<template lang="pug">
  v-app(id="bulwa")
    v-app-bar(app color="primary" flat dark)
      v-container.py-0.fill-height

        img.mr-3(:src="require('@/assets/eea_grants.png')" height="40")
        v-spacer

        img.mr-3(:src="require('@/assets/color_logo_no_background.svg')" height="40")
        v-spacer

        v-responsive.mr-4(v-if="username" max-width="150")
          v-text-field(v-model="username" append-icon="mdi-account" dense flat hide-details rounded solo-inverted readonly)
        v-responsive.mr-4(v-if="gameToken" max-width="150")
          v-text-field(v-model="gameToken" append-icon="mdi-controller" dense flat hide-details rounded solo-inverted readonly)
        v-btn(v-if="isLoggedIn" @click="logout()" icon)
          v-icon(dark) mdi-logout-variant

    v-main.grey.lighten-3
      v-container
        Error
        router-view
</template>

<script>
import { mapGetters } from "vuex";
import { GameSubscribeRequest } from "@/bulwa_pb.js";
import { GameClient } from "@/bulwa_grpc_web_pb.js";

import { EventBus } from "@/eventbus.js";

import Timer from "@/components/Timer";
import Error from "@/components/Error";

export default {
  name: "App",

  components: {
    // Timer,
    Error,
  },

  metaInfo: {
    title: "Home",
    titleTemplate: "%s | Plantacja",
  },

  data: () => ({
    showKnowledgeBase: false,
  }),

  created: function() {
    this.client = new GameClient(process.env.VUE_APP_BACKEND_URL, null, null);
    const self = this;
    EventBus.$on("subscribe", function() {
      if (self.authToken) {
        console.log("Subscribing " + self.username);
        self.subscribe();
      }
    });
  },

  destroyed: function() {
    EventBus.$off("subscribe");
  },

  methods: {
    logout: function() {
      this.$store.dispatch("reset");
      this.$router.push({ name: "Login" });
      if (this.stream) {
        this.stream.cancel();
      }
    },
    isAdmin: function() {
      return this.tabs;
    },
    subscribe: function() {
      let gameSubscribeRequest = new GameSubscribeRequest();
      var metadata = { auth_token: this.authToken };
      gameSubscribeRequest.setGametoken(this.gameToken);
      const self = this;
      this.stream = this.client.subscribe(
        gameSubscribeRequest,
        metadata,
        (err) => {
          if (err) {
            EventBus.$emit("errConnectionError");
            setTimeout(function() {
              console.log("Resubscribing...");
              self.subscribe();
            }, 1000);
          }
        }
      );
      this.stream.on("data", function(response) {
        EventBus.$emit("connectionRestored");
        self.$store.dispatch("sync", response);
      });
      this.stream.on("error", function(err) {
        console.log(`Got stream error: ${JSON.stringify(err)}`);
        if (err.message === "game not found") {
          EventBus.$emit("errGameNotFound");
          self.$store.dispatch("reset");
          self.$router.push({ name: "Login" });
        } else if (err.message === "user not found") {
          EventBus.$emit("errUserNotFound");
          self.$store.dispatch("reset");
          self.$router.push({ name: "Login" });
        } else if (err.message === "token not found") {
          EventBus.$emit("errAuthTokenNotFound");
          self.$store.dispatch("reset");
          self.$router.push({ name: "Login" });
        } else if (err.message === "user not in game") {
          EventBus.$emit("errUserNotInGame");
          self.$store.dispatch("reset");
          self.$router.push({ name: "Login" });
        } else if (err.message === "user does not have game manager tx") {
          EventBus.$emit("errUserDoesNotHaveGameManagerTx");
          self.$store.dispatch("reset");
          self.$router.push({ name: "Login" });
          // throw err;
        } else if (err.message === "user already in game") {
          EventBus.$emit("errUserAlreadyInGame");
          self.$store.dispatch("reset");
          self.$router.push({ name: "Login" });
        } else if (err.message === "game already started") {
          EventBus.$emit("errGameAlreadyStarted");
          self.$store.dispatch("reset");
          self.$router.push({ name: "Login" });
        } else if (err.message === "internal"){
          EventBus.$emit("errInternal");
          self.$store.dispatch("reset");
          self.$router.push({ name: "Login" });
        }

        else if (self.gameToken) {
          EventBus.$emit("errConnectionError");
          setTimeout(function() {
            console.log("Resubscribing...");
            self.subscribe();
          }, 1000);
        } else {
          // todo add sentry
          // throw err;
        }
      });
    },
  },

  computed: {
    ...mapGetters("auth", ["isLoggedIn", "username", "gameToken", "authToken"]),
  },

  beforeDestroy: function() {
    // TODO hack to maintain correct state on server side while still having live reload
    if (this.stream) {
      this.stream.cancel();
    }
  },
};
</script>
