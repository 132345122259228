<template lang="pug">
div
  v-row
    v-col(cols="12")
      v-card
        v-card-title {{ gameTitle }}
        v-card-text
          v-row
            v-col {{ gameDescription }}
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Landing',
  
  computed: {
    ...mapGetters('params', [
      'gameTitle',
      'gameDescription',
    ])
  },
}
</script>


