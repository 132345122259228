<template lang="pug">
v-card(outlined shaped raised)
  template(v-if="circle.tekko")
  template(v-else)
    v-card-title Krąg uprawny {{ idx + 1 }}
    v-card-text
      v-row
        v-col(cols="9")
          DoughnutChart(:chartData="circleStatus" title="hide")
        v-col(cols="3")
          v-row
            h4 Wybierz ilość do zebrania
          v-row
            v-col(cols="12")
              v-text-field(v-model="shoots" @click="validateInput('shoots')" @keyup="validateInput('shoots')" type="number" color="green darken-3" outlined dense label="Pędy")
            v-col(cols="12")
              v-text-field(v-model="yellow" @click="validateInput('yellow')" @keyup="validateInput('yellow')" type="number" color="yellow darken-3" outlined dense label="Żółte Ngoro")
            v-col(cols="12")
              v-text-field(v-model="blue" @click="validateInput('blue')" @keyup="validateInput('blue')" type="number" color="blue darken-3" outlined dense label="Niebieskie Ngoro")
            v-col(cols="12")
              v-text-field(v-model="purple" @click="validateInput('purple')" @keyup="validateInput('purple')" type="number" color="purple darken-3" outlined dense label="Fioletowe Ngoro")
    v-card-title Zbieracze
    v-card-text
      Team(teamType="harvesters" :idx="idx")
    v-card-title Ogrodnicy
    v-card-text
      Team(teamType="gardeners" :idx="idx")
</template>
<script>
import { mapGetters } from "vuex";

import { AssignmentAction } from "@/bulwa_pb.js";

import DoughnutChart from "@/components/DoughnutChart";
import Team from "@/components/Team";

export default {
  name: "PlantationCircle",

  components: {
    DoughnutChart,
    Team,
  },

  props: {
    idx: Number,
    circle: Object,
    workersList: Array,
  },

  data: () => ({
    AssignmentAction,
  }),

  methods: {
    validateInput: function(kind) {
      let input = 0;
      let currentCount = 0;
      let method = '';
      switch (kind) {
        case 'shoots':
          input = this.harvest(this.idx).shoots;
          currentCount = this.circle.shoots;
          method = "plantation/setShoots";
          break;
        case 'yellow':
          input = this.harvest(this.idx).yellow;
          currentCount = this.circle.yellow;
          method = "plantation/setYellow";
          break;
        case 'blue':
          input = this.harvest(this.idx).blue;
          currentCount = this.circle.blue;
          method = "plantation/setBlue";
          break;
        case 'purple':
          input = this.harvest(this.idx).purple;
          currentCount = this.circle.purple;
          method = "plantation/setPurple";
          break;
      }
      if (input < 0) {
        this.$store.commit(method, { idx: this.idx, value: 0 });
      } else if (input > currentCount) {
        this.$store.commit(method, { idx: this.idx, value: currentCount });
      }
    },
  },

  computed: {
    shoots: {
      get() {
        return this.harvest(this.idx).shoots;
      },
      set(v) {
        this.$store.commit("plantation/setShoots", { idx: this.idx, value: v });
      },
    },
    yellow: {
      get() {
        return this.harvest(this.idx).yellow;
      },
      set(v) {
        this.$store.commit("plantation/setYellow", { idx: this.idx, value: v });
      },
    },
    blue: {
      get() {
        return this.harvest(this.idx).blue;
      },
      set(v) {
        this.$store.commit("plantation/setBlue", { idx: this.idx, value: v });
      },
    },
    purple: {
      get() {
        return this.harvest(this.idx).purple;
      },
      set(v) {
        this.$store.commit("plantation/setPurple", { idx: this.idx, value: v });
      },
    },
    circleStatus: function() {
      let res = [];
      let resColor = [];
      let labels = [];
      
      if (this.circle.shoots > 0) {
        res.push(this.circle.shoots);
        resColor.push("green");
        labels.push("Pędy");
      }

      if (this.circle.yellow > 0) {
        res.push(this.circle.yellow);
        resColor.push("gold");
        labels.push("Zółte");
      }
    
      if (this.circle.blue > 0) {
        res.push(this.circle.blue);
        resColor.push("blue");
        labels.push("Niebieskie");
      }

      if (this.circle.purple > 0) {
        res.push(this.circle.purple);
        resColor.push("purple");
        labels.push("Fioletowe");
      }

      let capacity = 300 - this.circle.shoots - this.circle.yellow - this.circle.blue - this.circle.purple;
      if (capacity > 0) {
        res.push(capacity);
        resColor.push("gray");
        labels.push("Pusta przestrzeń");
      }

      return {
        labels: labels,
        datasets: [{
          data: res,
          backgroundColor: resColor,
        }],
      };
    },
    ...mapGetters("plantation", ["harvest"]),
  },
};
</script>
