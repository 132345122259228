<template lang="pug">
v-card
  v-card-title {{ offerTitle }}
  v-card-text
    v-row
      v-col(cols="12")
        v-simple-table
          thead
            tr
              th Wystaw na sprzedaż
              th Liczba (szt.)
              th Cena jednostkowa
          tbody
            tr
              td Bulwy żółte
              td
                v-btn(@click="decrement('yellow', 'quantity')" icon)
                  v-icon mdi-minus
                | {{ offer(this.idx).yellow }}
                v-btn(@click="increment('yellow', 'quantity')" icon)
                  v-icon mdi-plus
              td
                v-btn(@click="decrement('yellow', 'price')" icon)
                  v-icon mdi-minus
                | {{ offer(this.idx).yellowPrice }}
                v-icon mdi-currency-mnt
                v-btn(@click="increment('yellow', 'price')" icon)
                  v-icon mdi-plus
            tr
              td Bulwy niebieskie
              td
                v-btn(@click="decrement('blue', 'quantity')" icon)
                  v-icon mdi-minus
                | {{ offer(this.idx).blue }}
                v-btn(@click="increment('blue', 'quantity')" icon)
                  v-icon mdi-plus
              td
                v-btn(@click="decrement('blue', 'price')" icon)
                  v-icon mdi-minus
                | {{ offer(this.idx).bluePrice }}
                v-icon mdi-currency-mnt
                v-btn(@click="increment('blue', 'price')" icon)
                  v-icon mdi-plus
            tr
              td Bulwy fioletowe
              td
                v-btn(@click="decrement('purple', 'quantity')" icon)
                  v-icon mdi-minus
                | {{ offer(this.idx).purple }}
                v-btn(@click="increment('purple', 'quantity')" icon)
                  v-icon mdi-plus
              td
                v-btn(@click="decrement('purple', 'price')" icon)
                  v-icon mdi-minus
                | {{ offer(this.idx).purplePrice }}
                v-icon mdi-currency-mnt
                v-btn(@click="increment('purple', 'price')" icon)
                  v-icon mdi-plus
            tr
              td  Napój Kwanai złej jakości
              td
                v-btn(@click="decrement('badDrink', 'quantity')" icon)
                  v-icon mdi-minus
                | {{ offer(this.idx).badDrink }}
                v-btn(@click="increment('badDrink', 'quantity')" icon)
                  v-icon mdi-plus
              td
                v-btn(@click="decrement('badDrink', 'price')" icon)
                  v-icon mdi-minus
                | {{ offer(this.idx).badDrinkPrice }}
                v-icon mdi-currency-mnt
                v-btn(@click="increment('badDrink', 'price')" icon)
                  v-icon mdi-plus
            tr
              td Napój Kwanai dobrej jakości
              td
                v-btn(@click="decrement('goodDrink', 'quantity')" icon)
                  v-icon mdi-minus
                | {{ offer(this.idx).goodDrink }}
                v-btn(@click="increment('goodDrink', 'quantity')" icon)
                  v-icon mdi-plus
              td
                v-btn(@click="decrement('goodDrink', 'price')" icon)
                  v-icon mdi-minus
                | {{ offer(this.idx).goodDrinkPrice }}
                v-icon mdi-currency-mnt
                v-btn(@click="increment('goodDrink', 'price')" icon)
                  v-icon mdi-plus
  v-card-title Handlowcy
  v-card-text
    Team(teamType="traders" :idx="idx")

</template>
<script>
import { mapGetters } from "vuex";

import Team from "@/components/Team";

export default {
  name: "TradePost",

  components: {
    Team,
  },

  props: {
    idx: Number,
  },

  methods: {
    increment: function(kind, type) {
      let step = type === "quantity" ? 5 : 1;
      let mutationSuffix = type === "quantity" ? "" : "Price";
      let idx = this.idx;
      switch (kind) {
        case "yellow":
          {
            let yellow =
              type === "quantity"
                ? this.offer(idx).yellow
                : this.offer(idx).yellowPrice;
            this.$store.commit("trade/setYellow" + mutationSuffix, {
              idx,
              v: yellow + step,
            });
          }
          break;
        case "blue":
          {
            let blue =
              type === "quantity"
                ? this.offer(idx).blue
                : this.offer(idx).bluePrice;
            this.$store.commit("trade/setBlue" + mutationSuffix, {
              idx,
              v: blue + step,
            });
          }
          break;
        case "purple":
          {
            let purple =
              type === "quantity"
                ? this.offer(idx).purple
                : this.offer(idx).purplePrice;
            this.$store.commit("trade/setPurple" + mutationSuffix, {
              idx,
              v: purple + step,
            });
          }
          break;
        case "badDrink":
          {
            let drink =
              type === "quantity"
                ? this.offer(idx).badDrink
                : this.offer(idx).badDrinkPrice;
            this.$store.commit("trade/setBadDrink" + mutationSuffix, {
              idx,
              v: drink + step,
            });
          }
          break;
        case "goodDrink":
          {
            let drink =
              type === "quantity"
                ? this.offer(idx).goodDrink
                : this.offer(idx).goodDrinkPrice;
            this.$store.commit("trade/setGoodDrink" + mutationSuffix, {
              idx,
              v: drink + step,
            });
          }
          break;
        default:
          console.log("no such type");
      }
    },
    decrement: function(kind, type) {
      let idx = this.idx;
      let step = type === "quantity" ? 5 : 1;
      let mutationSuffix = type === "quantity" ? "" : "Price";
      switch (kind) {
        case "yellow":
          {
            let yellow =
              type === "quantity"
                ? this.offer(idx).yellow
                : this.offer(idx).yellowPrice;
            yellow -= step;
            yellow = Math.max(yellow, 0);
            this.$store.commit("trade/setYellow" + mutationSuffix, {
              idx,
              v: yellow,
            });
          }
          break;
        case "blue":
          {
            let blue =
              type === "quantity"
                ? this.offer(idx).blue
                : this.offer(idx).bluePrice;
            blue -= step;
            blue = Math.max(blue, 0);
            this.$store.commit("trade/setBlue" + mutationSuffix, {
              idx,
              v: blue,
            });
          }
          break;
        case "purple":
          {
            let purple =
              type === "quantity"
                ? this.offer(idx).purple
                : this.offer(idx).purplePrice;
            purple -= step;
            purple = Math.max(purple, 0);
            this.$store.commit("trade/setPurple" + mutationSuffix, {
              idx,
              v: purple,
            });
          }
          break;
        case "badDrink":
          {
            let drink =
              type === "quantity"
                ? this.offer(idx).badDrink
                : this.offer(idx).badDrinkPrice;
            drink -= step;
            drink = Math.max(drink, 0);
            this.$store.commit("trade/setBadDrink" + mutationSuffix, {
              idx,
              v: drink,
            });
          }
          break;
        case "goodDrink":
          {
            let drink =
              type === "quantity"
                ? this.offer(idx).goodDrink
                : this.offer(idx).goodDrinkPrice;
            drink -= step;
            drink = Math.max(drink, 0);
            this.$store.commit("trade/setGoodDrink" + mutationSuffix, {
              idx,
              v: drink,
            });
          }
          break;
        default:
          console.log("no such type");
      }
    },
  },

  computed: {
    offerTitle: function() {
      if (this.idx === 4) {
        return "Oferta portu kosmicznego"
      }
      return "Oferta punktu handlowego " + (this.idx + 1); 
    },
    ...mapGetters("trade", ["offer"]),
  },
};
</script>
