<template lang="pug">
  #productionManagement.px-4
    v-row
      v-col
        h1 Zarządzanie przetwórnią {{ id - 4 }}
    v-row
      v-col(cols="12")
        v-row
          v-col#workarea(cols="8")
            v-timeline(dense)
              template(v-for="(n, i) in availableUnits")
                v-timeline-item(:key="i" :icon="unitIcon(i)" :color="iconColor(i)" fill-dot right)
                  ProductionUnit(v-if="isUnitActive(i)" :idx="i")
                  v-card(v-if="isConstructionSite(i)")
                    v-card-title Budowniczowie
                    v-card-text
                      Team(teamType="builders")

          v-col(cols="4")
            v-row
              v-col(cols="12")
                affix(relative-element-selector="#workarea" style="width: 350px")
                  v-card
                    v-card-title Dostępni pracownicy
                    v-card-text
                      Team(teamType="free")         
                  v-card
                    v-card-text
                      v-switch(v-model="destroyBadDrink" label="Zniszcz napój złej jakości")
</template>
<script>
import { Affix } from "vue-affix";
import { mapGetters } from "vuex";
import { SpecialAction } from "@/bulwa_pb.js";

import Team from "@/components/Team";
import DoughnutChart from "@/components/DoughnutChart";
import ProductionUnit from "@/components/ProductionUnit";

export default {
  name: "ProductionManagement",

  props: {
    readonly: Boolean,
    id: Number,
  },

  components: {
    Team,
    DoughnutChart,
    ProductionUnit,
    Affix,
  },

  data: () => ({}),

  created: function () {
    this.initSpecialActions();
  },

  methods: {
    isReadonly: function () {
      return this.readonly;
    },
    initSpecialActions: function () {
      this.$store.commit("special/clearAvailableActions");
    },
    isUnitActive: function (i) {
      return i < this.readyUnits;
    },
    isConstructionSite: function (i) {
      return i === this.readyUnits;
    },
    unitIcon: function (i) {
      if (this.isUnitActive(i)) {
        return "mdi-cog";
      } else if (this.isConstructionSite(i)) {
        return "mdi-hammer-wrench";
      } else {
        return "mdi-cog";
      }
    },
    iconColor: function (i) {
      if (i <= this.readyUnits) {
        return "primary";
      } else {
        return "#DCDCDC";
      }
    },
  },

  computed: {
    destroyBadDrink: {
      get() {
        return this["production/destroyBadDrink"];
      },
      set(v) {
        this.$store.commit("production/setDestroyBadDrink", v);
      },
    },
    ...mapGetters("production", ["readyUnits", "availableUnits"]),
    ...mapGetters(["production/destroyBadDrink"]),
  },
};
</script>
