<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  extends: Line,

  mixins: [reactiveProp],

  mounted() {
    this.addPlugin(ChartDataLabels);
    let options = {
      legend: {
        position: "right",
      },
      plugins: {
        datalabels: {
          color: "#FFFFFF",
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              max: 3,
              min: 0,
              stepSize: 1,
            },
            scaleLabel: {
              display: true,
              labelString: "Siła",
              fontSize: 16,
            },
          },
        ],
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Tygodnie",
              fontSize: 16,
            },
          },
        ],
      },
      maintainAspectRatio: false,
    };
    this.renderChart(this.chartData, options);
  },
};
</script>
