import Vue from 'vue'
import VueRouter from 'vue-router'

import store from './../main.js'

import Game from '../views/Game.vue'
import GameSelection from '../views/GameSelection.vue'
import Login from '../views/Login.vue'
import Admin from '../views/Admin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isLoggedIn']) {
        next({
          path: '/game',
        })
      } else {
        next()
      }
    },
  },
  {
    path: '/resume/:authToken',
    name: 'Resume',
    component: Login,
    props: true,
  },
  {
    path: '/game-selection',
    name: 'Game Selection',
    component: GameSelection,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/game',
    name: 'Game',
    component: Game,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: {
      template: '<div>Not Found</div>',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/isLoggedIn']) {
      next({
        path: '/',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
