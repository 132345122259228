<template lang="pug">
  v-dialog(v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition")
    v-card
      v-toolbar(dark color="primary")
        v-btn(icon @click.stop="show = false")
          v-icon mdi-close
        v-toolbar-title Baza wiedzy
        v-spacer
      v-expansion-panels
        v-expansion-panel(v-for="article in articles" :key="article.id")
          v-expansion-panel-header {{ article.title }}
          v-expansion-panel-content
            div(v-html="article.content")
</template>
<script>
import { mapGetters } from "vuex";
import dashify from "dashify";

export default {
  name: "KnowledgeBase",

  data: () => ({
    dialog: false,
  }),

  props: {
    value: Boolean,
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    articles: function() {
      let res = [];
      this.titles.forEach(function(title, i) {
        let article = require("@/assets/articles/" + dashify(title) + ".md");
        res.push({
          id: i,
          title: title,
          content: article,
        });
      });
      return res;
    },
    ...mapGetters("knowledgebase", ["titles"]),
  },
};
</script>
