<template lang="pug">
v-card
  v-card-title
    | {{ title }}
  v-card-text
    v-slider(v-model="value" step="4" ticks :tick-labels="tickLabels" min="4" max="16")
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/eventbus.js";

export default {
  name: "WorkingTimeSlider",

  props: {
    id: Number,
    teamType: String,
  },

  data: () => ({
    tickLabels: ["4", "8", "12", "16", "20"],
  }),

  computed: {
    value: {
      get() {
        switch (this.id) {
          case 2:
          case 3:
          case 4:
            if (this.teamType === "gardeners") {
              return this.$store.getters["plantation/workingTime"];
            }
            return this.$store.getters["plantation/harvestersWorkingTime"];
          default:
            return -1;
        }
      },
      set(v) {
        switch (this.id) {
          case 2:
          case 3:
          case 4:
            if (this.teamType === "gardeners") {
              this.$store.commit("plantation/setWorkingTime", v);
            } else {
              this.$store.commit("plantation/setHarvestersWorkingTime", v);
            }
            break;
          default:
            console.log("wrong id");
        }
      },
    },
    title: function() {
      let res = "Czas pracy ";
      switch (this.teamType) {
        case "gardeners":
          return res + "ogrodników";
        case "harvesters":
          return res + "zbieraczy";
        default:
          console.log("wrong team");
          return "-1";
      }
    },
  },
};
</script>
