import Vue from "vue";

import { Action, RecruitmentAction, SalaryAction } from "@/bulwa_pb.js";

const getDefaultState = () => {
  return {
    salaries: null,
    crewChanges: {
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
    },
    salaryChanges: {
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      101: 0,
      102: 0,
      103: 0,
      104: 0,
      105: 0,
      106: 0,
    },
    availableToHire: 0,
  };
};

const state = getDefaultState();

const actions = {
  sync(ctx, resp) {
    ctx.commit("setAvailableToHire", resp.availabletohire);
    ctx.commit("setSalaries", new Map(resp.hr.salariesMap));
  },
  reset(ctx) {
    ctx.commit("reset");
  },
};

const getters = {
  salary: (state) => (id) => {
    if (state.salaries && state.salaries.has(id)) {
      return state.salaries.get(id);
    }
    return 0;
  },
  salaryChange: (state) => (id) => {
    return state.salaryChanges[id];
  },
  crewChange: (state) => (id) => {
    return state.crewChanges[id];
  },
  availableToHire: (state) => {
    let hiredThisTurn = 0;
    for (const [k, v] of Object.entries(state.crewChanges)) {
      hiredThisTurn += v;
    }
    return state.availableToHire - hiredThisTurn;
  },
  toPlayerActions: (state) => {
    let actions = [];

    // recruitments
    for (const [source, change] of Object.entries(state.crewChanges)) {
      if (change === 0) {
        continue;
      }
      let action = new Action();
      let rA = new RecruitmentAction();
      rA.setSource(source);
      rA.setQuantity(change);
      action.setRecruitment(rA);
      actions.push(action);
    }

    // salary changes
    for (const [source, change] of Object.entries(state.salaryChanges)) {
      if (change === 0) {
        continue;
      }
      let action = new Action();
      let sA = new SalaryAction();
      sA.setSource(source);
      sA.setChange(change);
      action.setSalary(sA);
      actions.push(action);
    }
    return actions;
  },
};

const mutations = {
  setSalaryChange(state, { id, change }) {
    state.salaryChanges[id] = change;
  },
  setCrewChange(state, { id, change }) {
    state.crewChanges[id] = change;
  },
  setAvailableToHire(state, v) {
    state.availableToHire = v;
  },
  setSalaries(state, m) {
    state.salaries = m;
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
