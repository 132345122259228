<template lang="pug">
  #game
    v-row
      KnowledgeBase(v-if="gameState == 'Ongoing'" v-model="showKnowledgeBase")
    v-row
      v-col(cols="2")
        v-row(no-gutters)
          v-col(cols="12")
            v-sheet(v-if="gameState == 'Ongoing'" rounded="lg")
              v-list(color="transparent")
                v-list-item(@click="showKnowledgeBase = true" link)
                  v-list-item-icon
                    v-icon mdi-book-open-variant
                  v-list-item-content
                    v-list-item-title Baza wiedzy
                v-list-item(@click="showTurnSummary = true" link)
                  v-list-item-icon
                    v-icon mdi-text-box-search
                  v-list-item-content
                    v-list-item-title Podsumowanie tury
                v-divider(class="my-2")
                v-list-item(@click="endTurn()" link :disabled="playerActionsSent()")
                  v-list-item-content
                    v-list-item-title Zakończ turę
        v-row
          v-col(cols="12")
            v-sheet(v-if="gameState == 'Ongoing'" rounded="lg")
              v-list(color="transparent")
                v-list-item
                  v-list-item-icon
                    v-icon mdi-weather-partly-snowy-rainy
                  v-list-item-content
                    v-list-item-title Aktualna pogoda
                v-divider(class="my-2")
                v-list-item
                  v-list-item-icon
                    v-icon mdi-weather-windy
                  v-list-item-content
                    v-list-item-title(v-text="wind")
                v-list-item
                  v-list-item-icon
                    v-icon mdi-weather-pouring
                  v-list-item-content
                    v-list-item-title(v-text="rainfall")
                v-list-item
                  v-list-item-icon
                    v-icon mdi-thermometer
                  v-list-item-content
                    v-list-item-title(v-text="temperature")
        v-row
          v-col(cols="12")
            v-sheet(v-if="gameState == 'Ongoing'" rounded="lg")
              v-list(color="transparent")
                v-list-item
                  v-list-item-icon
                    v-icon mdi-currency-mnt
                  v-list-item-content
                    v-list-item-title Budżet firmy
                v-divider(class="my-2")
                v-list-item
                  v-list-item-content {{ balanceWithSeparator }}
      v-col(cols="8")
        v-sheet(rounded="lg")
          Landing(v-if="gameState == 'Pending'")
          PlayersList(v-if="gameState == 'Pending'")
          RoleSelection(v-if="gameState == 'RoleSelection'")
          #game(v-if="gameState == 'Ongoing'")
            v-dialog(v-model="showTurnSummary")
              TurnSummary
            PlantationManagement(:id="2" v-if="role == 2")
            PlantationManagement(
              :id="3"
              v-if="role == 3"
            )
            PlantationManagement(
              :id="4"
              v-if="role == 4"
            )
            ProductionManagement(
              :id="5"
              v-if="role == 5"
            )
            ProductionManagement(
              :id="6"
              v-if="role == 6"
            )
            WarehouseManagement(v-if="role == 7")
            HRManagement(:id="9" v-if="role == 9")
            Meteo(:id="10" v-if="role == 10")
            Trade(:id="8" v-if="role == 8")
          GameSummary(v-if="gameState == 'Summary'")
      v-col(cols="2")
        v-row(no-gutters)
          v-col(cols="12")
            v-sheet(v-if="gameState == 'Ongoing'" rounded="lg")
              h2.pt-4.pl-4 Stan tury
              PlayersList
        v-row
          v-col(cols="12")
            v-sheet(v-if="gameState == 'Ongoing' && availableActions && availableActions.length > 0" rounded="lg")
              v-list(color="transparent")
                v-list-item(@click="addSpecialAction(action.kind, action.price)" v-for="action in availableActions" :key="action.kind" link)
                  v-list-item-content
                    v-list-item-title {{ textForSpecialAction(action.kind) }}
                  v-list-item-action
                    v-btn(icon)
                      span {{ action.price }}
                      v-icon mdi-currency-mnt
</template>

<script>
import { mapGetters } from "vuex";
import { GameClient } from "../bulwa_grpc_web_pb.js";
import { PlayerActions, SpecialAction } from "@/bulwa_pb.js";
import Role from "@/mixins/role.js";

import { EventBus } from "@/eventbus.js";

import Landing from "@/components/Landing";
import PlayersList from "@/components/PlayersList";
import PlantationManagement from "@/components/PlantationManagement";
import RoleSelection from "@/components/RoleSelection";
import TurnSummary from "@/components/TurnSummary";
import KnowledgeBase from "@/components/KnowledgeBase";
import HRManagement from "@/components/HRManagement";
import Meteo from "@/components/Meteo";
import WarehouseManagement from "@/components/WarehouseManagement";
import ProductionManagement from "@/components/ProductionManagement";
import Trade from "@/components/Trade";
import GameSummary from "@/components/GameSummary";

export default {
  name: "Game",

  mixins: [Role],

  components: {
    Landing,
    PlayersList,
    PlantationManagement,
    RoleSelection,
    TurnSummary,
    KnowledgeBase,
    HRManagement,
    Meteo,
    WarehouseManagement,
    ProductionManagement,
    Trade,
    GameSummary,
  },

  metaInfo() {
    return {
      title: this.roles[this.roleInt],
    };
  },

  data: () => ({
    showTurnSummary: false,
    showKnowledgeBase: false,
  }),

  created: function() {
    this.client = new GameClient(process.env.VUE_APP_BACKEND_URL, null, null);
    EventBus.$emit("subscribe");
    const self = this;
    EventBus.$on("turnEnded", function() {
      self.showTurnSummary = true;
    });
  },

  mounted: function() {},

  methods: {
    endTurn: function() {
      let actions = [];
      switch (this.roleInt) {
        case 2:
        case 3:
        case 4:
          actions.push(...this.$store.getters["plantation/toPlayerActions"]);
          break;
        case 5:
        case 6:
          actions.push(...this.$store.getters["production/toPlayerActions"]);
          break;
        case 7:
          actions.push(...this.$store.getters["warehouse/toPlayerActions"]);
          break;
        case 8:
          actions.push(...this.$store.getters["trade/toPlayerActions"]);
          break;
        case 9:
          actions.push(...this.$store.getters["hr/toPlayerActions"]);
          this.$store.commit("hr/reset");
          break;
        case 10:
          console.log("No actions to be commited.");
          break;
        default:
          console.log("role cannot be synced");
      }
      actions.push(...this.$store.getters["special/toPlayerActions"]);

      var metadata = { auth_token: this.authToken };
      const self = this;
      var playerActions = new PlayerActions();
      if (actions.length > 0) {
        playerActions.setActionsList(actions);
      } else {
        // TODO
        console.log(
          "if this never happens then player actions can be deleted - no actions test???"
        );
        playerActions.setActionsList(this.playerActions);
      }
      this.client.submitActions(playerActions, metadata, (err, resp) => {
        if (err) {
          EventBus.$emit("failedToSendActions");
          // throw err;
        }
      });
    },

    addSpecialAction: function(kind, price) {
      this.$store.commit("special/addAction", { kind, price });
    },

    textForSpecialAction: function(kind) {
      switch (kind) {
        case SpecialAction.Kind.PLANTATION_NEW_CIRCLE:
          return "Rozbuduj plantację";
        case SpecialAction.Kind.PRODUCTION_NEW_UNIT:
          return "Rozbuduj przetwórnię";
        default:
          return "No such special action";
      }
    },
    playerActionsSent: function() {
      const self = this;
      if (this.players.length > 0) {
        return this.players.find((p) => p.authtoken === self.authToken).decision;
      }
      return false;
    }
  },

  computed: {
    balanceWithSeparator: function() {
      let n = this.balance;
      let stack = [];
      while (n > 0) {
        let chunk = n % 1000;
        stack.push(chunk);
        n = Math.trunc(n / 1000); 
      }
      let res = "";
      let startingStackLen = stack.length;
      while (stack.length > 0) {
        let chunk = stack.pop() + "";
        if (chunk.length < 3 && stack.length != startingStackLen) {
          chunk = chunk.padStart(3, "0");
        }
        res += " " + chunk;
      }
      return res;
    },
    ...mapGetters("common", [
      "roleInt",
      "role",
      "gameState",
      "wind",
      "rainfall",
      "temperature",
      "playerActions",
      "turnEnded",
      "balance",
      "players",
    ]),
    ...mapGetters("special", ["availableActions"]),
    ...mapGetters("auth", ["authToken"]),
  },
};
</script>
