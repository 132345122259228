<template lang="pug">
  div
    v-row.pl-8
      v-col
        h1 Zarządzanie plantacją {{ id - 1 }}
    v-row.pr-8
      v-col(cols="12")
        v-row.pl-8
          v-col#workarea(cols="8")
            v-timeline(dense)
              template(v-for="(n, i) in availableCircles")
                v-timeline-item(:key="i" :icon="circleIcon(i)" :color="iconColor(i)" fill-dot right)
                  PlantationCircle(v-if="isCircleActive(i) && !isTekko(i)" :circle="circles[i]" :workersList="workersList" :idx="i")
                  v-card(v-else-if="isCircleActive(i) && isTekko(i)" outlined shaped raised color="#00695C")
                    v-card-title Rekultywatorzy
                    v-card-text
                      Team(teamType="cleaners" :idx="i")
                  v-card(v-else-if="isConstructionSite(i)" outlined shaped raised)
                    v-card-title Krąg do przygotowania
                    v-card-title Budowniczowie
                    v-card-text
                      Team(teamType="builders")

          v-col(cols="4")
            affix(relative-element-selector="#workarea" style="width: 350px")
              v-row
                v-col(cols="12")
                  WorkingTimeSlider(:id="id" teamType="gardeners")
                v-col(cols="12")
                  WorkingTimeSlider(:id="id" teamType="harvesters")
              v-row
                v-col(cols="12")
                  v-card
                    v-card-title Dostępni pracownicy
                    v-card-text
                      Team(teamType="free")
</template>

<script>
import { mapGetters } from "vuex";
import { Affix } from "vue-affix";
import { EventBus } from "@/eventbus.js";
import { SpecialAction } from "@/bulwa_pb.js";

import Team from "@/components/Team";
import PlantationCircle from "@/components/PlantationCircle";
import WorkingTimeSlider from "@/components/WorkingTimeSlider";

export default {
  name: "PlantationManagement",

  props: {
    readonly: Boolean,
    id: Number,
  },

  components: {
    Affix,
    Team,
    PlantationCircle,
    WorkingTimeSlider,
  },

  data: () => ({}),

  created: function() {
    this.initSpecialActions();
  },

  methods: {
    isReadonly: function() {
      return this.readonly;
    },
    initSpecialActions: function() {
      this.$store.commit("special/clearAvailableActions");
    },
    isCircleActive: function(i) {
      return i < this.readyCircles;
    },
    circleIcon: function(i) {
      if (i < this.readyCircles) {
        if (this.circles[i].tekko) {
          return "mdi-palm-tree";
        } else {
          return "mdi-flower";
        }
      } else if (i === this.readyCircles) {
        return "mdi-hammer-wrench";
      } else {
        return "mdi-flower";
      }
    },
    isConstructionSite: function(i) {
      return i === this.readyCircles;
    },
    isTekko: function(i) {
      return this.circles[i].tekko;
    },
    iconColor: function(i) {
      if (i <= this.readyCircles) {
        return "primary"
      } else {
        return "#DCDCDC"
      }
    }
  },

  computed: {
    ...mapGetters("plantation", [
      "plantationById",
      "readyCircles",
      "availableCircles",
      "circles",
      "workersList",
    ]),
  },
};
</script>
