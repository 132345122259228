import { Player } from "@/bulwa_pb.js";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    roles: {
      0: "None",
      1: "Admin",
      2: "Menedżer, Menedżerka plantacji 1",
      3: "Menedżer, Menedżerka plantacji 2",
      4: "Menedżer, Menedżerka plantacji 3",
      5: "Menedżer, Menedżerka przetwórni 1",
      6: "Menedżer, Menedżerka przetwórni 2",
      7: "Menedżer, Menedżerka magazynu",
      8: "Menedżer, Menedżerka handlu",
      9: "Menedżer, Menedżerka HR",
      10: "Planetolog, Planetolożka",
      11: "Meteorolog, Meteorolożka",
    },
  }),

  computed: {
    selectableRoles: function() {
      return Object.keys(this.roles)
        .filter((key) => parseInt(key) > 1 && parseInt(key) < 11)
        .filter((key) => {
          switch (key) {
            case '2':
              return this.plantation1;
            case '3':
              return this.plantation2;
            case '4':
              return this.plantation3;
            case '5':
              return this.production1;
            case '6':
              return this.production2;
            default:
              return true;
          }
        })
        .reduce((obj, key) => {
          obj[key] = this.roles[key];
          return obj;
        }, {});
    },
    ...mapGetters("params", [
      "plantation1",
      "plantation2",
      "plantation3",
      "production1",
      "production2",
    ]),
  },
};
