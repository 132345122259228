<template lang="pug">
  v-sheet.pa-4
    v-row
      v-col(cols="12")
        h1.text-center Podsumowanie {{ weekText }}
    v-row(dense)
      v-col(v-for="(events, source_id) in sortedEvents" :key="source_id" cols="12")
        v-card(:color="cardColor(source_id)" dark)
          v-card-title(v-text="cardTitle(source_id)" class="headline")
          v-card-text
            ul
              li(v-for="(event, i) in events" v-if="textForEvent(event)" :key="i")
                | {{ textForEvent(event) }}
</template>
<script>
import { mapGetters } from "vuex";
import Role from "@/mixins/role.js";

export default {
  name: "TurnSummary",

  mixins: [Role],

  data: () => ({
    dialog: false,
  }),

  props: {
    value: Boolean,
  },

  created: function () {},

  methods: {
    cardColor: function (source) {
      source = parseInt(source);
      switch (source) {
        case 2:
        case 3:
        case 4:
          return "green";
        case 5:
        case 6:
          return "orange";
        case 7:
          return "purple";
        case 8:
          return "blue";
        case 9:
          return "pink";
        default:
          return "black";
      }
    },
    cardTitle: function (source) {
      source = parseInt(source);
      switch (source) {
        case 2:
          return "Plantacja 1";
        case 3:
          return "Plantacja 2";
        case 4:
          return "Plantacja 3";
        case 5:
          return "Przetwórnia 1";
        case 6:
          return "Przetwórnia 2";
        case 7:
          return "Magazyn";
        case 8:
          return "Handel";
        case 9:
          return "HR";
        default:
          return "-1";
      }
    },
    textForEvent: function (e) {
      if (typeof e.harvest !== "undefined") {
        let msg = "Zebrano: ";
        let nonEmpty = false;
        if (e.harvest.shoots > 0) {
          msg += `${e.harvest["shoots"]} pędów, `;
          nonEmpty = true;
        }
        if (e.harvest.yellow > 0) {
          msg += `${e.harvest["yellow"]} żółtych bulw, `;
          nonEmpty = true;
        }
        if (e.harvest.blue > 0) {
          msg += `${e.harvest["blue"]} niebieskich bulw, `;
          nonEmpty = true;
        }
        if (e.harvest.purple > 0) {
          msg += `${e.harvest["purple"]} fioletowych bulw, `;
          nonEmpty = true;
        }
        msg += `z kręgu ${e.harvest.idx + 1}.`;
        if (nonEmpty) {
          return msg;
        } else {
          return "";
        }
      } else if (typeof e.growth !== "undefined") {
        return this.messageForGrowth(e.growth);
      } else if (typeof e.production !== "undefined") {
        return this.messageForProduction(e.production);
      } else if (typeof e.logistics !== "undefined") {
        switch (e.logistics.kind) {
          case 0:
            if (
              e.logistics.yellow !== 0 ||
              e.logistics.blue !== 0 ||
              e.logistics.purple !== 0
            ) {
              return `Przyjęto ${e.logistics.yellow} żółtych, ${
                e.logistics.blue
              } niebieskich i ${
                e.logistics.purple
              } fioletowych bulw z ${this.cardTitle(e.logistics.source)}.`;
            } else {
              return `Przyjęto ${e.logistics.baddrink} złych butelek i ${
                e.logistics.gooddrink
              } dobrych butelek z ${this.cardTitle(e.logistics.source)}.`;
            }
          case 1:
            return `Zabrakło miejsca dla części towarów.`;
          case 2:
            return `Magazynierzy nie byli w stanie obsłużyć wszystkich przesyłek.`;
          case 3:
            return `Zaginęło ${
              e.logistics.gooddrink + e.logistics.baddrink
            } butelek napoju z ${this.cardTitle(e.logistics.source)}.`;
          case 4:
            return `Wydano ${e.logistics.yellow} żółtych, ${
              e.logistics.blue
            } niebieskich i ${
              e.logistics.purple
            } fioletowych bulw do ${this.cardTitle(e.logistics.target)}.`;
          default:
            return "-1";
        }
      } else if (typeof e.trade !== "undefined") {
        switch (e.trade.kind) {
          case 0:
            return `Sprzedano ${e.trade.quantity} żółtych bulw za ${e.trade.payment} ₮.`;
          case 1:
            return `Sprzedano ${e.trade.quantity} niebieskich bulw za ${e.trade.payment} ₮.`;
          case 2:
            return `Sprzedano ${e.trade.quantity} butelek napoju złej jakości za ${e.trade.payment} ₮.`;
          case 3:
            return `Sprzedano ${e.trade.quantity} butelek napoju dobrej jakości za ${e.trade.payment} ₮.`;
          case 4:
            return `Sprzedano ${e.trade.quantity} fioletowych bulw za ${e.trade.payment} ₮.`;
          case 5:
            // TODO this should be refactored to a file similar to consts.rs
            if (e.trade.quantity < 100) {
              return "Nikogo nie stać na sprzedawaną przez nas bulwę.";
            } else if (e.trade.quantity < 250) {
              return "Klientów jest niewielu, a każdy stara się kupować jak najmniej. Wydają się głodni.";
            } else if (e.trade.quantity < 280) {
              return "Klientów jest sporo, choć wielu wydaje się uważać na wydatki i długo rozważa decyzję o zakupie.";
            } else if (e.trade.quantity < 300) {
              return "Klientów jest wielu, a każdy z nich kupuje coraz więcej. Nie zwracają zwbyt wielkiej uwagi na cenę.";
            } else {
              return "Klientów jest mnóstwo, wśród nich nieco wybredni. Wybierają najbardziej dorodne bulwy i są gotowi sporo za nie płacić.";
            }
          case 6:
            if (e.trade.quantity < 30) {
              return "Klientów jest niewielu, wydają się niepewni i zażenowani. Można odnieść wrażenie, że zakup napoju jest dla nich czymś wstydliwym.";
            } else {
              return "Wielu klientów ochoczo próbuje napoju, żywo dyskutując o walorach smakowych poszczególnych partii i porównując je z napojem produkowanym metodami tradycyjnymi.";
            }
          default:
            return "-1";
        }
      } else if (typeof e.recruitment !== "undefined") {
        if (e.recruitment.quantity > 0) {
          return `Zatrudniono ${
            e.recruitment.quantity
          } pracowników do ${this.cardTitle(e.recruitment.target)}.`;
        } else {
          return `Zwolniono ${
            e.recruitment.quantity
          } pracowników z ${this.cardTitle(e.recruitment.target)}.`;
        }
      } else if (typeof e.payment !== "undefined") {
        return `Wydano ${e.payment.quantity} ₮ na pensje.`;
      } else if (typeof e.build !== "undefined") {
        switch (e.source) {
          case 2:
          case 3:
          case 4:
            return `Wybudowano ${e.build.idx} krąg plantacji.`;
          case 5:
          case 6:
            return `Wybudowano ${e.build.idx} linię produkcyjną.`;
          case 7:
            return `Magazyn został rozbudowany`;
          case 8:
            // look into build system code
            if (e.build.idx === 777) {
              return `Wybudowano port kosmiczny.`;
            } else {
              return `Wybudowano ${e.build.idx} placówkę handlową.`;
            }
          default:
            return "Unknown source for build event.";
        }
      } else if (typeof e.tekko !== "undefined") {
        switch (e.tekko.kind) {
          case 0:
            return `W kręgu ${e.tekko.idx + 1} pojawiło się pnącze tekko.`;
          case 1:
            return `Z kręgu ${e.tekko.idx + 1} usunięto pnącze tekko.`;
          default:
            return "-1";
        }
      } else if (typeof e.plantationinfo !== "undefined") {
        return "";
        // return this.messageForPlantationInfo(e.plantationinfo);
      } else if (typeof e.optimalization !== "undefined") {
        return `Na linii produkcyjnej ${
          e.optimalization.idx + 1
        } wprowadzono optymalizację.`;
      } else if (typeof e.decay !== "undefined") {
        return `Zgniło ${e.decay.yellow} żółtych, ${e.decay.blue} niebieskich i ${e.decay.purple} fioletowych bulw.`;
      }
      return "-1";
    },
    messageForGrowth: function (g) {
      let msg = `W kręgu ${g.idx + 1} wyrosło ${g["shoots"]} pędów. `;
      msg += `${g["yellow"]} bulw zmieniło kolor na żółty. `;
      msg += `${g["blue"]} bulw zmieniło kolor na niebieski. `;
      msg += `${g["purple"]} bulw zmieniło kolor na fioletowy. `;
      if (g.killed) {
        msg += "Krąg został zduszony. ";
      }
      return msg;
    },
    messageForPlantationInfo: function (p) {
      let msg = `Po zbiorach w kręgu ${p.idx + 1} było `;
      msg += `${p["shoots"]} pędów, `;
      msg += `${p["yellow"]} żółtych bulw, `;
      msg += `${p["blue"]} niebieskich bulw, `;
      msg += `${p["purple"]} fioletowych bulw.`;
      return msg;
    },
    messageForProduction: function (p) {
      // console.log(`Turn Summary Production ${p.idx + 1} line ${p.quantity} quantity ${p.quality}`);
      let msg = `Na liniii ${p.idx + 1} wyprodukowano `;
      if (p.quality === 0) {
        msg += `${p.quantity} butelek dobrej jakości.`;
      } else {
        msg += `${p.quantity} butelek złej jakości.`;
      }
      return msg;
    },
  },

  computed: {
    weekText: function () {
      let text = "tygodnia ";
      let weekNumber = this.turnNumber % 12;
      const map = new Map();
      map.set(0, "Ważki");
      map.set(1, "Jednorożca");
      map.set(2, "Gremlina");
      map.set(3, "Feniksa");
      map.set(4, "Meduzy");
      map.set(5, "Cyklopa");
      map.set(6, "Trolla");
      map.set(7, "Czarodzieja");
      map.set(8, "Goblina");
      map.set(9, "Gryfa");
      map.set(10, "Dżina");
      map.set(11, "Tytana");
      text += map.has(weekNumber) ? map.get(weekNumber) : "-1";
      if (this.isAdmin) {
        text += " (" + this.turnNumber + ")";
      }
      return text;
    },
    sortedEvents: function () {
      var res = {};
      for (let e of this.events) {
        if (!(e.source in res)) {
          res[e.source] = [];
        }
        res[e.source].push(e);
      }
      return res;
    },
    ...mapGetters("common", [
      "events",
      "eventsBySource",
      "turnNumber",
      "isAdmin",
    ]),
  },
};
</script>
