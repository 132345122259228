<template lang="pug">
    v-card(v-if="selectionDone")
      v-card-title Wybrana rola
      v-card-text {{ selectedRoleName }}
    v-card(v-else)
      v-card-title Wybór roli
      v-card-text
        v-radio-group(v-model="selectedRole" v-for="(role, index) in selectableRoles" :key="index" column)
          v-radio(:value="index" :label="role" :disabled="isRoleUnavailable(index)")
      v-card-actions
        v-btn(@click="selectRole") Select
</template>
<script>
import { Player, SelectRoleRequest } from "@/bulwa_pb.js";
import { GameClient } from "@/bulwa_grpc_web_pb.js";
import Role from "@/mixins/role.js";
import { EventBus } from "@/eventbus.js";

import { mapGetters } from "vuex";

export default {
  name: "RoleSelection",

  mixins: [Role],

  metaInfo: {
    title: "Wybór roli",
  },

  data: () => ({
    selectedRole: null,
    selectionDone: false,
  }),

  created: function() {
    this.client = new GameClient(process.env.VUE_APP_BACKEND_URL, null, null);
  },

  methods: {
    selectRole: function() {
      // TODO make this sane
      // we should not use vuex to store selected role - what when disconnect happens?
      this.$store.commit("common/setRole", this.selectedRole);
      var selectRoleRequest = new SelectRoleRequest();
      selectRoleRequest.setRole(this.selectedRole);
      selectRoleRequest.setGametoken(this.gameToken);
      var metadata = { auth_token: this.authToken };
      const self = this;
      this.client.selectRole(selectRoleRequest, metadata, (err, response) => {
        if (err) {
          EventBus.$emit("failedToSelectRole");
        } else {
          self.selectionDone = true;
        }
      });
    },
    isRoleUnavailable: function(roleId) {
      var takenRoles = [];
      for (const player of this.players) {
        takenRoles.push(player.role);
      }
      if (takenRoles.includes(parseInt(roleId))) {
        return true;
      }
      return false;
    },
  },

  computed: {
    selectedRoleName: function() {
      return this.roles[this.role];
    },
    ...mapGetters("common", ["role", "players"]),
    ...mapGetters("auth", ["authToken", "gameToken"]),
  },
};
</script>
