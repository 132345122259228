<template lang="pug">
v-card(:color="color" dark)
  v-card-title
    v-icon(left) {{ icon }}
    span.text-subtitle-1 {{ name }}
  v-card-text.text-center
      v-btn(@click="decrement" icon)
        v-icon mdi-minus
      span.text-subtitle-1 {{ total }}
        v-icon(dense) {{ valueIcon }}
      v-btn(@click="increment" icon)
        v-icon mdi-plus
      br
      | {{ changeStr }}
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/eventbus.js";

export default {
  name: "HRSlider",

  props: {
    id: Number,
    type: String,

    step: {
      type: Number,
      default: 1,
    },
    source: Number,
  },

  data: () => ({}),

  created: function() {},

  methods: {
    increment: function() {
      switch (this.type) {
        case "salary":
          {
            let change = this.salaryChange(this.id);
            change += this.step;
            this.$store.commit("hr/setSalaryChange", { id: this.id, change });
          }
          break;
        case "crew":
          {
            if (this.availableToHire > 0) {
              let change = this.crewChange(this.id);
              change += 1;
              this.$store.commit("hr/setCrewChange", { id: this.id, change });
            }
          }
          break;
        default:
          console.log("no such type");
      }
    },
    decrement: function() {
      if (this.total > 0) {
        switch (this.type) {
          case "salary":
            {
              let change = this.salaryChange(this.id);
              change -= this.step;
              this.$store.commit("hr/setSalaryChange", { id: this.id, change });
            }
            break;
          case "crew":
            {
              let change = this.crewChange(this.id);
              change -= this.step;
              this.$store.commit("hr/setCrewChange", { id: this.id, change });
            }
            break;
          default:
            console.log("no such type");
        }
      }
    },
  },

  computed: {
    value: function() {
      switch (this.type) {
        case "crew": {
          switch (this.id) {
            case 2:
            case 3:
            case 4:
              return this.$store.getters["plantation/teamSize"](this.id);
            case 5:
            case 6:
              return this.$store.getters["production/teamSize"](this.id);
            case 7:
              return this.$store.getters["warehouse/teamSize"];
            case 8:
              return this.$store.getters["trade/teamSize"];
            default:
              return -1;
          }
        }
        case "salary":
          return this.salary(this.id);
        default:
          return -1;
      }
    },
    change: function() {
      switch (this.type) {
        case "crew":
          return this.crewChange(this.id);
        case "salary":
          return this.salaryChange(this.id);
        default:
          return -1;
      }
    },
    total: function() {
      return this.value + this.change;
    },
    changeStr: function() {
      if (this.change < 0) {
        return "-" + this.change;
      } else if (this.change > 0) {
        return "+" + this.change;
      } else {
        return "0";
      }
    },
    name: function() {
      switch (this.id) {
        case 2:
          return "Plantacja 1";
        case 3:
          return "Plantacja 2";
        case 4:
          return "Plantacja 3";
        case 5:
          return "Przetwórnia 1";
        case 6:
          return "Przetwórnia 2";
        case 7:
          return "Magazyn";
        case 8:
          return "Handel";
        case 101:
          return "Menedżer, Menedżerka plantacji";
        case 102:
          return "Menedżer, Menedżerka przetwórni";
        case 103:
          return "Menedżer, Menedżerka magazynu";
        case 104:
          return "Menedżer, Menedżerka handlu";
        case 105:
          return "Menedżer, Menedżerka HR";
        case 106:
          return "Planetolog, Planetolożka";
        default:
          return "Undefined";
      }
    },
    icon: function() {
      switch (this.id) {
        case 2:
        case 3:
        case 4:
          return "mdi-flower";
        case 5:
        case 6:
          return "mdi-factory";
        case 7:
          return "mdi-package-variant";
        case 8:
          return "mdi-cart-outline";
        case 101:
        case 102:
        case 103:
        case 104:
        case 105:
        case 106:
          return "mdi-account-voice";
        default:
          return "weight-lifter";
      }
    },
    color: function() {
      switch (this.id) {
        case 2:
        case 3:
        case 4:
          return "green";
        case 5:
        case 6:
          return "orange";
        case 7:
          return "purple";
        case 8:
          return "light-blue";
        default:
          return "black";
        case 101:
        case 102:
        case 103:
        case 104:
        case 105:
        case 106:
          return "pink";
      }
    },
    valueIcon: function() {
      switch (this.type) {
        case "crew":
          return "mdi-account";
        case "salary":
          return "mdi-currency-mnt";
        default:
          return "weight-lifter";
      }
    },
    ...mapGetters("hr", [
      "salary",
      "salaryChange",
      "crewChange",
      "availableToHire",
    ]),
  },
};
</script>
