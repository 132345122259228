<template lang="pug">
  #trade.px-4
    v-row
      v-col
        h1 Zarządzanie handlem
    v-row
      v-col(cols="12") 
        v-row
          v-col#workarea(cols="8")
            v-timeline(dense)
              template(v-for="(n, i) in availablePosts")
                v-timeline-item(:key="i" :icon="postIcon(i)" :color="postColor(i)" fill-dot right)
                  TradePost(v-if="isPostActive(i)" :idx="i")
                  v-card(v-if="isConstructionSite(i)")
                    v-card-title Budowniczowie punktu handlowego
                    v-card-text
                      Team(teamType="builders")
              v-timeline-item(:icon="postIcon(777)" fill-dot right)
                TradePost(v-if="spaceport" :idx="4")
                v-card(v-if="isConstructionSite(777)")
                  v-card-title Budowniczowie portu kosmicznego
                  v-card-text
                    Team(teamType="spaceportBuilders")
          v-col(cols="4")
            v-row
              v-col(cols="12")
                affix(relative-element-selector="#workarea" style="width: 350px")
                  v-card
                    v-card-title Dostępni pracownicy
                    v-card-text
                      Team(teamType="free")         
        
</template>
<script>
import { Affix } from "vue-affix";
import { mapGetters } from 'vuex';

import Team from "@/components/Team";
import TradePost from "@/components/TradePost";


export default {
  name: "Trade",

  components: {
    Team,
    TradePost,
    Affix,
  },

  data: () => ({}),

  methods: {
    isPostActive: function(i) {
      return i < this.readyPosts;
    },
    isConstructionSite: function(i) {
      // magic value spaceport
      if (i !== 777) {
        return i === this.readyPosts;
      } else {
        return !this.spaceport;
      }
    },
    postIcon: function(i) {
      if (this.isPostActive(i)) {
        return "mdi-cart-outline";
      } else if (this.isConstructionSite(i)) {
        return "mdi-hammer-wrench";
      } else if (i === 777 && this.spaceport) {
        return  "mdi-rocket-launch-outline";
      } else {
        return "mdi-cart-outline";
      }
    },
    postColor: function(i) {
      if (this.isPostActive(i)) {
        return "primary";
      } else if (this.isConstructionSite(i)) {
        return "primary";
      } else if (i === 777 && this.spaceport) {
        return  "primary";
      } else {
        return "grey";
      }
    },
  },

  computed: {
    ...mapGetters("trade", [
      "teamSize",
      "readyPosts",
      "availablePosts",
      "spaceport"
    ]),
  }
}
</script>
