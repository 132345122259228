<template lang="pug">
  div
    v-alert(type="error" v-model="gameNotFound" dismissible) Nie znaleziono gry.
    v-alert(type="error" v-model="userNotFound" dismissible) Nie znaleziono użytkownika.
    v-alert(type="error" v-model="authTokenNotFound" dismissible) Nie znaleziono kodu użytkownika.
    v-alert(type="error" v-model="userNotInGame" dismissible) Gracz nie bierze udziału w grze.
    v-alert(type="error" v-model="userDoesNotHaveGameManagerTx" dismissible) Użytkownik nie ma trasmitera gry (błąd wewnętrzny).
    v-alert(type="error" v-model="failedToSendActions" dismissible) Nie udało się zakończyć tury. Sprawdź swój internet i spróbuj ponownie.
    v-alert(type="error" v-model="failedToSelectRole" dismissible) Nie udało się wybrać roli. Sprawdź swój internet i spróbuj ponownie.
    v-alert(type="error" v-model="failedToLogIn" dismissible) Nie udało się zalogować. Sprawdź swój internet i spróbuj ponownie.
    v-alert(type="error" v-model="connectionError" dismissible) Błąd połączenia. Ponawiam...
    v-alert(type="error" v-model="alreadyInGame" dismissible) Gracz już bierze udział w grze.
    v-alert(type="error" v-model="gameAlreadyStarted" dismissible) Gra już się rozpoczęła.
    v-alert(type="error" v-model="internal" dismissible) Błąd wewnętrzny.
</template>
<script>
import { EventBus } from "@/eventbus.js";

export default {
  name: "Error",

  data: () => ({
    gameNotFound: false,
    userNotFound: false,
    authTokenNotFound: false,
    userNotInGame: false,
    userDoesNotHaveGameManagerTx: false,
    failedToSendActions: false,
    failedToSelectRole: false,
    failedToLogIn: false,
    connectionError: false,
    alreadyInGame: false,
    gameAlreadyStarted: false,
    internal: false,
  }),

  created: function() {
    const self = this;
    EventBus.$on("errGameNotFound", function() {
      self.gameNotFound = true;
    });
    EventBus.$on("errUserNotFound", function() {
      self.userNotFound = true;
    });
    EventBus.$on("errAuthTokenNotFound", function() {
      self.authTokenNotFound = true;
    });
    EventBus.$on("errUserNotInGame", function() {
      self.userNotInGame = true;
    });
    EventBus.$on("errUserDoesNotHaveGameManagerTx", function() {
      self.userDoesNotHaveGameManagerTx = true;
    });
    EventBus.$on("failedToSendActions", function() {
      self.failedToSendActions = true;
    });
    EventBus.$on("failedToSelectRole", function() {
      self.failedToSelectRole = true;
    });
    EventBus.$on("failedToLogIn", function() {
      self.failedToLogIn = true;
    });
    EventBus.$on("errConnectionError", function() {
      self.connectionError = true;
    });
    EventBus.$on("connectionRestored", function() {
      self.connectionError = false;
    });
    EventBus.$on("errAlreadyInGame", function() {
      self.alreadyInGame = true;
    });
    EventBus.$on("errGameAlreadyStarted", function() {
      self.gameAlreadyStarted = true;
    });
    EventBus.$on("errInternal", function() {
      self.internal = true;
    });
  },
};
</script>
