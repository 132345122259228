import { EventBus } from "@/eventbus.js";

import router from '@/router/index.js';

const getDefaultState = () => {
  return {
    authToken: null,
    username: null,
    gameToken: null,
  }
}

const state = getDefaultState();

const actions = {
  sync(ctx, resp) {
    let currentUser = resp.playersList.find(u => u.authtoken === ctx.getters.authToken);
    if (!ctx.getters.username) {
      ctx.commit('setUsername', currentUser.username);
      ctx.commit('common/setRole', currentUser.role, { root: true });
      if (ctx.rootGetters['common/isAdmin']) {
        router.push('admin');
      }
    }  
  },
  reset(ctx) {
    ctx.commit('reset');
  },
};

const getters = {
  isLoggedIn: (state) => {
    return Boolean(state.authToken);
  },
  username: (state) => {
    return state.username;
  },
  gameToken: (state) => {
    return state.gameToken;
  },
  authToken: (state) => {
    return state.authToken;
  },
};

const mutations = {
  setAuthToken(state, token) {
    state.authToken = token;
  },
  setUsername(state, username) {
    state.username = username;
  },
  setGameToken(state, token) {
    state.gameToken = token;
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

