<template lang="pug">
div
  v-list
    v-list-item(v-for="player in players" :key="player.id")
      v-list-item-icon
        v-icon(x-large :color="getColor(player)") mdi-account
      v-list-item-content {{ player.username }} - {{ roles[player.role] }}
        span(v-if="isAdmin")
          code(v-if="isAdmin") {{ getUrl(player) }}
      v-list-item-icon
        v-icon(x-large :color="getColor(player)") {{ getMark(player) }}
  v-row(v-if="isAdmin")
    v-col(align="center" justify="space-around")
      v-btn(v-on:click="copyResumeUrlsToClipboard" color="primary") Zapisz linki zapasowe do schowka
</template>
<script>
import { mapGetters } from 'vuex'

import { Player } from '@/bulwa_pb.js';

import Role from '@/mixins/role.js';

export default {
  name: 'PlayersList',

  mixins: [Role,],

  data: () => ({
  }),

  methods: {
    getColor: function(player) {
      if (player.decision || this.isPlayerAdmin(player)) {
        return 'green';
      }
      return 'red';
    },
    getMark: function(player) {
      if (player.decision || this.isPlayerAdmin(player)) {
        return 'mdi-check';
      }
      return 'mdi-timer-sand';
    },
    isPlayerAdmin: function(player) {
      return this.roles[player.role] === 'Admin';
    },
    getUrl: function(player) {
      var resumeUrl = this.$router.resolve({ name: 'Resume', params: { authToken: player.authtoken } }).route.fullPath;
      var baseUrl = window.location.origin;
      return baseUrl + resumeUrl;
    },
    copyResumeUrlsToClipboard: function() {
      console.log(this.players);
      var res = "";
      this.players.forEach(player => {
        var resumeUrl = this.getUrl(player);
        res += player.username + " - " + resumeUrl + "\n";
      });
      navigator.clipboard.writeText(res).then(function() {
        console.log('Async: Copying to clipboard was successful!');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    }
  },

  computed: {
    ...mapGetters('common', [
      'players',
      'role',
      'isAdmin',
    ]),
  },
}
</script>



