import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import actions from "./actions.js";

import auth from "./modules/auth.js";
import common from "./modules/common.js";
import hr from "./modules/hr.js";
import plantation from "./modules/plantation.js";
import production from "./modules/production.js";
import warehouse from "./modules/warehouse.js";
import trade from "./modules/trade.js";
import special from "./modules/special.js";
import params from "./modules/params.js";
import knowledgebase from "./modules/knowledgebase.js";

Vue.use(Vuex);

const persist = new VuexPersistence({
  storage: window.sessionStorage,
  reducer: (state) => ({
    auth: auth,
    authToken: state.authToken,
    username: state.username,
    gameToken: state.gameToken,
    role: state.role,
  }),
});

export default new Vuex.Store({
  strict: process.env.VUEX_STRICT,
  modules: {
    auth: auth,
    common: common,
    hr: hr,
    plantation: plantation,
    production: production,
    warehouse: warehouse,
    trade: trade,
    special: special,
    params: params,
    knowledgebase: knowledgebase,
  },
  actions: actions,
  plugins: [persist.plugin],
});
