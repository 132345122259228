<template>
  <span v-if="timerCount > 0">{{ endTurnTimer }}</span>
</template>

<script>
export default {
  name: 'Timer',

  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true,
    }
  },

  computed: {
    timerCount: function () {
      return this.$store.state.timerCount;
    },
    endTurnTimer: function () {
      return new Date(this.timerCount * 1000).toISOString().substr(11, 8);
    }
  }

}
</script>
