import Vue from "vue";
import { EventBus } from "@/eventbus.js";

import { Action, TradeAction, AssignmentAction } from "@/bulwa_pb.js";

const getDefaultState = () => {
  return {
    trade: {
      readyposts: 0,
      availableposts: 0,
      spaceport: false,
      workersList: [],
    },

    freeWorkers: [],
    offers: [...Array(5)].fill().map((e) => ({yellow: 0, blue: 0, purple: 0, goodDrink: 0, badDrink: 0, yellowPrice: 0, bluePrice: 0, purplePrice: 0, goodDrinkPrice: 0, badDrinkPrice: 0})),
    traders: [...Array(5)].fill().map((e) => Array()),
    builders: [],
    spaceportBuilders: [],
  }
};

const state = getDefaultState();

const actions = {
  sync(ctx, resp) {
    let trade = resp.trade;
    ctx.commit('setTrade', resp.trade);

    const id = ctx.rootGetters['common/roleInt'];
    if (8 === id && ctx.rootGetters["common/turnEnded"]) {
      // free
      let free = trade.workersList.filter((worker) => worker.teamtype === AssignmentAction.TeamType.NONE);
      ctx.commit('setFree', free);

      // builders 
      let builders = trade.workersList.filter((worker) => worker.teamtype === AssignmentAction.TeamType.BUILDERS && worker.idx !== 777);
      ctx.commit('setBuilders', builders);

      // spaceport builders
      let spaceport_builders = trade.workersList.filter((worker) => worker.teamtype === AssignmentAction.TeamType.BUILDERS && worker.idx === 777);
      ctx.commit('setSpaceportBuilders', spaceport_builders);

      // traders
      for (let i = 0; i < ctx.getters.readyPosts; i++) {
        let workers = trade.workersList.filter((worker) => worker.teamtype === AssignmentAction.TeamType.TRADERS && worker.idx === i);
        ctx.commit('setTraders', { idx: i, v: workers });
      }

      // spaceport traders
      if (ctx.getters.spaceport) {
        console.log(trade.workersList);
        let workers = trade.workersList.filter((worker) => worker.teamtype === AssignmentAction.TeamType.TRADERS && worker.idx === 777);
        console.log(workers);
        ctx.commit('setTraders', { idx: 4, v: workers });
      }
    }
  },
  reset(ctx) {
    ctx.commit('reset');
  },
};

const getters = {
  toPlayerActions: (state, getters, rootState, rootGetters) => {
    let actions = [];

    // trade offers
    for (let i = 0; i < getters.readyPosts; i++) {
      let action = new Action();
      let tA = new TradeAction();
      tA.setYellow(getters.offer(i).yellow);
      tA.setBlue(getters.offer(i).blue);
      tA.setPurple(getters.offer(i).purple);
      tA.setGooddrink(getters.offer(i).goodDrink);
      tA.setBaddrink(getters.offer(i).badDrink);
      tA.setYellowprice(getters.offer(i).yellowPrice);
      tA.setBlueprice(getters.offer(i).bluePrice);
      tA.setPurpleprice(getters.offer(i).purplePrice);
      tA.setBaddrinkprice(getters.offer(i).badDrinkPrice);
      tA.setGooddrinkprice(getters.offer(i).goodDrinkPrice);
      tA.setPostidx(i);
      action.setTrade(tA);
      actions.push(action);
    }

    // spaceport trade offer
    if (getters.spaceport) {
      let action = new Action();
      let tA = new TradeAction();
      tA.setYellow(getters.offer(4).yellow);
      tA.setBlue(getters.offer(4).blue);
      tA.setPurple(getters.offer(4).purple);
      tA.setGooddrink(getters.offer(4).goodDrink);
      tA.setBaddrink(getters.offer(4).badDrink);
      tA.setYellowprice(getters.offer(4).yellowPrice);
      tA.setBlueprice(getters.offer(4).bluePrice);
      tA.setPurpleprice(getters.offer(4).purplePrice);
      tA.setBaddrinkprice(getters.offer(4).badDrinkPrice);
      tA.setGooddrinkprice(getters.offer(4).goodDrinkPrice);
      tA.setPostidx(777);
      action.setTrade(tA);
      actions.push(action);
    }

    // traders
    for (let i = 0; i < getters.readyPosts; i++) {
      for (const trader of getters.traders(i)) { 
        let action = new Action();
        let aA = new AssignmentAction();
        aA.setPersonid(trader.id);
        aA.setSource(rootGetters['common/roleInt']);
        aA.setTeamtype(AssignmentAction.TeamType.TRADERS);
        aA.setTeamid(i);
        action.setAssignment(aA);
        actions.push(action);
      }
    }

    if (getters.spaceport) {
      for (const trader of getters.traders(4)) { 
        let action = new Action();
        let aA = new AssignmentAction();
        aA.setPersonid(trader.id);
        aA.setSource(rootGetters['common/roleInt']);
        aA.setTeamtype(AssignmentAction.TeamType.TRADERS);
        aA.setTeamid(777);
        action.setAssignment(aA);
        actions.push(action);
      }
    }

    // free
    for (const worker of getters.freeWorkers) {
      let action = new Action();
      let aA = new AssignmentAction();
      aA.setPersonid(worker.id);
      aA.setSource(rootGetters['common/roleInt']);
      aA.setTeamtype(AssignmentAction.TeamType.NONE);
      aA.setTeamid(0);
      action.setAssignment(aA);
      actions.push(action);
    }

    // builders
    for (const worker of getters.builders) {
      let action = new Action();
      let aA = new AssignmentAction();
      aA.setPersonid(worker.id);
      aA.setSource(rootGetters['common/roleInt']);
      aA.setTeamtype(AssignmentAction.TeamType.BUILDERS);
      aA.setTeamid(getters.readyPosts);
      action.setAssignment(aA);
      actions.push(action);
    }

    // spaceport builders
    for (const worker of getters.spaceportBuilders) {
      let action = new Action();
      let aA = new AssignmentAction();
      aA.setPersonid(worker.id);
      aA.setSource(rootGetters['common/roleInt']);
      aA.setTeamtype(AssignmentAction.TeamType.BUILDERS);
      // see build system
      aA.setTeamid(777);
      action.setAssignment(aA);
      actions.push(action);
    }

    return actions;
  },
  teamSize: (state) => {
    return state.trade.workersList.length;
  },
  readyPosts: (state, getters, rootState, rootGetters) => {
    return state.trade.readyposts;
  },
  availablePosts: (state, getters, rootState, rootGetters) => {
    return state.trade.availableposts;
  },
  builders: (state) => {
    return state.builders;
  },
  spaceportBuilders: (state) => {
    return state.spaceportBuilders;
  },
  freeWorkers: (state) => {
    return state.freeWorkers;
  },
  traders: (state) => (idx) => {
    return state.traders[idx];
  },
  offer: (state) => (idx) => {
    return state.offers[idx];
  },
  spaceport: (state) => {
    return state.trade.spaceport;
  },
};

const mutations = {
  setYellow(state, { idx, v }) {
    state.offers[idx].yellow = v;
  },
  setBlue(state, { idx, v }) {
    state.offers[idx].blue = v;
  },
  setPurple(state, { idx, v }) {
    state.offers[idx].purple = v;
  },
  setBadDrink(state, { idx, v }) {
    state.offers[idx].badDrink = v;
  },
  setGoodDrink(state, { idx, v }) {
    state.offers[idx].goodDrink = v;
  },
  setYellowPrice(state, { idx, v }) {
    state.offers[idx].yellowPrice = v;
  },
  setBluePrice(state, { idx, v }) {
    state.offers[idx].bluePrice = v;
  },
  setPurplePrice(state, { idx, v }) {
    state.offers[idx].purplePrice = v;
  },
  setBadDrinkPrice(state, { idx, v }) {
    state.offers[idx].badDrinkPrice = v;
  },
  setGoodDrinkPrice(state, { idx, v }) {
    state.offers[idx].goodDrinkPrice = v;
  },
  setTrade(state, trade) {
    state.trade = trade;
  },
  setBuilders(state, v) {
    state.builders = v;
  },
  setSpaceportBuilders(state, v) {
    state.spaceportBuilders = v;
  },
  setFree(state, v) {
    state.freeWorkers = v;
  },
  setTraders(state, { idx, v }) {
    Vue.set(state.traders, idx, v);
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

