import { render, staticRenderFns } from "./HRSlider.vue?vue&type=template&id=00d96dc8&lang=pug&"
import script from "./HRSlider.vue?vue&type=script&lang=js&"
export * from "./HRSlider.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports