let actions = {
  async sync({ commit, dispatch }, resp) {
    resp = resp.toObject();
    await dispatch("auth/sync", resp, { root: true });
    await dispatch("params/sync", resp, { root: true });
    await dispatch("common/sync", resp, { root: true });
    await dispatch("plantation/sync", resp, { root: true });
    await dispatch("production/sync", resp, { root: true });
    await dispatch("warehouse/sync", resp, { root: true });
    await dispatch("trade/sync", resp, { root: true });
    await dispatch("hr/sync", resp, { root: true });
  },
  async reset({ commit, dispatch }) {
    await dispatch("auth/reset", { root: true });
    await dispatch("params/reset", { root: true });
    await dispatch("common/reset", { root: true});
    await dispatch("plantation/reset", { root: true });
    await dispatch("production/reset", { root: true });
    await dispatch("warehouse/reset", { root: true });
    await dispatch("trade/reset", { root: true });
    await dispatch("hr/reset", { root: true });
  },
};

export default actions;
