import { SpecialAction, Action } from "@/bulwa_pb.js";

const state = () => ({
  availableActions: [],
  actions: [],
});

const actions = {
};

const getters = {
  toPlayerActions: (state, getters, rootState, rootGetters) => {
    let actions = [];

    for (const { kind, price } of state.actions) {
      let action = new Action();
      let sA = new SpecialAction();
      sA.setKind(kind);
      sA.setPrice(price);
      sA.setSource(rootGetters['common/roleInt']);
      action.setSpecial(sA);
      actions.push(action);
    }

    return actions;
  },
  availableActions: (state) => {
    return state.availableActions;
  },
};

const mutations = {
  addAction(state, { kind, price }) {
    state.actions.push({ kind, price });
  },
  addAvailableAction(state, { kind, price }) {
    state.availableActions.push({ kind, price });
  },
  clearActions(state) {
    state.actions = [];
  },
  clearAvailableActions(state) {
    state.availableActions = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};


