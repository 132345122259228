const getDefaultState = () => {
  return {
    titles: [],
  };
};

const state = getDefaultState();

const getters = {
  titles: (state, getters, rootState, rootGetters) => {
    switch (rootGetters["common/roleInt"]) {
      case 2:
      case 3:
      case 4:
        return [
          "Plantacja",
          "Cykl życia bulwy Ngoro",
          "Wpływ pogody na rozwój bulwy Ngoro",
          "Wpływ pogody na rozmnażanie się bulwy Ngoro",
          "Rola ogrodnika",
          "Rola zbieracza",
          "Kręgi uprawne",
        ];
      case 5:
      case 6:
        return [
          "Przetwórnia",
          "Napój Kwanai",
          "Przepis na napój Kwanai",
          "Obsada linii produkcyjnych",
          "Napój złej jakości",
        ];
      case 7:
        return [
          "Magazyn",
          "Kolejka obsługi zleceń",
          "Przyjmowanie i przechowywanie bulwy Ngoro",
          "Przyjmowanie i przechowywanie napoju Kwanai",
          "Inwentaryzacja",
        ];
      case 8:
        return [
          "Handel",
          "Sytuacja na rynku bulwy Ngoro",
          "Sytuacja na rynku napoju Kwanai",
        ];
      case 9:
        return [
          "Zarządzanie personelem",
          "Wizerunek firmy i pula kandydatów",
          "Zatrudnianie i zwalnianie pracowników",
          "Wynagradzanie pracowników",
          "Zaangażowanie pracowników",
          "Satysfakcja pracowników",
          // "Zdarzenia losowe",
        ];
      case 10:
        return [
          "Rola planetologa",
          "Cykle pogodowe na Minerwie",
          "Minerwa",
          "Populacja Minerwy i sytuacja żywnościowa",
          "Kultura Minerwy, warunki pracy i wynagrodzenie",
          "Egalitaryzm w kulturze Minerwy",
          "Długofalowe cele plantacji",
        ];
      default:
        throw "role not found in knowledgebase";
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
};
