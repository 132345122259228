import Vue from "vue";

import {
  Action,
  HarvestAction,
  AssignmentAction,
  WorkingTimeAction,
} from "@/bulwa_pb.js";

const getDefaultState = () => {
  return {
    plantations: [],

    harvest: [...Array(20)]
      .fill()
      .map((e) => ({ shoots: 0, yellow: 0, blue: 0, purple: 0 })),
    freeWorkers: [],
    harvesters: [...Array(20)].fill().map((e) => Array()),
    gardeners: [...Array(20)].fill().map((e) => Array()),
    cleaners: [...Array(20)].fill().map((e) => Array()),
    builders: [],
    workingTime: 8,
    harvestersWorkingTime: 8,
  };
};

const state = getDefaultState();

const actions = {
  sync(ctx, resp) {
    let plantations = resp.plantationsList;
    ctx.commit("setPlantations", plantations);

    const id = ctx.rootGetters["common/roleInt"];
    if ([2, 3, 4].includes(id) && ctx.rootGetters["common/turnEnded"]) {
      // free
      let free = plantations[id - 2].workersList.filter(
        (worker) => worker.teamtype === AssignmentAction.TeamType.NONE
      );
      ctx.commit("setFree", free);
      // builders
      let builders = plantations[id - 2].workersList.filter(
        (worker) => worker.teamtype === AssignmentAction.TeamType.BUILDERS
      );
      ctx.commit("setBuilders", builders);
      // harvesters
      for (let i = 0; i < ctx.getters.readyCircles; i++) {
        let workers = plantations[id - 2].workersList.filter(
          (worker) =>
            worker.teamtype === AssignmentAction.TeamType.HARVESTERS &&
            worker.idx === i
        );
        ctx.commit("setHarvesters", { idx: i, v: workers });
      }
      // gardeners
      for (let i = 0; i < ctx.getters.readyCircles; i++) {
        let workers = plantations[id - 2].workersList.filter(
          (worker) =>
            worker.teamtype === AssignmentAction.TeamType.GARDENERS &&
            worker.idx === i
        );
        ctx.commit("setGardeners", { idx: i, v: workers });
      }
      // cleaners
      for (let i = 0; i < ctx.getters.readyCircles; i++) {
        let workers = plantations[id - 2].workersList.filter(
          (worker) =>
            worker.teamtype === AssignmentAction.TeamType.CLEANERS &&
            worker.idx === i
        );
        ctx.commit("setCleaners", { idx: i, v: workers });
      }
    }
  },
  reset(ctx) {
    ctx.commit("reset");
  },
};

const mutations = {
  setPlantations(state, plantations) {
    state.plantations = plantations;
  },
  setShoots(state, { idx, value }) {
    state.harvest[idx].shoots = value;
  },
  setYellow(state, { idx, value }) {
    state.harvest[idx].yellow = value;
  },
  setBlue(state, { idx, value }) {
    state.harvest[idx].blue = value;
  },
  setPurple(state, { idx, value }) {
    state.harvest[idx].purple = value;
  },
  setHarvesters(state, { idx, v }) {
    Vue.set(state.harvesters, idx, v);
  },
  setGardeners(state, { idx, v }) {
    Vue.set(state.gardeners, idx, v);
  },
  setCleaners(state, { idx, v }) {
    Vue.set(state.cleaners, idx, v);
  },
  setBuilders(state, v) {
    state.builders = v;
  },
  setFree(state, v) {
    state.freeWorkers = v;
  },
  setWorkingTime(state, v) {
    state.workingTime = v;
  },
  setHarvestersWorkingTime(state, v) {
    state.harvestersWorkingTime = v;
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  teamSize: (state) => (id) => {
    if (state.plantations.length > 0) {
      return state.plantations[id - 2].workersList.length;
    }
    return 0;
  },
  harvest: (state) => (idx) => {
    return state.harvest[idx];
  },
  harvesters: (state) => (idx) => {
    return state.harvesters[idx];
  },
  gardeners: (state) => (idx) => {
    return state.gardeners[idx];
  },
  cleaners: (state) => (idx) => {
    return state.cleaners[idx];
  },
  builders: (state) => {
    return state.builders;
  },
  freeWorkers: (state) => {
    return state.freeWorkers;
  },
  readyCircles: (state, getters, rootState, rootGetters) => {
    if (state.plantations.length > 0) {
      return state.plantations[rootGetters["common/roleInt"] - 2].readycircles;
    }
    return 0;
  },
  availableCircles: (state, getters, rootState, rootGetters) => {
    if (state.plantations.length > 0) {
      return state.plantations[rootGetters["common/roleInt"] - 2]
        .availablecircles;
    }
    return 0;
  },
  circles: (state, getters, rootState, rootGetters) => {
    if (state.plantations.length > 0) {
      return state.plantations[rootGetters["common/roleInt"] - 2].circlesList;
    }
    return 0;
  },
  workersList: (state, getters, rootState, rootGetters) => {
    if (state.plantations.length > 0) {
      return state.plantations[rootGetters["common/roleInt"] - 2].workersList;
    }
    return 0;
  },
  workingTime: (state) => {
    return state.workingTime;
  },
  harvestersWorkingTime: (state) => {
    return state.harvestersWorkingTime;
  },

  toPlayerActions: (state, getters, rootState, rootGetters) => {
    let actions = [];

    // working time
    let action = new Action();
    let wtA = new WorkingTimeAction();
    wtA.setLength(getters.workingTime);
    wtA.setSource(rootGetters["common/roleInt"]);
    action.setWorkingtime(wtA);
    actions.push(action);

    // harvest
    for (let i = 0; i < getters.readyCircles; i++) {
      let action = new Action();
      let hA = new HarvestAction();
      hA.setShoots(getters.harvest(i).shoots);
      hA.setYellow(getters.harvest(i).yellow);
      hA.setBlue(getters.harvest(i).blue);
      hA.setPurple(getters.harvest(i).purple);
      hA.setCircleidx(i);
      hA.setSource(rootGetters["common/roleInt"]);
      action.setHarvest(hA);
      actions.push(action);
    }

    // team assignments
    for (let i = 0; i < getters.readyCircles; i++) {
      // harvesters
      for (const harvester of getters.harvesters(i)) {
        let action = new Action();
        let aA = new AssignmentAction();
        aA.setPersonid(harvester.id);
        aA.setSource(rootGetters["common/roleInt"]);
        aA.setTeamtype(AssignmentAction.TeamType.HARVESTERS);
        aA.setTeamid(i);
        action.setAssignment(aA);
        actions.push(action);
      }

      // gardeners
      for (const gardener of getters.gardeners(i)) {
        let action = new Action();
        let aA = new AssignmentAction();
        aA.setPersonid(gardener.id);
        aA.setSource(rootGetters["common/roleInt"]);
        aA.setTeamtype(AssignmentAction.TeamType.GARDENERS);
        aA.setTeamid(i);
        action.setAssignment(aA);
        actions.push(action);
      }

      // cleaners
      for (const cleaner of getters.cleaners(i)) {
        let action = new Action();
        let aA = new AssignmentAction();
        aA.setPersonid(cleaner.id);
        aA.setSource(rootGetters["common/roleInt"]);
        aA.setTeamtype(AssignmentAction.TeamType.CLEANERS);
        aA.setTeamid(i);
        action.setAssignment(aA);
        actions.push(action);
      }
    }

    // free
    for (const worker of getters.freeWorkers) {
      let action = new Action();
      let aA = new AssignmentAction();
      aA.setPersonid(worker.id);
      aA.setSource(rootGetters["common/roleInt"]);
      aA.setTeamtype(AssignmentAction.TeamType.NONE);
      aA.setTeamid(0);
      action.setAssignment(aA);
      actions.push(action);
    }

    // builders
    for (const worker of getters.builders) {
      let action = new Action();
      let aA = new AssignmentAction();
      aA.setPersonid(worker.id);
      aA.setSource(rootGetters["common/roleInt"]);
      aA.setTeamtype(AssignmentAction.TeamType.BUILDERS);
      aA.setTeamid(getters.readyCircles);
      action.setAssignment(aA);
      actions.push(action);
    }

    return actions;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
