<template lang="pug">
  #hrmanagement.px-4
    v-row
      v-col
        h1 Zarządzanie ludźmi
    v-row
      v-col(md="6" sm="12")
        v-row(justify="space-between")
          v-col(cols="1")
            h2 Personel
          v-col(cols="4")
            v-chip(color="green" text-color="white")
              v-avatar.green.darken-4(left)
                | {{ availableToHire }}
              | Chętni do pracy
        v-row
          v-col(v-for="facility_id in facilities" cols="4")
            HRSlider(:id="facility_id" type="crew")
      v-col(md="6" sm="12")
        v-row
          v-col
            h2 Płace
        v-row
          v-col(v-for="facility_id in facilities" cols="4")
            HRSlider(:id="facility_id" type="salary")
      v-col(cols="12")
        v-row
          v-col
            h2 Płace Zarządu
        v-row
          v-col(v-for="manager_id in managers" md="3" sm="4")
            HRSlider(:id="manager_id" type="salary" :step="1")
</template>

<script>
import { GameClient } from "@/bulwa_grpc_web_pb.js";
import { PlayerActions, Action, RecruitmentAction } from "@/bulwa_pb.js";
import { mapGetters } from "vuex";
import { EventBus } from "@/eventbus.js";

import HRSlider from "@/components/HRSlider";

export default {
  name: "HRManagement",

  props: {
    readonly: Boolean,
    id: Number,
  },

  components: {
    HRSlider,
  },

  data: () => ({
    managers: [101, 102, 103, 104, 105, 106],
  }),

  created: function() {
    this.initSpecialActions();
  },

  methods: {
    isReadonly: function() {
      return this.readonly;
    },
    initSpecialActions: function() {
      this.$store.commit('common/setSpecialActions', [
          { 'name': 'Badanie zaangażowania', 'cost': 100 },
          { 'name': 'Badanie satysfakcji', 'cost': 100 },
          { 'name': 'Badanie wizerunku', 'cost': 100 },
          { 'name': 'Polityka kadrowa (nowi)', 'cost': 0 },
          { 'name': 'Polityka kadrowa (eksperci)', 'cost': 0 },
          { 'name': 'Odzież ochronna', 'cost': 500 },
          { 'name': 'Komfort pracy przetwórni', 'cost': 500 },
        ]
      );
    },
  },

  computed: {
    facilities: function() {
      // TODO use mixin or smth
      let tmp = [2, 3, 4, 5, 6, 7, 8];
      return tmp.filter(item => {
        switch (item) {
          case 2:
            return this.plantation1;
          case 3:
            return this.plantation2;
          case 4:
            return this.plantation3;
          case 5:
            return this.production1;
          case 6:
            return this.production2;
          case 7:
          case 8:
            // warehouse and trade are always present
            return true;
          default:
            console.log("hr facilities filtering - should not happen");
            return undefined;
        }
      });
    },
    ...mapGetters("params", ["plantation1", "plantation2", "plantation3", "production1", "production2"]),
    ...mapGetters("hr", ["availableToHire"]),
  },
};
</script>
