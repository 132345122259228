<template lang="pug">
  #warehouse.px-4
    v-row
      v-col
        h1 Zarządzanie magazynem
    v-row
      v-spacer
      v-col(cols="5")
        v-card(outlined shaped raised)
          v-card-title Stan magazynu
          v-card-text
            v-row(align="center")
              v-spacer
              v-col(cols="8")
                DoughnutChart(:chartData="stockStatus" title="hide")
              v-spacer
      v-spacer
      v-col(cols="5")
        v-card(outlined shaped raised)
          v-card-title Rozbudowa
          v-card-text
            v-row(align="center")
              v-spacer
              v-col(cols="8")
                DoughnutChart(:chartData="warehouseStatus" title="hide")
              v-spacer
      v-spacer
    v-row
      v-spacer
      v-col(cols="5")
        v-card(outlined shaped raised)
          v-card-title Alokacja przestrzeni
          v-card-text
            v-slider(v-model="bulbsPercentage" min="0" max="100")
              template(v-slot:prepend)
                v-text-field.mt-0.pt-0(v-model="bulbsPercentage" type="number" style="width: 90px" suffix="%" prepend-icon="mdi-spa-outline" color="#4DB6AC" readonly)
              template(v-slot:append)
                v-text-field.mt-0.pt-0(v-model="drinksPercentage" type="number" style="width: 90px" suffix="%" prepend-icon="mdi-bottle-soda-classic-outline" color="#9575CD" readonly)
      v-spacer
      v-col(cols="5")
        v-card(outlined shaped raised)
          v-card-title Priorytet logistyki
          v-card-text
            v-simple-table
              template(v-slot:default)
                tbody
                  tr(v-for="p in priority" :id="p.id")
                    td 
                      v-icon(@click="priorityUp(p)") mdi-arrow-up-bold
                    td {{ p.name }}
                    td
                      v-icon(@click="priorityDown(p)") mdi-arrow-down-bold
      v-spacer
    v-row
      v-spacer
      v-col#workarea(cols="6")
        v-card(outlined shaped raised)
          v-card-text
          v-timeline(dense)
            v-timeline-item(icon="mdi-home-import-outline" fill-dot right)
              v-card.mr-6
                v-card-title Magazynierzy
                v-card-text
                  Team(teamType="warehousemen")
            v-timeline-item(icon="mdi-hammer" fill-dot right)
              v-card.mr-6
                v-card-title Budowniczowie
                v-card-text
                  Team(teamType="builders")
      v-spacer
      v-col(cols="4")
        affix(relative-element-selector="#workarea")
              v-card(outlined shaped raised)
                v-card-title Dostępni pracownicy
                v-card-text
                  Team(teamType="free")         
      v-spacer
            
</template>
<script>
import { Affix } from "vue-affix";
import { mapGetters } from "vuex";

import DoughnutChart from "@/components/DoughnutChart";
import Team from "@/components/Team";

export default {
  name: "WarehouseManagement",

  components: {
    DoughnutChart,
    Team,
    Affix,
  },

  data: () => ({
    warehouseTitle: "Rozbudowa magazynu",
    stockTitle: "Stan magazynu",
    allocationTitle: "Alokacja przestrzeni",
  }),

  created: function () {
    this.initSpecialActions();
  },

  methods: {
    initSpecialActions: function () {
      this.$store.commit("common/setSpecialActions", [
        { name: "Inspekcja", cost: 100 },
      ]);
    },
    priorityUp: function (item) {
      var priority = this["warehouse/priority"];
      var idx = priority.findIndex((e) => e.id === item.id);
      if (idx > 0) {
        var tmp = priority[idx];
        priority[idx] = priority[idx - 1];
        priority[idx - 1] = tmp;
      }
      this.$store.commit("warehouse/setPriority", priority);
    },
    priorityDown: function (item) {
      var priority = this["warehouse/priority"];
      var idx = priority.findIndex((e) => e.id === item.id);
      if (idx < priority.length - 1) {
        var tmp = priority[idx];
        priority[idx] = priority[idx + 1];
        priority[idx + 1] = tmp;
      }
      this.$store.commit("warehouse/setPriority", priority);
    },
  },

  computed: {
    stockStatus: function () {
      let mask = [
        this.yellow,
        this.blue,
        this.purple,
        this.badDrink,
        this.goodDrink,
        this.capacity -
          this.yellow -
          this.blue -
          this.purple -
          this.badDrink -
          this.goodDrink,
      ];
      mask = mask.map((e) => e > 0);

      let labels = [
        "Żółte Ngoro",
        "Niebieskie Ngoro",
        "Fioletowe Ngoro",
        "Napój Kwanai złej jakości",
        "Napój Kwanai dobrej jakości",
        "Pusta przestrzeń",
      ].filter((e, i) => mask[i]);
      let datasets = [
        {
          data: [
            this.yellow,
            this.blue,
            this.purple,
            this.badDrink,
            this.goodDrink,
            this.capacity -
              this.yellow -
              this.blue -
              this.purple -
              this.badDrink -
              this.goodDrink,
          ].filter((e, i) => mask[i]),
          backgroundColor: [
            "yellow",
            "blue",
            "purple",
            "black",
            "orange",
            "gray",
          ].filter((e, i) => mask[i]),
        },
      ];
      return { labels, datasets };
    },
    warehouseStatus: function () {
      let labels = ["Dostępna przestrzeń", "Przestrzeń do rozbudowy"];
      let datasets = [
        {
          data: [this.capacity, this.maxCapacity - this.capacity],
          backgroundColor: ["pink", "gray"],
        },
      ];
      return { labels, datasets };
    },
    allocationStatus: function () {
      return {
        labels: ["Bulwy Ngoro", "Napój Kwanai"],
        datasets: [
          {
            data: [this.bulbsPercentage, 100 - this.bulbsPercentage],
            backgroundColor: ["green", "purple"],
          },
        ],
      };
    },
    priority: {
      get() {
        return this["warehouse/priority"];
      },
    },
    bulbsPercentage: {
      get() {
        return this["warehouse/bulbsPercentage"];
      },
      set(v) {
        this.$store.commit("warehouse/setBulbsPercentage", v);
      },
    },
    drinksPercentage: function () {
      return 100 - this["warehouse/bulbsPercentage"];
    },
    ...mapGetters("warehouse", [
      "capacity",
      "maxCapacity",
      "yellow",
      "blue",
      "purple",
      "badDrink",
      "goodDrink",
      "teamSize",
    ]),
    ...mapGetters(["warehouse/bulbsPercentage", "warehouse/priority"]),
  },
};
</script>
