<template lang="pug">
  div
    v-col(cols="8" offset="2")
      v-sheet(rounded="lg")
        v-row
          v-col(cols="12")
            h1.display-2.font-weight-bold.text-center Witaj w Plantacji!
        v-row
          v-col(cols="10" offset="1")
            v-form(@submit.prevent="login")
              v-row
                v-col(cols="10" offset="1")
                  v-text-field(v-model="username" label="Jak masz na imię?" required)
              v-row
                v-col(cols="2" offset="5")
                  v-btn.mr-4(block type="submit" color="success") Dalej
</template>

<script>
import { LoginRequest } from "../proto/auth_pb.js";
import { AuthClient } from "../proto/auth_grpc_web_pb.js";

import { EventBus } from "@/eventbus.js";

export default {
  name: "Login",

  metaInfo: {
    title: "Logowanie",
  },

  data: () => ({
    username: "",
  }),

  props: ["authToken"],

  created: function() {
    this.client = new AuthClient(process.env.VUE_APP_BACKEND_URL, null, null);
    console.log(`AuthToken: ${this.authToken}`);
    if (this.authToken) {
      this.$store.commit("auth/setAuthToken", this.authToken);
      this.$router.push("/game");
    }
  },

  methods: {
    login: function() {
      let loginRequest = new LoginRequest();
      loginRequest.setUsername(this.username);
      const self = this;
      this.client.login(loginRequest, {}, (err, response) => {
        if (err) {
          EventBus.$emit("failedToLogIn");
        } else {
          let authToken = response.toObject().authtoken;
          self.$store.commit("auth/setAuthToken", authToken);
          self.$store.commit("auth/setUsername", this.$data.username);
          self.$router.push("game-selection");
        }
      });
    },
  },
};
</script>
