import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueMeta from 'vue-meta';

import store from './store/index.js'

Vue.config.productionTip = false;

export default store;

Sentry.init({
  Vue,
  dsn: "https://c33da654426f4774b3a1c833622b2594@o923230.ingest.sentry.io/5870368",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  logErrors: true,
});


Vue.use(VueMeta);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
  store: store,
}).$mount("#app");
