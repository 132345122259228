import Vue from "vue";

import { Action, AssignmentAction, WarehouseAction } from "@/bulwa_pb.js";

const getDefaultState = () => {
  return {
    warehouse: {
      capacity: 0,
      maxcapacity: 0,
      yellow: 0,
      blue: 0,
      purple: 0,
      baddrink: 0,
      gooddrink: 0,
      workersList: [],
    },
    bulbsPercentage: 80,
    // todo use roles mixin or smth
    priority: [
      { id: 2, name: "Plantacja 1" },
      { id: 3, name: "Plantacja 2" },
      { id: 4, name: "Plantacja 3" },
      { id: 5, name: "Przetwórnia 1" },
      { id: 6, name: "Przetwórnia 2" },
      { id: 8, name: "Handel" },
    ],

    freeWorkers: [],
    warehousemen: [],
    builders: [],
  }
};

const state = getDefaultState();

const actions = {
  sync(ctx, resp) {
    let warehouse = resp.warehouse;
    ctx.commit('setWarehouse', warehouse);

    let priority_filtered = ctx.getters.priority.filter(item => {
      switch (item.id) {
        case 2:
          return resp.gameparams.plantation1;
        case 3:
          return resp.gameparams.plantation2;
        case 4:
          return resp.gameparams.plantation3;
        case 5:
          return resp.gameparams.production1;
        case 6:
          return resp.gameparams.production2;
        case 8:
          // trade is always present
          return true;
        default:
          console.log("priority filtering - should not happen")
          return undefined;
      }
    });

    ctx.commit('setPriority', priority_filtered);
 
    const id = ctx.rootGetters['common/roleInt'];
    if ([7].includes(id) && ctx.rootGetters["common/turnEnded"]) {
      // free
      let free = warehouse.workersList.filter((worker) => worker.teamtype === AssignmentAction.TeamType.NONE);
      ctx.commit('setFree', free);
      // warehousemen
      let warehousemen = warehouse.workersList.filter((worker) => worker.teamtype === AssignmentAction.TeamType.WAREHOUSEMEN);
      ctx.commit('setWarehousemen', warehousemen);
      // builders
      let builders = warehouse.workersList.filter((worker) => worker.teamtype === AssignmentAction.TeamType.BUILDERS);
      ctx.commit('setBuilders', builders);
    }
  },
  reset(ctx) {
    ctx.commit('reset');
  },
};

const getters = {
  toPlayerActions: (state, getters, rootState, rootGetters) => {
    let actions = [];
    let action = new Action();
    let wA = new WarehouseAction();
   
    // priorities
    wA.setPriorityList(state.priority.map(e => e.id));

    // bulbs percentage
    wA.setBulbspercentage(state.bulbsPercentage);

    action.setWarehouse(wA);
    actions.push(action);

    // warehousemen
    for (const worker of getters.warehousemen) {
      let action = new Action();
      let aA = new AssignmentAction();
      aA.setPersonid(worker.id);
      aA.setSource(rootGetters['common/roleInt']);
      aA.setTeamtype(AssignmentAction.TeamType.WAREHOUSEMEN);
      aA.setTeamid(0);
      action.setAssignment(aA);
      actions.push(action);
    }
    
    // builders
    for (const worker of getters.builders) {
      console.log('worker');
      let action = new Action();
      let aA = new AssignmentAction();
      aA.setPersonid(worker.id);
      aA.setSource(rootGetters['common/roleInt']);
      aA.setTeamtype(AssignmentAction.TeamType.BUILDERS);
      aA.setTeamid(0);
      action.setAssignment(aA);
      actions.push(action);
    }

    // free
    for (const worker of getters.freeWorkers) {
      let action = new Action();
      let aA = new AssignmentAction();
      aA.setPersonid(worker.id);
      aA.setSource(rootGetters['common/roleInt']);
      aA.setTeamtype(AssignmentAction.TeamType.NONE);
      aA.setTeamid(0);
      action.setAssignment(aA);
      actions.push(action);
    }

    return actions;
  },
  priority: (state) => {
    return state.priority;
  },
  bulbsPercentage: (state) => {
    return state.bulbsPercentage;
  },
  capacity: (state) => {
    return state.warehouse.capacity;
  },
  maxCapacity: (state) => {
    return state.warehouse.maxcapacity;
  },
  yellow: (state) => {
    return state.warehouse.yellow;
  },
  blue: (state) => {
    return state.warehouse.blue;
  },
  purple: (state) => {
    return state.warehouse.purple;
  },
  badDrink: (state) => {
    return state.warehouse.baddrink;
  },
  goodDrink: (state) => {
    return state.warehouse.gooddrink;
  },
  warehousemen: (state) => {
    return state.warehousemen;
  },
  builders: (state) => {
    return state.builders;
  },
  freeWorkers: (state) => {
    return state.freeWorkers;
  },
  teamSize: (state) => {
    return state.warehouse.workersList.length;
  },
};

const mutations = {
  setPriority(state, priority) {
    state.priority = priority;
  },
  setBulbsPercentage(state, p) {
    state.bulbsPercentage = p;
  },
  setWarehouse(state, warehouse) {
    state.warehouse = warehouse;
  },
  setWarehousemen(state, v) {
    state.warehousemen = v;
  },
  setBuilders(state, v) {
    state.builders = v;
  },
  setFree(state, v) {
    state.freeWorkers = v;
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
