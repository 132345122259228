<template lang="pug">
  v-app-bar
    v-tabs(v-model="tab" background-color="primary" dark)
      v-tabs-slider(color="yellow")
      v-tab(key="params") Parametry
      v-tab(key="players") Gracze
      v-tab(key="turn-summary") Podsumowanie tury
      v-tab(key="game-summary") Podsumowanie gry

      v-tabs-items(v-model="tab")
        v-tab-item(key="params")
          Params
        v-tab-item(key="players")
          PlayersList
        v-tab-item(key="turn-summary")
          TurnSummary
        v-tab-item(key="game-summary")
          GameSummary
</template>

<script>
import { mapGetters } from "vuex";

import { EventBus } from "@/eventbus.js";

import PlayersList from "@/components/PlayersList";
import Params from "@/components/Params";
import TurnSummary from "@/components/TurnSummary";
import GameSummary from "@/components/GameSummary";

export default {
  name: "Admin",

  components: {
    PlayersList,
    Params,
    TurnSummary,
    GameSummary,
  },

  metaInfo: {
    title: "Admin",
  },

  data: () => ({
    tab: null,
  }),
};
</script>
