import Vue from "vue";

import { Action, AssignmentAction, ProductionAction } from "@/bulwa_pb.js";

const getDefaultState = () => {
  return {
    productions: [],

    freeWorkers: [],
    requests: [...Array(5)].fill().map((e) => ({yellow: 0, blue: 0, bottles: 0})),
    operators: [...Array(5)].fill().map((e) => Array()),
    experts: [...Array(5)].fill().map((e) => Array()),
    builders: [],
    destroyBadDrink: false,
  }
};

const state = getDefaultState();

const actions = {
  sync(ctx, resp) {
    let productions = resp.productionsList;
    ctx.commit('setProductions', productions);

    const id = ctx.rootGetters['common/roleInt'];
    if ([5, 6].includes(id) && ctx.rootGetters["common/turnEnded"]) {
      // free
      let free = productions[id - 5].workersList.filter((worker) => worker.teamtype === AssignmentAction.TeamType.NONE);
      ctx.commit('setFree', free);
      // builders
      let builders = productions[id - 5].workersList.filter((worker) => worker.teamtype === AssignmentAction.TeamType.BUILDERS);
      ctx.commit('setBuilders', builders);
      // operators
      for (let i = 0; i < ctx.getters.readyUnits; i++) {
        let workers = productions[id - 5].workersList.filter((worker) => worker.teamtype === AssignmentAction.TeamType.OPERATORS && worker.idx === i);
        ctx.commit('setOperators', { idx: i, v: workers });
      }
      // experts
      for (let i = 0; i < ctx.getters.readyUnits; i++) {
        let workers = productions[id - 5].workersList.filter((worker) => worker.teamtype === AssignmentAction.TeamType.EXPERTS && worker.idx === i);
        ctx.commit('setExperts', { idx: i, v: workers });
      }
    }
  },
  reset(ctx) {
    ctx.commit('reset');
  },
};

const mutations = {
  setProductions(state, productions) {
    state.productions = productions;
  },
  setYellow(state, { idx, v }) {
    state.requests[idx].yellow = v;
  },
  setBlue(state, { idx, v }) {
    state.requests[idx].blue = v;
  },
  setBottles(state, { idx, v }) {
    state.requests[idx].bottles = v;
  },
  setOperators(state, { idx, v }) {
    Vue.set(state.operators, idx, v);
  },
  setExperts(state, { idx, v }) {
    Vue.set(state.experts, idx, v);
  },
  setBuilders(state, v) {
    state.builders = v;
  },
  setFree(state, v) {
    state.freeWorkers = v;
  },
  setDestroyBadDrink(state, v) {
    state.destroyBadDrink = v;
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  readyUnits: (state, getters, rootState, rootGetters) => {
    if (state.productions.length > 0) {
      return state.productions[rootGetters['common/roleInt'] - 5].readyunits;
    }
    return 0;
  },
  availableUnits: (state, getters, rootState, rootGetters) => {
    if (state.productions.length > 0) {
      return state.productions[rootGetters['common/roleInt'] - 5].availableunits;
    }
    return 0;
  },
  request: (state) => (idx) => {
    return state.requests[idx]; 
  },
  operators: (state) => (idx) => {
    return state.operators[idx];
  },
  experts: (state) => (idx) => {
    return state.experts[idx];
  },
  builders: (state) => {
    return state.builders;
  },
  freeWorkers: (state) => {
    return state.freeWorkers;
  },
  teamSize: (state) => (id) => {
    if (state.productions.length > 0) {
      return state.productions[id - 5].workersList.length;
    }
    return 0;
  },
  destroyBadDrink: (state) => {
    return state.destroyBadDrink;
  },
  toPlayerActions: (state, getters, rootState, rootGetters) => {
    let actions = [];

    // request
    for (let i = 0; i < getters.readyUnits; i++) {
      let action = new Action();
      let pA = new ProductionAction();
      pA.setYellow(getters.request(i).yellow);
      pA.setBlue(getters.request(i).blue);
      pA.setBottles(getters.request(i).bottles);
      pA.setUnitidx(i);
      pA.setDestroybaddrink(getters.destroyBadDrink);
      pA.setSource(rootGetters['common/roleInt']);
      action.setProduction(pA);
      actions.push(action);
    }
    
    // production units
    for (let i = 0; i < getters.readyUnits; i++) {
      // operators
      for (const operator of getters.operators(i)) {
        let action = new Action();
        let aA = new AssignmentAction();
        aA.setPersonid(operator.id);
        aA.setSource(rootGetters['common/roleInt']);
        aA.setTeamtype(AssignmentAction.TeamType.OPERATORS);
        aA.setTeamid(i);
        action.setAssignment(aA);
        actions.push(action);
      }
      // experts
      for (const expert of getters.experts(i)) {
        let action = new Action();
        let aA = new AssignmentAction();
        aA.setPersonid(expert.id);
        aA.setSource(rootGetters['common/roleInt']);
        aA.setTeamtype(AssignmentAction.TeamType.EXPERTS);
        aA.setTeamid(i);
        action.setAssignment(aA);
        actions.push(action);
      }
    }

    // free
    for (const worker of getters.freeWorkers) {
      let action = new Action();
      let aA = new AssignmentAction();
      aA.setPersonid(worker.id);
      aA.setSource(rootGetters['common/roleInt']);
      aA.setTeamtype(AssignmentAction.TeamType.NONE);
      aA.setTeamid(0);
      action.setAssignment(aA);
      actions.push(action);
    }
    
    // builders
    for (const worker of getters.builders) {
      let action = new Action();
      let aA = new AssignmentAction();
      aA.setPersonid(worker.id);
      aA.setSource(rootGetters['common/roleInt']);
      aA.setTeamtype(AssignmentAction.TeamType.BUILDERS);
      aA.setTeamid(getters.readyUnits);
      action.setAssignment(aA);
      actions.push(action);
    }
    
    return actions;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
