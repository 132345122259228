<template lang="pug">
  div
    div
      v-avatar(v-if="teamType != 'free'" @click="minus" color="#696969" size="36")
        v-icon(dark) mdi-account-multiple-minus
      v-avatar(v-if="teamType != 'free'" @click="plus" color="#696969" size="36")
        v-icon(dark) mdi-account-multiple-plus
      span(v-for="person in team" :key="person.id")
        Person(:person="person")
</template>
<script>
import { mapGetters } from "vuex";

import Person from "@/components/Person";

export default {
  name: "Team",

  props: {
    teamType: String,
    idx: Number,
  },

  components: {
    Person,
  },

  methods: {
    plus: function() {
      if (this.free.length > 0) {
        let person = this.free.pop();
        let t = this.team;
        t.push(person);
        this.setTeam(t);
      }
    },

    minus: function() {
      if (this.team.length > 0) {
        let person = this.team.pop();
        let free = this.free;
        free.push(person);
        this.setFree(free);
      }
    },

    setTeam: function(v) {
      switch (this.teamType) {
        case "harvesters":
          this.$store.commit("plantation/setHarvesters", {
            idx: this.idx,
            v,
          });
          break;
        case "gardeners":
          this.$store.commit("plantation/setGardeners", { idx: this.idx, v });
          break;
        case "cleaners":
          this.$store.commit("plantation/setCleaners", { idx: this.idx, v });
          break;
        case "builders":
          switch (this.roleInt) {
            case 2:
            case 3:
            case 4:
              this.$store.commit("plantation/setBuilders", v);
              break;
            case 5:
            case 6:
              this.$store.commit("production/setBuilders", v);
              break;
            case 7:
              this.$store.commit("warehouse/setBuilders", v);
              break;
            case 8:
              this.$store.commit("trade/setBuilders", v);
              break;
            default:
              console.log("source unknown for builders team type");
              return undefined;
          }
          break;
        case "spaceportBuilders":
          this.$store.commit("trade/setSpaceportBuilders", v);
          break;
        case "operators":
          this.$store.commit("production/setOperators", { idx: this.idx, v });
          break;
        case "experts":
          this.$store.commit("production/setExperts", { idx: this.idx, v });
          break;
        case "traders":
          this.$store.commit("trade/setTraders", { idx: this.idx, v });
          break;
        case "warehousemen":
          this.$store.commit("warehouse/setWarehousemen", v);
          break;
        case "free":
          console.log("setting free - should never happen");
          break;
        default:
          console.log("team type does not exist (setter)");
      }
    },
    setFree: function(v) {
      switch (this.roleInt) {
        case 2:
        case 3:
        case 4:
          this.$store.commit("plantation/setFree", v);
          break;
        case 5:
        case 6:
          this.$store.commit("production/setFree", v);
          break;
        case 7:
          this.$store.commit("warehouse/setFree", v);
          break;
        case 8:
          this.$store.commit("trade/setFree", v);
          break;
        default:
          console.log("free team type does not exist");
          return undefined;
      }
    },
  },

  computed: {
    free() {
      switch (this.roleInt) {
        case 2:
        case 3:
        case 4:
          return this["plantation/freeWorkers"];
        case 5:
        case 6:
          return this["production/freeWorkers"];
        case 7:
          return this["warehouse/freeWorkers"];
        case 8:
          return this["trade/freeWorkers"];
        default:
          console.log("free team type does not exist");
          return undefined;
      }
    },
    team: {
      get() {
        switch (this.teamType) {
          case "harvesters":
            return this.harvesters(this.idx);
          case "gardeners":
            return this.gardeners(this.idx);
          case "cleaners":
            return this.cleaners(this.idx);
          case "builders":
            switch (this.roleInt) {
              case 2:
              case 3:
              case 4:
                return this["plantation/builders"];
              case 5:
              case 6:
                return this["production/builders"];
              case 7:
                return this["warehouse/builders"];
              case 8:
                return this["trade/builders"];
              default:
                console.log("source unknown for builders team type");
                return undefined;
            }
          case "spaceportBuilders":
            return this.spaceportBuilders;
          case "operators":
            return this.operators(this.idx);
          case "experts":
            return this.experts(this.idx);
          case "traders":
            return this.traders(this.idx);
          case "warehousemen":
            return this.warehousemen;
          case "free":
            return this.free;
          default:
            console.log("team type does not exist (getter)");
            return undefined;
        }
      },
    },
    ...mapGetters([
      "plantation/freeWorkers",
      "plantation/builders",
      "production/freeWorkers",
      "production/builders",
      "trade/builders",
      "trade/freeWorkers",
      "warehouse/builders",
      "warehouse/freeWorkers",
    ]),
    ...mapGetters("plantation", ["harvesters", "gardeners", "cleaners"]),
    ...mapGetters("production", ["operators", "experts"]),
    ...mapGetters("trade", ["traders", "spaceportBuilders"]),
    ...mapGetters("common", ["roleInt"]),
    ...mapGetters("warehouse", ["warehousemen"]),
  },
};
</script>
