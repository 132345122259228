// source: bulwa.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var proto_auth_pb = require('./proto/auth_pb.js');
goog.object.extend(proto, proto_auth_pb);
var proto_healthcheck_pb = require('./proto/healthcheck_pb.js');
goog.object.extend(proto, proto_healthcheck_pb);
goog.exportSymbol('proto.bulwa.Action', null, global);
goog.exportSymbol('proto.bulwa.Action.ActionCase', null, global);
goog.exportSymbol('proto.bulwa.AssignmentAction', null, global);
goog.exportSymbol('proto.bulwa.AssignmentAction.TeamType', null, global);
goog.exportSymbol('proto.bulwa.BuildEvent', null, global);
goog.exportSymbol('proto.bulwa.Circle', null, global);
goog.exportSymbol('proto.bulwa.CreateGameResponse', null, global);
goog.exportSymbol('proto.bulwa.DecayEvent', null, global);
goog.exportSymbol('proto.bulwa.Event', null, global);
goog.exportSymbol('proto.bulwa.Event.EventCase', null, global);
goog.exportSymbol('proto.bulwa.GameCommand', null, global);
goog.exportSymbol('proto.bulwa.GameEvent', null, global);
goog.exportSymbol('proto.bulwa.GameParams', null, global);
goog.exportSymbol('proto.bulwa.GameSubscribeRequest', null, global);
goog.exportSymbol('proto.bulwa.GrowthEvent', null, global);
goog.exportSymbol('proto.bulwa.HR', null, global);
goog.exportSymbol('proto.bulwa.HarvestAction', null, global);
goog.exportSymbol('proto.bulwa.HarvestEvent', null, global);
goog.exportSymbol('proto.bulwa.LogisticsEvent', null, global);
goog.exportSymbol('proto.bulwa.LogisticsEvent.Kind', null, global);
goog.exportSymbol('proto.bulwa.OptimalizationEvent', null, global);
goog.exportSymbol('proto.bulwa.PaymentEvent', null, global);
goog.exportSymbol('proto.bulwa.Person', null, global);
goog.exportSymbol('proto.bulwa.Plantation', null, global);
goog.exportSymbol('proto.bulwa.PlantationInfoEvent', null, global);
goog.exportSymbol('proto.bulwa.Player', null, global);
goog.exportSymbol('proto.bulwa.Player.Role', null, global);
goog.exportSymbol('proto.bulwa.PlayerActions', null, global);
goog.exportSymbol('proto.bulwa.Production', null, global);
goog.exportSymbol('proto.bulwa.ProductionAction', null, global);
goog.exportSymbol('proto.bulwa.ProductionEvent', null, global);
goog.exportSymbol('proto.bulwa.ProductionEvent.Quality', null, global);
goog.exportSymbol('proto.bulwa.RecruitmentAction', null, global);
goog.exportSymbol('proto.bulwa.RecruitmentEvent', null, global);
goog.exportSymbol('proto.bulwa.SalaryAction', null, global);
goog.exportSymbol('proto.bulwa.SelectRoleRequest', null, global);
goog.exportSymbol('proto.bulwa.SpecialAction', null, global);
goog.exportSymbol('proto.bulwa.SpecialAction.Kind', null, global);
goog.exportSymbol('proto.bulwa.TekkoEvent', null, global);
goog.exportSymbol('proto.bulwa.TekkoEvent.Kind', null, global);
goog.exportSymbol('proto.bulwa.Trade', null, global);
goog.exportSymbol('proto.bulwa.TradeAction', null, global);
goog.exportSymbol('proto.bulwa.TradeEvent', null, global);
goog.exportSymbol('proto.bulwa.TradeEvent.Kind', null, global);
goog.exportSymbol('proto.bulwa.Warehouse', null, global);
goog.exportSymbol('proto.bulwa.WarehouseAction', null, global);
goog.exportSymbol('proto.bulwa.Weather', null, global);
goog.exportSymbol('proto.bulwa.WorkingTimeAction', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.bulwa.Event.oneofGroups_);
};
goog.inherits(proto.bulwa.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.Event.displayName = 'proto.bulwa.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.DecayEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.DecayEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.DecayEvent.displayName = 'proto.bulwa.DecayEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.OptimalizationEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.OptimalizationEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.OptimalizationEvent.displayName = 'proto.bulwa.OptimalizationEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.PlantationInfoEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.PlantationInfoEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.PlantationInfoEvent.displayName = 'proto.bulwa.PlantationInfoEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.ProductionEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.ProductionEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.ProductionEvent.displayName = 'proto.bulwa.ProductionEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.LogisticsEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.LogisticsEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.LogisticsEvent.displayName = 'proto.bulwa.LogisticsEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.TradeEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.TradeEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.TradeEvent.displayName = 'proto.bulwa.TradeEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.PaymentEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.PaymentEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.PaymentEvent.displayName = 'proto.bulwa.PaymentEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.BuildEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.BuildEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.BuildEvent.displayName = 'proto.bulwa.BuildEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.TekkoEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.TekkoEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.TekkoEvent.displayName = 'proto.bulwa.TekkoEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.RecruitmentEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.RecruitmentEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.RecruitmentEvent.displayName = 'proto.bulwa.RecruitmentEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.HarvestEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.HarvestEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.HarvestEvent.displayName = 'proto.bulwa.HarvestEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.GrowthEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.GrowthEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.GrowthEvent.displayName = 'proto.bulwa.GrowthEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.PlayerActions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.bulwa.PlayerActions.repeatedFields_, null);
};
goog.inherits(proto.bulwa.PlayerActions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.PlayerActions.displayName = 'proto.bulwa.PlayerActions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.bulwa.Action.oneofGroups_);
};
goog.inherits(proto.bulwa.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.Action.displayName = 'proto.bulwa.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.WorkingTimeAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.WorkingTimeAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.WorkingTimeAction.displayName = 'proto.bulwa.WorkingTimeAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.SpecialAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.SpecialAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.SpecialAction.displayName = 'proto.bulwa.SpecialAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.TradeAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.TradeAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.TradeAction.displayName = 'proto.bulwa.TradeAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.WarehouseAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.bulwa.WarehouseAction.repeatedFields_, null);
};
goog.inherits(proto.bulwa.WarehouseAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.WarehouseAction.displayName = 'proto.bulwa.WarehouseAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.ProductionAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.ProductionAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.ProductionAction.displayName = 'proto.bulwa.ProductionAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.SalaryAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.SalaryAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.SalaryAction.displayName = 'proto.bulwa.SalaryAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.AssignmentAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.AssignmentAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.AssignmentAction.displayName = 'proto.bulwa.AssignmentAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.RecruitmentAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.RecruitmentAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.RecruitmentAction.displayName = 'proto.bulwa.RecruitmentAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.HarvestAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.HarvestAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.HarvestAction.displayName = 'proto.bulwa.HarvestAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.SelectRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.SelectRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.SelectRoleRequest.displayName = 'proto.bulwa.SelectRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.GameCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.GameCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.GameCommand.displayName = 'proto.bulwa.GameCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.GameEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.bulwa.GameEvent.repeatedFields_, null);
};
goog.inherits(proto.bulwa.GameEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.GameEvent.displayName = 'proto.bulwa.GameEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.Weather = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.Weather, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.Weather.displayName = 'proto.bulwa.Weather';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.Person = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.Person, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.Person.displayName = 'proto.bulwa.Person';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.HR = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.HR, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.HR.displayName = 'proto.bulwa.HR';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.Trade = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.bulwa.Trade.repeatedFields_, null);
};
goog.inherits(proto.bulwa.Trade, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.Trade.displayName = 'proto.bulwa.Trade';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.Warehouse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.bulwa.Warehouse.repeatedFields_, null);
};
goog.inherits(proto.bulwa.Warehouse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.Warehouse.displayName = 'proto.bulwa.Warehouse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.Plantation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.bulwa.Plantation.repeatedFields_, null);
};
goog.inherits(proto.bulwa.Plantation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.Plantation.displayName = 'proto.bulwa.Plantation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.Production = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.bulwa.Production.repeatedFields_, null);
};
goog.inherits(proto.bulwa.Production, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.Production.displayName = 'proto.bulwa.Production';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.Circle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.Circle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.Circle.displayName = 'proto.bulwa.Circle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.GameParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.GameParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.GameParams.displayName = 'proto.bulwa.GameParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.Player = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.Player, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.Player.displayName = 'proto.bulwa.Player';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.CreateGameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.CreateGameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.CreateGameResponse.displayName = 'proto.bulwa.CreateGameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bulwa.GameSubscribeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bulwa.GameSubscribeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bulwa.GameSubscribeRequest.displayName = 'proto.bulwa.GameSubscribeRequest';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.bulwa.Event.oneofGroups_ = [[10,11,12,13,14,15,16,17,18,19,20,21]];

/**
 * @enum {number}
 */
proto.bulwa.Event.EventCase = {
  EVENT_NOT_SET: 0,
  HARVEST: 10,
  GROWTH: 11,
  PRODUCTION: 12,
  LOGISTICS: 13,
  TRADE: 14,
  RECRUITMENT: 15,
  PAYMENT: 16,
  BUILD: 17,
  TEKKO: 18,
  PLANTATIONINFO: 19,
  OPTIMALIZATION: 20,
  DECAY: 21
};

/**
 * @return {proto.bulwa.Event.EventCase}
 */
proto.bulwa.Event.prototype.getEventCase = function() {
  return /** @type {proto.bulwa.Event.EventCase} */(jspb.Message.computeOneofCase(this, proto.bulwa.Event.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, 0),
    harvest: (f = msg.getHarvest()) && proto.bulwa.HarvestEvent.toObject(includeInstance, f),
    growth: (f = msg.getGrowth()) && proto.bulwa.GrowthEvent.toObject(includeInstance, f),
    production: (f = msg.getProduction()) && proto.bulwa.ProductionEvent.toObject(includeInstance, f),
    logistics: (f = msg.getLogistics()) && proto.bulwa.LogisticsEvent.toObject(includeInstance, f),
    trade: (f = msg.getTrade()) && proto.bulwa.TradeEvent.toObject(includeInstance, f),
    recruitment: (f = msg.getRecruitment()) && proto.bulwa.RecruitmentEvent.toObject(includeInstance, f),
    payment: (f = msg.getPayment()) && proto.bulwa.PaymentEvent.toObject(includeInstance, f),
    build: (f = msg.getBuild()) && proto.bulwa.BuildEvent.toObject(includeInstance, f),
    tekko: (f = msg.getTekko()) && proto.bulwa.TekkoEvent.toObject(includeInstance, f),
    plantationinfo: (f = msg.getPlantationinfo()) && proto.bulwa.PlantationInfoEvent.toObject(includeInstance, f),
    optimalization: (f = msg.getOptimalization()) && proto.bulwa.OptimalizationEvent.toObject(includeInstance, f),
    decay: (f = msg.getDecay()) && proto.bulwa.DecayEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.Event}
 */
proto.bulwa.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.Event;
  return proto.bulwa.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.Event}
 */
proto.bulwa.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 10:
      var value = new proto.bulwa.HarvestEvent;
      reader.readMessage(value,proto.bulwa.HarvestEvent.deserializeBinaryFromReader);
      msg.setHarvest(value);
      break;
    case 11:
      var value = new proto.bulwa.GrowthEvent;
      reader.readMessage(value,proto.bulwa.GrowthEvent.deserializeBinaryFromReader);
      msg.setGrowth(value);
      break;
    case 12:
      var value = new proto.bulwa.ProductionEvent;
      reader.readMessage(value,proto.bulwa.ProductionEvent.deserializeBinaryFromReader);
      msg.setProduction(value);
      break;
    case 13:
      var value = new proto.bulwa.LogisticsEvent;
      reader.readMessage(value,proto.bulwa.LogisticsEvent.deserializeBinaryFromReader);
      msg.setLogistics(value);
      break;
    case 14:
      var value = new proto.bulwa.TradeEvent;
      reader.readMessage(value,proto.bulwa.TradeEvent.deserializeBinaryFromReader);
      msg.setTrade(value);
      break;
    case 15:
      var value = new proto.bulwa.RecruitmentEvent;
      reader.readMessage(value,proto.bulwa.RecruitmentEvent.deserializeBinaryFromReader);
      msg.setRecruitment(value);
      break;
    case 16:
      var value = new proto.bulwa.PaymentEvent;
      reader.readMessage(value,proto.bulwa.PaymentEvent.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    case 17:
      var value = new proto.bulwa.BuildEvent;
      reader.readMessage(value,proto.bulwa.BuildEvent.deserializeBinaryFromReader);
      msg.setBuild(value);
      break;
    case 18:
      var value = new proto.bulwa.TekkoEvent;
      reader.readMessage(value,proto.bulwa.TekkoEvent.deserializeBinaryFromReader);
      msg.setTekko(value);
      break;
    case 19:
      var value = new proto.bulwa.PlantationInfoEvent;
      reader.readMessage(value,proto.bulwa.PlantationInfoEvent.deserializeBinaryFromReader);
      msg.setPlantationinfo(value);
      break;
    case 20:
      var value = new proto.bulwa.OptimalizationEvent;
      reader.readMessage(value,proto.bulwa.OptimalizationEvent.deserializeBinaryFromReader);
      msg.setOptimalization(value);
      break;
    case 21:
      var value = new proto.bulwa.DecayEvent;
      reader.readMessage(value,proto.bulwa.DecayEvent.deserializeBinaryFromReader);
      msg.setDecay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getHarvest();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.bulwa.HarvestEvent.serializeBinaryToWriter
    );
  }
  f = message.getGrowth();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.bulwa.GrowthEvent.serializeBinaryToWriter
    );
  }
  f = message.getProduction();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.bulwa.ProductionEvent.serializeBinaryToWriter
    );
  }
  f = message.getLogistics();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.bulwa.LogisticsEvent.serializeBinaryToWriter
    );
  }
  f = message.getTrade();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.bulwa.TradeEvent.serializeBinaryToWriter
    );
  }
  f = message.getRecruitment();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.bulwa.RecruitmentEvent.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.bulwa.PaymentEvent.serializeBinaryToWriter
    );
  }
  f = message.getBuild();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.bulwa.BuildEvent.serializeBinaryToWriter
    );
  }
  f = message.getTekko();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.bulwa.TekkoEvent.serializeBinaryToWriter
    );
  }
  f = message.getPlantationinfo();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.bulwa.PlantationInfoEvent.serializeBinaryToWriter
    );
  }
  f = message.getOptimalization();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.bulwa.OptimalizationEvent.serializeBinaryToWriter
    );
  }
  f = message.getDecay();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.bulwa.DecayEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional Player.Role source = 1;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.Event.prototype.getSource = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.Event} returns this
 */
proto.bulwa.Event.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional HarvestEvent harvest = 10;
 * @return {?proto.bulwa.HarvestEvent}
 */
proto.bulwa.Event.prototype.getHarvest = function() {
  return /** @type{?proto.bulwa.HarvestEvent} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.HarvestEvent, 10));
};


/**
 * @param {?proto.bulwa.HarvestEvent|undefined} value
 * @return {!proto.bulwa.Event} returns this
*/
proto.bulwa.Event.prototype.setHarvest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.bulwa.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Event} returns this
 */
proto.bulwa.Event.prototype.clearHarvest = function() {
  return this.setHarvest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Event.prototype.hasHarvest = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GrowthEvent growth = 11;
 * @return {?proto.bulwa.GrowthEvent}
 */
proto.bulwa.Event.prototype.getGrowth = function() {
  return /** @type{?proto.bulwa.GrowthEvent} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.GrowthEvent, 11));
};


/**
 * @param {?proto.bulwa.GrowthEvent|undefined} value
 * @return {!proto.bulwa.Event} returns this
*/
proto.bulwa.Event.prototype.setGrowth = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.bulwa.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Event} returns this
 */
proto.bulwa.Event.prototype.clearGrowth = function() {
  return this.setGrowth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Event.prototype.hasGrowth = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ProductionEvent production = 12;
 * @return {?proto.bulwa.ProductionEvent}
 */
proto.bulwa.Event.prototype.getProduction = function() {
  return /** @type{?proto.bulwa.ProductionEvent} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.ProductionEvent, 12));
};


/**
 * @param {?proto.bulwa.ProductionEvent|undefined} value
 * @return {!proto.bulwa.Event} returns this
*/
proto.bulwa.Event.prototype.setProduction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.bulwa.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Event} returns this
 */
proto.bulwa.Event.prototype.clearProduction = function() {
  return this.setProduction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Event.prototype.hasProduction = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional LogisticsEvent logistics = 13;
 * @return {?proto.bulwa.LogisticsEvent}
 */
proto.bulwa.Event.prototype.getLogistics = function() {
  return /** @type{?proto.bulwa.LogisticsEvent} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.LogisticsEvent, 13));
};


/**
 * @param {?proto.bulwa.LogisticsEvent|undefined} value
 * @return {!proto.bulwa.Event} returns this
*/
proto.bulwa.Event.prototype.setLogistics = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.bulwa.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Event} returns this
 */
proto.bulwa.Event.prototype.clearLogistics = function() {
  return this.setLogistics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Event.prototype.hasLogistics = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional TradeEvent trade = 14;
 * @return {?proto.bulwa.TradeEvent}
 */
proto.bulwa.Event.prototype.getTrade = function() {
  return /** @type{?proto.bulwa.TradeEvent} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.TradeEvent, 14));
};


/**
 * @param {?proto.bulwa.TradeEvent|undefined} value
 * @return {!proto.bulwa.Event} returns this
*/
proto.bulwa.Event.prototype.setTrade = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.bulwa.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Event} returns this
 */
proto.bulwa.Event.prototype.clearTrade = function() {
  return this.setTrade(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Event.prototype.hasTrade = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional RecruitmentEvent recruitment = 15;
 * @return {?proto.bulwa.RecruitmentEvent}
 */
proto.bulwa.Event.prototype.getRecruitment = function() {
  return /** @type{?proto.bulwa.RecruitmentEvent} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.RecruitmentEvent, 15));
};


/**
 * @param {?proto.bulwa.RecruitmentEvent|undefined} value
 * @return {!proto.bulwa.Event} returns this
*/
proto.bulwa.Event.prototype.setRecruitment = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.bulwa.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Event} returns this
 */
proto.bulwa.Event.prototype.clearRecruitment = function() {
  return this.setRecruitment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Event.prototype.hasRecruitment = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional PaymentEvent payment = 16;
 * @return {?proto.bulwa.PaymentEvent}
 */
proto.bulwa.Event.prototype.getPayment = function() {
  return /** @type{?proto.bulwa.PaymentEvent} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.PaymentEvent, 16));
};


/**
 * @param {?proto.bulwa.PaymentEvent|undefined} value
 * @return {!proto.bulwa.Event} returns this
*/
proto.bulwa.Event.prototype.setPayment = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.bulwa.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Event} returns this
 */
proto.bulwa.Event.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Event.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional BuildEvent build = 17;
 * @return {?proto.bulwa.BuildEvent}
 */
proto.bulwa.Event.prototype.getBuild = function() {
  return /** @type{?proto.bulwa.BuildEvent} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.BuildEvent, 17));
};


/**
 * @param {?proto.bulwa.BuildEvent|undefined} value
 * @return {!proto.bulwa.Event} returns this
*/
proto.bulwa.Event.prototype.setBuild = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.bulwa.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Event} returns this
 */
proto.bulwa.Event.prototype.clearBuild = function() {
  return this.setBuild(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Event.prototype.hasBuild = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional TekkoEvent tekko = 18;
 * @return {?proto.bulwa.TekkoEvent}
 */
proto.bulwa.Event.prototype.getTekko = function() {
  return /** @type{?proto.bulwa.TekkoEvent} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.TekkoEvent, 18));
};


/**
 * @param {?proto.bulwa.TekkoEvent|undefined} value
 * @return {!proto.bulwa.Event} returns this
*/
proto.bulwa.Event.prototype.setTekko = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.bulwa.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Event} returns this
 */
proto.bulwa.Event.prototype.clearTekko = function() {
  return this.setTekko(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Event.prototype.hasTekko = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional PlantationInfoEvent plantationInfo = 19;
 * @return {?proto.bulwa.PlantationInfoEvent}
 */
proto.bulwa.Event.prototype.getPlantationinfo = function() {
  return /** @type{?proto.bulwa.PlantationInfoEvent} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.PlantationInfoEvent, 19));
};


/**
 * @param {?proto.bulwa.PlantationInfoEvent|undefined} value
 * @return {!proto.bulwa.Event} returns this
*/
proto.bulwa.Event.prototype.setPlantationinfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.bulwa.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Event} returns this
 */
proto.bulwa.Event.prototype.clearPlantationinfo = function() {
  return this.setPlantationinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Event.prototype.hasPlantationinfo = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional OptimalizationEvent optimalization = 20;
 * @return {?proto.bulwa.OptimalizationEvent}
 */
proto.bulwa.Event.prototype.getOptimalization = function() {
  return /** @type{?proto.bulwa.OptimalizationEvent} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.OptimalizationEvent, 20));
};


/**
 * @param {?proto.bulwa.OptimalizationEvent|undefined} value
 * @return {!proto.bulwa.Event} returns this
*/
proto.bulwa.Event.prototype.setOptimalization = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.bulwa.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Event} returns this
 */
proto.bulwa.Event.prototype.clearOptimalization = function() {
  return this.setOptimalization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Event.prototype.hasOptimalization = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional DecayEvent decay = 21;
 * @return {?proto.bulwa.DecayEvent}
 */
proto.bulwa.Event.prototype.getDecay = function() {
  return /** @type{?proto.bulwa.DecayEvent} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.DecayEvent, 21));
};


/**
 * @param {?proto.bulwa.DecayEvent|undefined} value
 * @return {!proto.bulwa.Event} returns this
*/
proto.bulwa.Event.prototype.setDecay = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.bulwa.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Event} returns this
 */
proto.bulwa.Event.prototype.clearDecay = function() {
  return this.setDecay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Event.prototype.hasDecay = function() {
  return jspb.Message.getField(this, 21) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.DecayEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.DecayEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.DecayEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.DecayEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    yellow: jspb.Message.getFieldWithDefault(msg, 1, 0),
    blue: jspb.Message.getFieldWithDefault(msg, 2, 0),
    purple: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.DecayEvent}
 */
proto.bulwa.DecayEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.DecayEvent;
  return proto.bulwa.DecayEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.DecayEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.DecayEvent}
 */
proto.bulwa.DecayEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYellow(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBlue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPurple(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.DecayEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.DecayEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.DecayEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.DecayEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYellow();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBlue();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPurple();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 yellow = 1;
 * @return {number}
 */
proto.bulwa.DecayEvent.prototype.getYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.DecayEvent} returns this
 */
proto.bulwa.DecayEvent.prototype.setYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 blue = 2;
 * @return {number}
 */
proto.bulwa.DecayEvent.prototype.getBlue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.DecayEvent} returns this
 */
proto.bulwa.DecayEvent.prototype.setBlue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 purple = 3;
 * @return {number}
 */
proto.bulwa.DecayEvent.prototype.getPurple = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.DecayEvent} returns this
 */
proto.bulwa.DecayEvent.prototype.setPurple = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.OptimalizationEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.OptimalizationEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.OptimalizationEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.OptimalizationEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    idx: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.OptimalizationEvent}
 */
proto.bulwa.OptimalizationEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.OptimalizationEvent;
  return proto.bulwa.OptimalizationEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.OptimalizationEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.OptimalizationEvent}
 */
proto.bulwa.OptimalizationEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.OptimalizationEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.OptimalizationEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.OptimalizationEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.OptimalizationEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdx();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 idx = 1;
 * @return {number}
 */
proto.bulwa.OptimalizationEvent.prototype.getIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.OptimalizationEvent} returns this
 */
proto.bulwa.OptimalizationEvent.prototype.setIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.PlantationInfoEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.PlantationInfoEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.PlantationInfoEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.PlantationInfoEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    shoots: jspb.Message.getFieldWithDefault(msg, 1, 0),
    yellow: jspb.Message.getFieldWithDefault(msg, 2, 0),
    blue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    purple: jspb.Message.getFieldWithDefault(msg, 4, 0),
    idx: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.PlantationInfoEvent}
 */
proto.bulwa.PlantationInfoEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.PlantationInfoEvent;
  return proto.bulwa.PlantationInfoEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.PlantationInfoEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.PlantationInfoEvent}
 */
proto.bulwa.PlantationInfoEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShoots(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYellow(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBlue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPurple(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.PlantationInfoEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.PlantationInfoEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.PlantationInfoEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.PlantationInfoEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShoots();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getYellow();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBlue();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPurple();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getIdx();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional uint32 shoots = 1;
 * @return {number}
 */
proto.bulwa.PlantationInfoEvent.prototype.getShoots = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.PlantationInfoEvent} returns this
 */
proto.bulwa.PlantationInfoEvent.prototype.setShoots = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 yellow = 2;
 * @return {number}
 */
proto.bulwa.PlantationInfoEvent.prototype.getYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.PlantationInfoEvent} returns this
 */
proto.bulwa.PlantationInfoEvent.prototype.setYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 blue = 3;
 * @return {number}
 */
proto.bulwa.PlantationInfoEvent.prototype.getBlue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.PlantationInfoEvent} returns this
 */
proto.bulwa.PlantationInfoEvent.prototype.setBlue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 purple = 4;
 * @return {number}
 */
proto.bulwa.PlantationInfoEvent.prototype.getPurple = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.PlantationInfoEvent} returns this
 */
proto.bulwa.PlantationInfoEvent.prototype.setPurple = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 idx = 5;
 * @return {number}
 */
proto.bulwa.PlantationInfoEvent.prototype.getIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.PlantationInfoEvent} returns this
 */
proto.bulwa.PlantationInfoEvent.prototype.setIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.ProductionEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.ProductionEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.ProductionEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.ProductionEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    quality: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    idx: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.ProductionEvent}
 */
proto.bulwa.ProductionEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.ProductionEvent;
  return proto.bulwa.ProductionEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.ProductionEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.ProductionEvent}
 */
proto.bulwa.ProductionEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.bulwa.ProductionEvent.Quality} */ (reader.readEnum());
      msg.setQuality(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.ProductionEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.ProductionEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.ProductionEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.ProductionEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuality();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getIdx();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.bulwa.ProductionEvent.Quality = {
  GOOD: 0,
  BAD: 1
};

/**
 * optional Quality quality = 1;
 * @return {!proto.bulwa.ProductionEvent.Quality}
 */
proto.bulwa.ProductionEvent.prototype.getQuality = function() {
  return /** @type {!proto.bulwa.ProductionEvent.Quality} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.bulwa.ProductionEvent.Quality} value
 * @return {!proto.bulwa.ProductionEvent} returns this
 */
proto.bulwa.ProductionEvent.prototype.setQuality = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 quantity = 2;
 * @return {number}
 */
proto.bulwa.ProductionEvent.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.ProductionEvent} returns this
 */
proto.bulwa.ProductionEvent.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 idx = 3;
 * @return {number}
 */
proto.bulwa.ProductionEvent.prototype.getIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.ProductionEvent} returns this
 */
proto.bulwa.ProductionEvent.prototype.setIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.LogisticsEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.LogisticsEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.LogisticsEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.LogisticsEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    target: jspb.Message.getFieldWithDefault(msg, 2, 0),
    yellow: jspb.Message.getFieldWithDefault(msg, 3, 0),
    blue: jspb.Message.getFieldWithDefault(msg, 4, 0),
    purple: jspb.Message.getFieldWithDefault(msg, 5, 0),
    baddrink: jspb.Message.getFieldWithDefault(msg, 6, 0),
    gooddrink: jspb.Message.getFieldWithDefault(msg, 8, 0),
    source: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.LogisticsEvent}
 */
proto.bulwa.LogisticsEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.LogisticsEvent;
  return proto.bulwa.LogisticsEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.LogisticsEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.LogisticsEvent}
 */
proto.bulwa.LogisticsEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.bulwa.LogisticsEvent.Kind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 2:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setTarget(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYellow(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBlue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPurple(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBaddrink(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGooddrink(value);
      break;
    case 7:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.LogisticsEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.LogisticsEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.LogisticsEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.LogisticsEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getYellow();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getBlue();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getPurple();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getBaddrink();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getGooddrink();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.bulwa.LogisticsEvent.Kind = {
  STOCK_UP: 0,
  INSUFFICIENT_SPACE: 1,
  TRANSPORT_LOSS: 2,
  THEFT_LOSS: 3,
  DISTRIBUTION: 4
};

/**
 * optional Kind kind = 1;
 * @return {!proto.bulwa.LogisticsEvent.Kind}
 */
proto.bulwa.LogisticsEvent.prototype.getKind = function() {
  return /** @type {!proto.bulwa.LogisticsEvent.Kind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.bulwa.LogisticsEvent.Kind} value
 * @return {!proto.bulwa.LogisticsEvent} returns this
 */
proto.bulwa.LogisticsEvent.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Player.Role target = 2;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.LogisticsEvent.prototype.getTarget = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.LogisticsEvent} returns this
 */
proto.bulwa.LogisticsEvent.prototype.setTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 yellow = 3;
 * @return {number}
 */
proto.bulwa.LogisticsEvent.prototype.getYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.LogisticsEvent} returns this
 */
proto.bulwa.LogisticsEvent.prototype.setYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 blue = 4;
 * @return {number}
 */
proto.bulwa.LogisticsEvent.prototype.getBlue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.LogisticsEvent} returns this
 */
proto.bulwa.LogisticsEvent.prototype.setBlue = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 purple = 5;
 * @return {number}
 */
proto.bulwa.LogisticsEvent.prototype.getPurple = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.LogisticsEvent} returns this
 */
proto.bulwa.LogisticsEvent.prototype.setPurple = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 badDrink = 6;
 * @return {number}
 */
proto.bulwa.LogisticsEvent.prototype.getBaddrink = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.LogisticsEvent} returns this
 */
proto.bulwa.LogisticsEvent.prototype.setBaddrink = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 goodDrink = 8;
 * @return {number}
 */
proto.bulwa.LogisticsEvent.prototype.getGooddrink = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.LogisticsEvent} returns this
 */
proto.bulwa.LogisticsEvent.prototype.setGooddrink = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional Player.Role source = 7;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.LogisticsEvent.prototype.getSource = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.LogisticsEvent} returns this
 */
proto.bulwa.LogisticsEvent.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.TradeEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.TradeEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.TradeEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.TradeEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    payment: jspb.Message.getFieldWithDefault(msg, 3, 0),
    idx: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.TradeEvent}
 */
proto.bulwa.TradeEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.TradeEvent;
  return proto.bulwa.TradeEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.TradeEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.TradeEvent}
 */
proto.bulwa.TradeEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.bulwa.TradeEvent.Kind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPayment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.TradeEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.TradeEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.TradeEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.TradeEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPayment();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getIdx();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.bulwa.TradeEvent.Kind = {
  YELLOW: 0,
  BLUE: 1,
  BAD_DRINK: 2,
  GOOD_DRINK: 3,
  PURPLE: 4,
  DEMAND_BULBS: 5,
  DEMAND_DRINK: 6
};

/**
 * optional Kind kind = 1;
 * @return {!proto.bulwa.TradeEvent.Kind}
 */
proto.bulwa.TradeEvent.prototype.getKind = function() {
  return /** @type {!proto.bulwa.TradeEvent.Kind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.bulwa.TradeEvent.Kind} value
 * @return {!proto.bulwa.TradeEvent} returns this
 */
proto.bulwa.TradeEvent.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 quantity = 2;
 * @return {number}
 */
proto.bulwa.TradeEvent.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.TradeEvent} returns this
 */
proto.bulwa.TradeEvent.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 payment = 3;
 * @return {number}
 */
proto.bulwa.TradeEvent.prototype.getPayment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.TradeEvent} returns this
 */
proto.bulwa.TradeEvent.prototype.setPayment = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 idx = 4;
 * @return {number}
 */
proto.bulwa.TradeEvent.prototype.getIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.TradeEvent} returns this
 */
proto.bulwa.TradeEvent.prototype.setIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.PaymentEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.PaymentEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.PaymentEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.PaymentEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    quantity: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.PaymentEvent}
 */
proto.bulwa.PaymentEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.PaymentEvent;
  return proto.bulwa.PaymentEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.PaymentEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.PaymentEvent}
 */
proto.bulwa.PaymentEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.PaymentEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.PaymentEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.PaymentEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.PaymentEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 quantity = 1;
 * @return {number}
 */
proto.bulwa.PaymentEvent.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.PaymentEvent} returns this
 */
proto.bulwa.PaymentEvent.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.BuildEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.BuildEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.BuildEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.BuildEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    idx: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.BuildEvent}
 */
proto.bulwa.BuildEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.BuildEvent;
  return proto.bulwa.BuildEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.BuildEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.BuildEvent}
 */
proto.bulwa.BuildEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.BuildEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.BuildEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.BuildEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.BuildEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdx();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 idx = 1;
 * @return {number}
 */
proto.bulwa.BuildEvent.prototype.getIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.BuildEvent} returns this
 */
proto.bulwa.BuildEvent.prototype.setIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.TekkoEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.TekkoEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.TekkoEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.TekkoEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    idx: jspb.Message.getFieldWithDefault(msg, 1, 0),
    kind: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.TekkoEvent}
 */
proto.bulwa.TekkoEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.TekkoEvent;
  return proto.bulwa.TekkoEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.TekkoEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.TekkoEvent}
 */
proto.bulwa.TekkoEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdx(value);
      break;
    case 2:
      var value = /** @type {!proto.bulwa.TekkoEvent.Kind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.TekkoEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.TekkoEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.TekkoEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.TekkoEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdx();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.bulwa.TekkoEvent.Kind = {
  SPAWN: 0,
  REMOVAL: 1
};

/**
 * optional uint32 idx = 1;
 * @return {number}
 */
proto.bulwa.TekkoEvent.prototype.getIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.TekkoEvent} returns this
 */
proto.bulwa.TekkoEvent.prototype.setIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Kind kind = 2;
 * @return {!proto.bulwa.TekkoEvent.Kind}
 */
proto.bulwa.TekkoEvent.prototype.getKind = function() {
  return /** @type {!proto.bulwa.TekkoEvent.Kind} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.bulwa.TekkoEvent.Kind} value
 * @return {!proto.bulwa.TekkoEvent} returns this
 */
proto.bulwa.TekkoEvent.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.RecruitmentEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.RecruitmentEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.RecruitmentEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.RecruitmentEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    target: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.RecruitmentEvent}
 */
proto.bulwa.RecruitmentEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.RecruitmentEvent;
  return proto.bulwa.RecruitmentEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.RecruitmentEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.RecruitmentEvent}
 */
proto.bulwa.RecruitmentEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setTarget(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.RecruitmentEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.RecruitmentEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.RecruitmentEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.RecruitmentEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional Player.Role target = 1;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.RecruitmentEvent.prototype.getTarget = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.RecruitmentEvent} returns this
 */
proto.bulwa.RecruitmentEvent.prototype.setTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 quantity = 2;
 * @return {number}
 */
proto.bulwa.RecruitmentEvent.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.RecruitmentEvent} returns this
 */
proto.bulwa.RecruitmentEvent.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.HarvestEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.HarvestEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.HarvestEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.HarvestEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    shoots: jspb.Message.getFieldWithDefault(msg, 1, 0),
    yellow: jspb.Message.getFieldWithDefault(msg, 2, 0),
    blue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    purple: jspb.Message.getFieldWithDefault(msg, 4, 0),
    idx: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.HarvestEvent}
 */
proto.bulwa.HarvestEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.HarvestEvent;
  return proto.bulwa.HarvestEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.HarvestEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.HarvestEvent}
 */
proto.bulwa.HarvestEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShoots(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYellow(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBlue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPurple(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.HarvestEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.HarvestEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.HarvestEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.HarvestEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShoots();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getYellow();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBlue();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPurple();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getIdx();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional uint32 shoots = 1;
 * @return {number}
 */
proto.bulwa.HarvestEvent.prototype.getShoots = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.HarvestEvent} returns this
 */
proto.bulwa.HarvestEvent.prototype.setShoots = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 yellow = 2;
 * @return {number}
 */
proto.bulwa.HarvestEvent.prototype.getYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.HarvestEvent} returns this
 */
proto.bulwa.HarvestEvent.prototype.setYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 blue = 3;
 * @return {number}
 */
proto.bulwa.HarvestEvent.prototype.getBlue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.HarvestEvent} returns this
 */
proto.bulwa.HarvestEvent.prototype.setBlue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 purple = 4;
 * @return {number}
 */
proto.bulwa.HarvestEvent.prototype.getPurple = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.HarvestEvent} returns this
 */
proto.bulwa.HarvestEvent.prototype.setPurple = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 idx = 5;
 * @return {number}
 */
proto.bulwa.HarvestEvent.prototype.getIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.HarvestEvent} returns this
 */
proto.bulwa.HarvestEvent.prototype.setIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.GrowthEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.GrowthEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.GrowthEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.GrowthEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    shoots: jspb.Message.getFieldWithDefault(msg, 1, 0),
    yellow: jspb.Message.getFieldWithDefault(msg, 2, 0),
    blue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    purple: jspb.Message.getFieldWithDefault(msg, 4, 0),
    idx: jspb.Message.getFieldWithDefault(msg, 5, 0),
    killed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.GrowthEvent}
 */
proto.bulwa.GrowthEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.GrowthEvent;
  return proto.bulwa.GrowthEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.GrowthEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.GrowthEvent}
 */
proto.bulwa.GrowthEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShoots(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYellow(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBlue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPurple(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdx(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKilled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.GrowthEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.GrowthEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.GrowthEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.GrowthEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShoots();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getYellow();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBlue();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPurple();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getIdx();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getKilled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional uint32 shoots = 1;
 * @return {number}
 */
proto.bulwa.GrowthEvent.prototype.getShoots = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.GrowthEvent} returns this
 */
proto.bulwa.GrowthEvent.prototype.setShoots = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 yellow = 2;
 * @return {number}
 */
proto.bulwa.GrowthEvent.prototype.getYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.GrowthEvent} returns this
 */
proto.bulwa.GrowthEvent.prototype.setYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 blue = 3;
 * @return {number}
 */
proto.bulwa.GrowthEvent.prototype.getBlue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.GrowthEvent} returns this
 */
proto.bulwa.GrowthEvent.prototype.setBlue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 purple = 4;
 * @return {number}
 */
proto.bulwa.GrowthEvent.prototype.getPurple = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.GrowthEvent} returns this
 */
proto.bulwa.GrowthEvent.prototype.setPurple = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 idx = 5;
 * @return {number}
 */
proto.bulwa.GrowthEvent.prototype.getIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.GrowthEvent} returns this
 */
proto.bulwa.GrowthEvent.prototype.setIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool killed = 6;
 * @return {boolean}
 */
proto.bulwa.GrowthEvent.prototype.getKilled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.bulwa.GrowthEvent} returns this
 */
proto.bulwa.GrowthEvent.prototype.setKilled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.bulwa.PlayerActions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.PlayerActions.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.PlayerActions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.PlayerActions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.PlayerActions.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.bulwa.Action.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.PlayerActions}
 */
proto.bulwa.PlayerActions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.PlayerActions;
  return proto.bulwa.PlayerActions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.PlayerActions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.PlayerActions}
 */
proto.bulwa.PlayerActions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.bulwa.Action;
      reader.readMessage(value,proto.bulwa.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.PlayerActions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.PlayerActions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.PlayerActions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.PlayerActions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.bulwa.Action.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Action actions = 1;
 * @return {!Array<!proto.bulwa.Action>}
 */
proto.bulwa.PlayerActions.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.bulwa.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.bulwa.Action, 1));
};


/**
 * @param {!Array<!proto.bulwa.Action>} value
 * @return {!proto.bulwa.PlayerActions} returns this
*/
proto.bulwa.PlayerActions.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.bulwa.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.bulwa.Action}
 */
proto.bulwa.PlayerActions.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.bulwa.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.bulwa.PlayerActions} returns this
 */
proto.bulwa.PlayerActions.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.bulwa.Action.oneofGroups_ = [[10,11,12,13,14,15,16,17,18]];

/**
 * @enum {number}
 */
proto.bulwa.Action.ActionCase = {
  ACTION_NOT_SET: 0,
  HARVEST: 10,
  RECRUITMENT: 11,
  ASSIGNMENT: 12,
  SALARY: 13,
  PRODUCTION: 14,
  WAREHOUSE: 15,
  TRADE: 16,
  SPECIAL: 17,
  WORKINGTIME: 18
};

/**
 * @return {proto.bulwa.Action.ActionCase}
 */
proto.bulwa.Action.prototype.getActionCase = function() {
  return /** @type {proto.bulwa.Action.ActionCase} */(jspb.Message.computeOneofCase(this, proto.bulwa.Action.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
    harvest: (f = msg.getHarvest()) && proto.bulwa.HarvestAction.toObject(includeInstance, f),
    recruitment: (f = msg.getRecruitment()) && proto.bulwa.RecruitmentAction.toObject(includeInstance, f),
    assignment: (f = msg.getAssignment()) && proto.bulwa.AssignmentAction.toObject(includeInstance, f),
    salary: (f = msg.getSalary()) && proto.bulwa.SalaryAction.toObject(includeInstance, f),
    production: (f = msg.getProduction()) && proto.bulwa.ProductionAction.toObject(includeInstance, f),
    warehouse: (f = msg.getWarehouse()) && proto.bulwa.WarehouseAction.toObject(includeInstance, f),
    trade: (f = msg.getTrade()) && proto.bulwa.TradeAction.toObject(includeInstance, f),
    special: (f = msg.getSpecial()) && proto.bulwa.SpecialAction.toObject(includeInstance, f),
    workingtime: (f = msg.getWorkingtime()) && proto.bulwa.WorkingTimeAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.Action}
 */
proto.bulwa.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.Action;
  return proto.bulwa.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.Action}
 */
proto.bulwa.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new proto.bulwa.HarvestAction;
      reader.readMessage(value,proto.bulwa.HarvestAction.deserializeBinaryFromReader);
      msg.setHarvest(value);
      break;
    case 11:
      var value = new proto.bulwa.RecruitmentAction;
      reader.readMessage(value,proto.bulwa.RecruitmentAction.deserializeBinaryFromReader);
      msg.setRecruitment(value);
      break;
    case 12:
      var value = new proto.bulwa.AssignmentAction;
      reader.readMessage(value,proto.bulwa.AssignmentAction.deserializeBinaryFromReader);
      msg.setAssignment(value);
      break;
    case 13:
      var value = new proto.bulwa.SalaryAction;
      reader.readMessage(value,proto.bulwa.SalaryAction.deserializeBinaryFromReader);
      msg.setSalary(value);
      break;
    case 14:
      var value = new proto.bulwa.ProductionAction;
      reader.readMessage(value,proto.bulwa.ProductionAction.deserializeBinaryFromReader);
      msg.setProduction(value);
      break;
    case 15:
      var value = new proto.bulwa.WarehouseAction;
      reader.readMessage(value,proto.bulwa.WarehouseAction.deserializeBinaryFromReader);
      msg.setWarehouse(value);
      break;
    case 16:
      var value = new proto.bulwa.TradeAction;
      reader.readMessage(value,proto.bulwa.TradeAction.deserializeBinaryFromReader);
      msg.setTrade(value);
      break;
    case 17:
      var value = new proto.bulwa.SpecialAction;
      reader.readMessage(value,proto.bulwa.SpecialAction.deserializeBinaryFromReader);
      msg.setSpecial(value);
      break;
    case 18:
      var value = new proto.bulwa.WorkingTimeAction;
      reader.readMessage(value,proto.bulwa.WorkingTimeAction.deserializeBinaryFromReader);
      msg.setWorkingtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHarvest();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.bulwa.HarvestAction.serializeBinaryToWriter
    );
  }
  f = message.getRecruitment();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.bulwa.RecruitmentAction.serializeBinaryToWriter
    );
  }
  f = message.getAssignment();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.bulwa.AssignmentAction.serializeBinaryToWriter
    );
  }
  f = message.getSalary();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.bulwa.SalaryAction.serializeBinaryToWriter
    );
  }
  f = message.getProduction();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.bulwa.ProductionAction.serializeBinaryToWriter
    );
  }
  f = message.getWarehouse();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.bulwa.WarehouseAction.serializeBinaryToWriter
    );
  }
  f = message.getTrade();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.bulwa.TradeAction.serializeBinaryToWriter
    );
  }
  f = message.getSpecial();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.bulwa.SpecialAction.serializeBinaryToWriter
    );
  }
  f = message.getWorkingtime();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.bulwa.WorkingTimeAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional HarvestAction harvest = 10;
 * @return {?proto.bulwa.HarvestAction}
 */
proto.bulwa.Action.prototype.getHarvest = function() {
  return /** @type{?proto.bulwa.HarvestAction} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.HarvestAction, 10));
};


/**
 * @param {?proto.bulwa.HarvestAction|undefined} value
 * @return {!proto.bulwa.Action} returns this
*/
proto.bulwa.Action.prototype.setHarvest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.bulwa.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Action} returns this
 */
proto.bulwa.Action.prototype.clearHarvest = function() {
  return this.setHarvest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Action.prototype.hasHarvest = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional RecruitmentAction recruitment = 11;
 * @return {?proto.bulwa.RecruitmentAction}
 */
proto.bulwa.Action.prototype.getRecruitment = function() {
  return /** @type{?proto.bulwa.RecruitmentAction} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.RecruitmentAction, 11));
};


/**
 * @param {?proto.bulwa.RecruitmentAction|undefined} value
 * @return {!proto.bulwa.Action} returns this
*/
proto.bulwa.Action.prototype.setRecruitment = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.bulwa.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Action} returns this
 */
proto.bulwa.Action.prototype.clearRecruitment = function() {
  return this.setRecruitment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Action.prototype.hasRecruitment = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional AssignmentAction assignment = 12;
 * @return {?proto.bulwa.AssignmentAction}
 */
proto.bulwa.Action.prototype.getAssignment = function() {
  return /** @type{?proto.bulwa.AssignmentAction} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.AssignmentAction, 12));
};


/**
 * @param {?proto.bulwa.AssignmentAction|undefined} value
 * @return {!proto.bulwa.Action} returns this
*/
proto.bulwa.Action.prototype.setAssignment = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.bulwa.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Action} returns this
 */
proto.bulwa.Action.prototype.clearAssignment = function() {
  return this.setAssignment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Action.prototype.hasAssignment = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional SalaryAction salary = 13;
 * @return {?proto.bulwa.SalaryAction}
 */
proto.bulwa.Action.prototype.getSalary = function() {
  return /** @type{?proto.bulwa.SalaryAction} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.SalaryAction, 13));
};


/**
 * @param {?proto.bulwa.SalaryAction|undefined} value
 * @return {!proto.bulwa.Action} returns this
*/
proto.bulwa.Action.prototype.setSalary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.bulwa.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Action} returns this
 */
proto.bulwa.Action.prototype.clearSalary = function() {
  return this.setSalary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Action.prototype.hasSalary = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional ProductionAction production = 14;
 * @return {?proto.bulwa.ProductionAction}
 */
proto.bulwa.Action.prototype.getProduction = function() {
  return /** @type{?proto.bulwa.ProductionAction} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.ProductionAction, 14));
};


/**
 * @param {?proto.bulwa.ProductionAction|undefined} value
 * @return {!proto.bulwa.Action} returns this
*/
proto.bulwa.Action.prototype.setProduction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.bulwa.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Action} returns this
 */
proto.bulwa.Action.prototype.clearProduction = function() {
  return this.setProduction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Action.prototype.hasProduction = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional WarehouseAction warehouse = 15;
 * @return {?proto.bulwa.WarehouseAction}
 */
proto.bulwa.Action.prototype.getWarehouse = function() {
  return /** @type{?proto.bulwa.WarehouseAction} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.WarehouseAction, 15));
};


/**
 * @param {?proto.bulwa.WarehouseAction|undefined} value
 * @return {!proto.bulwa.Action} returns this
*/
proto.bulwa.Action.prototype.setWarehouse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.bulwa.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Action} returns this
 */
proto.bulwa.Action.prototype.clearWarehouse = function() {
  return this.setWarehouse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Action.prototype.hasWarehouse = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional TradeAction trade = 16;
 * @return {?proto.bulwa.TradeAction}
 */
proto.bulwa.Action.prototype.getTrade = function() {
  return /** @type{?proto.bulwa.TradeAction} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.TradeAction, 16));
};


/**
 * @param {?proto.bulwa.TradeAction|undefined} value
 * @return {!proto.bulwa.Action} returns this
*/
proto.bulwa.Action.prototype.setTrade = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.bulwa.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Action} returns this
 */
proto.bulwa.Action.prototype.clearTrade = function() {
  return this.setTrade(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Action.prototype.hasTrade = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional SpecialAction special = 17;
 * @return {?proto.bulwa.SpecialAction}
 */
proto.bulwa.Action.prototype.getSpecial = function() {
  return /** @type{?proto.bulwa.SpecialAction} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.SpecialAction, 17));
};


/**
 * @param {?proto.bulwa.SpecialAction|undefined} value
 * @return {!proto.bulwa.Action} returns this
*/
proto.bulwa.Action.prototype.setSpecial = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.bulwa.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Action} returns this
 */
proto.bulwa.Action.prototype.clearSpecial = function() {
  return this.setSpecial(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Action.prototype.hasSpecial = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional WorkingTimeAction workingTime = 18;
 * @return {?proto.bulwa.WorkingTimeAction}
 */
proto.bulwa.Action.prototype.getWorkingtime = function() {
  return /** @type{?proto.bulwa.WorkingTimeAction} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.WorkingTimeAction, 18));
};


/**
 * @param {?proto.bulwa.WorkingTimeAction|undefined} value
 * @return {!proto.bulwa.Action} returns this
*/
proto.bulwa.Action.prototype.setWorkingtime = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.bulwa.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.Action} returns this
 */
proto.bulwa.Action.prototype.clearWorkingtime = function() {
  return this.setWorkingtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.Action.prototype.hasWorkingtime = function() {
  return jspb.Message.getField(this, 18) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.WorkingTimeAction.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.WorkingTimeAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.WorkingTimeAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.WorkingTimeAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    length: jspb.Message.getFieldWithDefault(msg, 1, 0),
    source: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.WorkingTimeAction}
 */
proto.bulwa.WorkingTimeAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.WorkingTimeAction;
  return proto.bulwa.WorkingTimeAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.WorkingTimeAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.WorkingTimeAction}
 */
proto.bulwa.WorkingTimeAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLength(value);
      break;
    case 2:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.WorkingTimeAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.WorkingTimeAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.WorkingTimeAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.WorkingTimeAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLength();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional uint32 length = 1;
 * @return {number}
 */
proto.bulwa.WorkingTimeAction.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.WorkingTimeAction} returns this
 */
proto.bulwa.WorkingTimeAction.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Player.Role source = 2;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.WorkingTimeAction.prototype.getSource = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.WorkingTimeAction} returns this
 */
proto.bulwa.WorkingTimeAction.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.SpecialAction.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.SpecialAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.SpecialAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.SpecialAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    price: jspb.Message.getFieldWithDefault(msg, 2, 0),
    source: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.SpecialAction}
 */
proto.bulwa.SpecialAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.SpecialAction;
  return proto.bulwa.SpecialAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.SpecialAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.SpecialAction}
 */
proto.bulwa.SpecialAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.bulwa.SpecialAction.Kind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.SpecialAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.SpecialAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.SpecialAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.SpecialAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.bulwa.SpecialAction.Kind = {
  NONE: 0,
  METEO_ANALYZE_TEMPERATURE: 1,
  METEO_ANALYZE_WIND: 2,
  METEO_ANALYZE_RAINFALL: 3,
  METEO_BETTER_SI_TEMPERATURE: 4,
  METEO_BETTER_SI_WIND: 5,
  METEO_BETTER_SI_RAINFALL: 6,
  HR_ANALYZE_COMMITMENT: 7,
  HR_ANALYZE_SATISFACTION: 8,
  HR_ANALYZE_PR: 9,
  HR_POLICY_LOWEST: 10,
  HR_POLICY_HIGHEST: 11,
  HR_PROTECTIVE_GEAR: 12,
  HR_PRODUCTION_SAFETY: 13,
  WAREHOUSE_INSPECTION: 15,
  PRODUCTION_NEW_UNIT: 17,
  TRADE_NEW_POST: 18
};

/**
 * optional Kind kind = 1;
 * @return {!proto.bulwa.SpecialAction.Kind}
 */
proto.bulwa.SpecialAction.prototype.getKind = function() {
  return /** @type {!proto.bulwa.SpecialAction.Kind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.bulwa.SpecialAction.Kind} value
 * @return {!proto.bulwa.SpecialAction} returns this
 */
proto.bulwa.SpecialAction.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 price = 2;
 * @return {number}
 */
proto.bulwa.SpecialAction.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.SpecialAction} returns this
 */
proto.bulwa.SpecialAction.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Player.Role source = 3;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.SpecialAction.prototype.getSource = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.SpecialAction} returns this
 */
proto.bulwa.SpecialAction.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.TradeAction.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.TradeAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.TradeAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.TradeAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    yellow: jspb.Message.getFieldWithDefault(msg, 1, 0),
    blue: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gooddrink: jspb.Message.getFieldWithDefault(msg, 3, 0),
    yellowprice: jspb.Message.getFieldWithDefault(msg, 4, 0),
    blueprice: jspb.Message.getFieldWithDefault(msg, 5, 0),
    gooddrinkprice: jspb.Message.getFieldWithDefault(msg, 6, 0),
    postidx: jspb.Message.getFieldWithDefault(msg, 7, 0),
    baddrink: jspb.Message.getFieldWithDefault(msg, 8, 0),
    baddrinkprice: jspb.Message.getFieldWithDefault(msg, 9, 0),
    purple: jspb.Message.getFieldWithDefault(msg, 10, 0),
    purpleprice: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.TradeAction}
 */
proto.bulwa.TradeAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.TradeAction;
  return proto.bulwa.TradeAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.TradeAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.TradeAction}
 */
proto.bulwa.TradeAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYellow(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBlue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGooddrink(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYellowprice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBlueprice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGooddrinkprice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPostidx(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBaddrink(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBaddrinkprice(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPurple(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPurpleprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.TradeAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.TradeAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.TradeAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.TradeAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYellow();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBlue();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getGooddrink();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getYellowprice();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getBlueprice();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getGooddrinkprice();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getPostidx();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getBaddrink();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getBaddrinkprice();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getPurple();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getPurpleprice();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
};


/**
 * optional uint32 yellow = 1;
 * @return {number}
 */
proto.bulwa.TradeAction.prototype.getYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.TradeAction} returns this
 */
proto.bulwa.TradeAction.prototype.setYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 blue = 2;
 * @return {number}
 */
proto.bulwa.TradeAction.prototype.getBlue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.TradeAction} returns this
 */
proto.bulwa.TradeAction.prototype.setBlue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 goodDrink = 3;
 * @return {number}
 */
proto.bulwa.TradeAction.prototype.getGooddrink = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.TradeAction} returns this
 */
proto.bulwa.TradeAction.prototype.setGooddrink = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 yellowPrice = 4;
 * @return {number}
 */
proto.bulwa.TradeAction.prototype.getYellowprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.TradeAction} returns this
 */
proto.bulwa.TradeAction.prototype.setYellowprice = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 bluePrice = 5;
 * @return {number}
 */
proto.bulwa.TradeAction.prototype.getBlueprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.TradeAction} returns this
 */
proto.bulwa.TradeAction.prototype.setBlueprice = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 goodDrinkPrice = 6;
 * @return {number}
 */
proto.bulwa.TradeAction.prototype.getGooddrinkprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.TradeAction} returns this
 */
proto.bulwa.TradeAction.prototype.setGooddrinkprice = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 postIdx = 7;
 * @return {number}
 */
proto.bulwa.TradeAction.prototype.getPostidx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.TradeAction} returns this
 */
proto.bulwa.TradeAction.prototype.setPostidx = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 badDrink = 8;
 * @return {number}
 */
proto.bulwa.TradeAction.prototype.getBaddrink = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.TradeAction} returns this
 */
proto.bulwa.TradeAction.prototype.setBaddrink = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 badDrinkPrice = 9;
 * @return {number}
 */
proto.bulwa.TradeAction.prototype.getBaddrinkprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.TradeAction} returns this
 */
proto.bulwa.TradeAction.prototype.setBaddrinkprice = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 purple = 10;
 * @return {number}
 */
proto.bulwa.TradeAction.prototype.getPurple = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.TradeAction} returns this
 */
proto.bulwa.TradeAction.prototype.setPurple = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 purplePrice = 11;
 * @return {number}
 */
proto.bulwa.TradeAction.prototype.getPurpleprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.TradeAction} returns this
 */
proto.bulwa.TradeAction.prototype.setPurpleprice = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.bulwa.WarehouseAction.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.WarehouseAction.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.WarehouseAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.WarehouseAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.WarehouseAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    bulbspercentage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    priorityList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.WarehouseAction}
 */
proto.bulwa.WarehouseAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.WarehouseAction;
  return proto.bulwa.WarehouseAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.WarehouseAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.WarehouseAction}
 */
proto.bulwa.WarehouseAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBulbspercentage(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.bulwa.Player.Role>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPriority(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.WarehouseAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.WarehouseAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.WarehouseAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.WarehouseAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBulbspercentage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPriorityList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
};


/**
 * optional uint32 bulbsPercentage = 1;
 * @return {number}
 */
proto.bulwa.WarehouseAction.prototype.getBulbspercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.WarehouseAction} returns this
 */
proto.bulwa.WarehouseAction.prototype.setBulbspercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Player.Role priority = 6;
 * @return {!Array<!proto.bulwa.Player.Role>}
 */
proto.bulwa.WarehouseAction.prototype.getPriorityList = function() {
  return /** @type {!Array<!proto.bulwa.Player.Role>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.bulwa.Player.Role>} value
 * @return {!proto.bulwa.WarehouseAction} returns this
 */
proto.bulwa.WarehouseAction.prototype.setPriorityList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @param {number=} opt_index
 * @return {!proto.bulwa.WarehouseAction} returns this
 */
proto.bulwa.WarehouseAction.prototype.addPriority = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.bulwa.WarehouseAction} returns this
 */
proto.bulwa.WarehouseAction.prototype.clearPriorityList = function() {
  return this.setPriorityList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.ProductionAction.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.ProductionAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.ProductionAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.ProductionAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    yellow: jspb.Message.getFieldWithDefault(msg, 1, 0),
    blue: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bottles: jspb.Message.getFieldWithDefault(msg, 3, 0),
    unitidx: jspb.Message.getFieldWithDefault(msg, 4, 0),
    source: jspb.Message.getFieldWithDefault(msg, 5, 0),
    destroybaddrink: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.ProductionAction}
 */
proto.bulwa.ProductionAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.ProductionAction;
  return proto.bulwa.ProductionAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.ProductionAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.ProductionAction}
 */
proto.bulwa.ProductionAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYellow(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBlue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBottles(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUnitidx(value);
      break;
    case 5:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDestroybaddrink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.ProductionAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.ProductionAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.ProductionAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.ProductionAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYellow();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBlue();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBottles();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getUnitidx();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDestroybaddrink();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional uint32 yellow = 1;
 * @return {number}
 */
proto.bulwa.ProductionAction.prototype.getYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.ProductionAction} returns this
 */
proto.bulwa.ProductionAction.prototype.setYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 blue = 2;
 * @return {number}
 */
proto.bulwa.ProductionAction.prototype.getBlue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.ProductionAction} returns this
 */
proto.bulwa.ProductionAction.prototype.setBlue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 bottles = 3;
 * @return {number}
 */
proto.bulwa.ProductionAction.prototype.getBottles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.ProductionAction} returns this
 */
proto.bulwa.ProductionAction.prototype.setBottles = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 unitIdx = 4;
 * @return {number}
 */
proto.bulwa.ProductionAction.prototype.getUnitidx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.ProductionAction} returns this
 */
proto.bulwa.ProductionAction.prototype.setUnitidx = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Player.Role source = 5;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.ProductionAction.prototype.getSource = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.ProductionAction} returns this
 */
proto.bulwa.ProductionAction.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool destroyBadDrink = 6;
 * @return {boolean}
 */
proto.bulwa.ProductionAction.prototype.getDestroybaddrink = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.bulwa.ProductionAction} returns this
 */
proto.bulwa.ProductionAction.prototype.setDestroybaddrink = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.SalaryAction.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.SalaryAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.SalaryAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.SalaryAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, 0),
    change: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.SalaryAction}
 */
proto.bulwa.SalaryAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.SalaryAction;
  return proto.bulwa.SalaryAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.SalaryAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.SalaryAction}
 */
proto.bulwa.SalaryAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.SalaryAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.SalaryAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.SalaryAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.SalaryAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getChange();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional Player.Role source = 1;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.SalaryAction.prototype.getSource = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.SalaryAction} returns this
 */
proto.bulwa.SalaryAction.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 change = 2;
 * @return {number}
 */
proto.bulwa.SalaryAction.prototype.getChange = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.SalaryAction} returns this
 */
proto.bulwa.SalaryAction.prototype.setChange = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.AssignmentAction.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.AssignmentAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.AssignmentAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.AssignmentAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    personid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    source: jspb.Message.getFieldWithDefault(msg, 2, 0),
    teamid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    teamtype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.AssignmentAction}
 */
proto.bulwa.AssignmentAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.AssignmentAction;
  return proto.bulwa.AssignmentAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.AssignmentAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.AssignmentAction}
 */
proto.bulwa.AssignmentAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPersonid(value);
      break;
    case 2:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeamid(value);
      break;
    case 4:
      var value = /** @type {!proto.bulwa.AssignmentAction.TeamType} */ (reader.readEnum());
      msg.setTeamtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.AssignmentAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.AssignmentAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.AssignmentAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.AssignmentAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTeamid();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTeamtype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.bulwa.AssignmentAction.TeamType = {
  NONE: 0,
  HARVESTERS: 1,
  GARDENERS: 2,
  OPERATORS: 3,
  EXPERTS: 4,
  TRADERS: 5,
  BUILDERS: 6,
  CLEANERS: 7,
  WAREHOUSEMEN: 10
};

/**
 * optional uint32 personId = 1;
 * @return {number}
 */
proto.bulwa.AssignmentAction.prototype.getPersonid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.AssignmentAction} returns this
 */
proto.bulwa.AssignmentAction.prototype.setPersonid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Player.Role source = 2;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.AssignmentAction.prototype.getSource = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.AssignmentAction} returns this
 */
proto.bulwa.AssignmentAction.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 teamId = 3;
 * @return {number}
 */
proto.bulwa.AssignmentAction.prototype.getTeamid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.AssignmentAction} returns this
 */
proto.bulwa.AssignmentAction.prototype.setTeamid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional TeamType teamType = 4;
 * @return {!proto.bulwa.AssignmentAction.TeamType}
 */
proto.bulwa.AssignmentAction.prototype.getTeamtype = function() {
  return /** @type {!proto.bulwa.AssignmentAction.TeamType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.bulwa.AssignmentAction.TeamType} value
 * @return {!proto.bulwa.AssignmentAction} returns this
 */
proto.bulwa.AssignmentAction.prototype.setTeamtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.RecruitmentAction.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.RecruitmentAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.RecruitmentAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.RecruitmentAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.RecruitmentAction}
 */
proto.bulwa.RecruitmentAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.RecruitmentAction;
  return proto.bulwa.RecruitmentAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.RecruitmentAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.RecruitmentAction}
 */
proto.bulwa.RecruitmentAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.RecruitmentAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.RecruitmentAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.RecruitmentAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.RecruitmentAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional Player.Role source = 1;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.RecruitmentAction.prototype.getSource = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.RecruitmentAction} returns this
 */
proto.bulwa.RecruitmentAction.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 quantity = 2;
 * @return {number}
 */
proto.bulwa.RecruitmentAction.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.RecruitmentAction} returns this
 */
proto.bulwa.RecruitmentAction.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.HarvestAction.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.HarvestAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.HarvestAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.HarvestAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    shoots: jspb.Message.getFieldWithDefault(msg, 1, 0),
    yellow: jspb.Message.getFieldWithDefault(msg, 2, 0),
    blue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    purple: jspb.Message.getFieldWithDefault(msg, 4, 0),
    circleidx: jspb.Message.getFieldWithDefault(msg, 5, 0),
    source: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.HarvestAction}
 */
proto.bulwa.HarvestAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.HarvestAction;
  return proto.bulwa.HarvestAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.HarvestAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.HarvestAction}
 */
proto.bulwa.HarvestAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShoots(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYellow(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBlue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPurple(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCircleidx(value);
      break;
    case 6:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.HarvestAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.HarvestAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.HarvestAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.HarvestAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShoots();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getYellow();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBlue();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPurple();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getCircleidx();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional uint32 shoots = 1;
 * @return {number}
 */
proto.bulwa.HarvestAction.prototype.getShoots = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.HarvestAction} returns this
 */
proto.bulwa.HarvestAction.prototype.setShoots = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 yellow = 2;
 * @return {number}
 */
proto.bulwa.HarvestAction.prototype.getYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.HarvestAction} returns this
 */
proto.bulwa.HarvestAction.prototype.setYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 blue = 3;
 * @return {number}
 */
proto.bulwa.HarvestAction.prototype.getBlue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.HarvestAction} returns this
 */
proto.bulwa.HarvestAction.prototype.setBlue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 purple = 4;
 * @return {number}
 */
proto.bulwa.HarvestAction.prototype.getPurple = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.HarvestAction} returns this
 */
proto.bulwa.HarvestAction.prototype.setPurple = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 circleIdx = 5;
 * @return {number}
 */
proto.bulwa.HarvestAction.prototype.getCircleidx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.HarvestAction} returns this
 */
proto.bulwa.HarvestAction.prototype.setCircleidx = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional Player.Role source = 6;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.HarvestAction.prototype.getSource = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.HarvestAction} returns this
 */
proto.bulwa.HarvestAction.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.SelectRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.SelectRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.SelectRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.SelectRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: jspb.Message.getFieldWithDefault(msg, 1, 0),
    gametoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.SelectRoleRequest}
 */
proto.bulwa.SelectRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.SelectRoleRequest;
  return proto.bulwa.SelectRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.SelectRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.SelectRoleRequest}
 */
proto.bulwa.SelectRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGametoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.SelectRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.SelectRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.SelectRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.SelectRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getGametoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Player.Role role = 1;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.SelectRoleRequest.prototype.getRole = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.SelectRoleRequest} returns this
 */
proto.bulwa.SelectRoleRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string gameToken = 2;
 * @return {string}
 */
proto.bulwa.SelectRoleRequest.prototype.getGametoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.bulwa.SelectRoleRequest} returns this
 */
proto.bulwa.SelectRoleRequest.prototype.setGametoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.GameCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.GameCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.GameCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.GameCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    gametoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.GameCommand}
 */
proto.bulwa.GameCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.GameCommand;
  return proto.bulwa.GameCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.GameCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.GameCommand}
 */
proto.bulwa.GameCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGametoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.GameCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.GameCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.GameCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.GameCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGametoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string gameToken = 1;
 * @return {string}
 */
proto.bulwa.GameCommand.prototype.getGametoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.bulwa.GameCommand} returns this
 */
proto.bulwa.GameCommand.prototype.setGametoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.bulwa.GameEvent.repeatedFields_ = [3,4,5,6,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.GameEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.GameEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.GameEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.GameEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameparams: (f = msg.getGameparams()) && proto.bulwa.GameParams.toObject(includeInstance, f),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    playersList: jspb.Message.toObjectList(msg.getPlayersList(),
    proto.bulwa.Player.toObject, includeInstance),
    plantationsList: jspb.Message.toObjectList(msg.getPlantationsList(),
    proto.bulwa.Plantation.toObject, includeInstance),
    weatherList: jspb.Message.toObjectList(msg.getWeatherList(),
    proto.bulwa.Weather.toObject, includeInstance),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.bulwa.Event.toObject, includeInstance),
    turnnumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    productionsList: jspb.Message.toObjectList(msg.getProductionsList(),
    proto.bulwa.Production.toObject, includeInstance),
    warehouse: (f = msg.getWarehouse()) && proto.bulwa.Warehouse.toObject(includeInstance, f),
    balance: jspb.Message.getFieldWithDefault(msg, 10, 0),
    trade: (f = msg.getTrade()) && proto.bulwa.Trade.toObject(includeInstance, f),
    availabletohire: jspb.Message.getFieldWithDefault(msg, 12, 0),
    hr: (f = msg.getHr()) && proto.bulwa.HR.toObject(includeInstance, f),
    weatherforecast: (f = msg.getWeatherforecast()) && proto.bulwa.Weather.toObject(includeInstance, f),
    localmarketpoints: jspb.Message.getFieldWithDefault(msg, 15, 0),
    protectecosystempoints: jspb.Message.getFieldWithDefault(msg, 16, 0),
    crisispoints: jspb.Message.getFieldWithDefault(msg, 17, 0),
    workerspoints: jspb.Message.getFieldWithDefault(msg, 18, 0),
    balancepoints: jspb.Message.getFieldWithDefault(msg, 19, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.GameEvent}
 */
proto.bulwa.GameEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.GameEvent;
  return proto.bulwa.GameEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.GameEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.GameEvent}
 */
proto.bulwa.GameEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.bulwa.GameParams;
      reader.readMessage(value,proto.bulwa.GameParams.deserializeBinaryFromReader);
      msg.setGameparams(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = new proto.bulwa.Player;
      reader.readMessage(value,proto.bulwa.Player.deserializeBinaryFromReader);
      msg.addPlayers(value);
      break;
    case 4:
      var value = new proto.bulwa.Plantation;
      reader.readMessage(value,proto.bulwa.Plantation.deserializeBinaryFromReader);
      msg.addPlantations(value);
      break;
    case 5:
      var value = new proto.bulwa.Weather;
      reader.readMessage(value,proto.bulwa.Weather.deserializeBinaryFromReader);
      msg.addWeather(value);
      break;
    case 6:
      var value = new proto.bulwa.Event;
      reader.readMessage(value,proto.bulwa.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTurnnumber(value);
      break;
    case 8:
      var value = new proto.bulwa.Production;
      reader.readMessage(value,proto.bulwa.Production.deserializeBinaryFromReader);
      msg.addProductions(value);
      break;
    case 9:
      var value = new proto.bulwa.Warehouse;
      reader.readMessage(value,proto.bulwa.Warehouse.deserializeBinaryFromReader);
      msg.setWarehouse(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBalance(value);
      break;
    case 11:
      var value = new proto.bulwa.Trade;
      reader.readMessage(value,proto.bulwa.Trade.deserializeBinaryFromReader);
      msg.setTrade(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAvailabletohire(value);
      break;
    case 13:
      var value = new proto.bulwa.HR;
      reader.readMessage(value,proto.bulwa.HR.deserializeBinaryFromReader);
      msg.setHr(value);
      break;
    case 14:
      var value = new proto.bulwa.Weather;
      reader.readMessage(value,proto.bulwa.Weather.deserializeBinaryFromReader);
      msg.setWeatherforecast(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocalmarketpoints(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProtectecosystempoints(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCrisispoints(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWorkerspoints(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBalancepoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.GameEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.GameEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.GameEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.GameEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameparams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.bulwa.GameParams.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.bulwa.Player.serializeBinaryToWriter
    );
  }
  f = message.getPlantationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.bulwa.Plantation.serializeBinaryToWriter
    );
  }
  f = message.getWeatherList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.bulwa.Weather.serializeBinaryToWriter
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.bulwa.Event.serializeBinaryToWriter
    );
  }
  f = message.getTurnnumber();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getProductionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.bulwa.Production.serializeBinaryToWriter
    );
  }
  f = message.getWarehouse();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.bulwa.Warehouse.serializeBinaryToWriter
    );
  }
  f = message.getBalance();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getTrade();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.bulwa.Trade.serializeBinaryToWriter
    );
  }
  f = message.getAvailabletohire();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getHr();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.bulwa.HR.serializeBinaryToWriter
    );
  }
  f = message.getWeatherforecast();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.bulwa.Weather.serializeBinaryToWriter
    );
  }
  f = message.getLocalmarketpoints();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getProtectecosystempoints();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getCrisispoints();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getWorkerspoints();
  if (f !== 0) {
    writer.writeUint32(
      18,
      f
    );
  }
  f = message.getBalancepoints();
  if (f !== 0) {
    writer.writeUint32(
      19,
      f
    );
  }
};


/**
 * optional GameParams gameParams = 1;
 * @return {?proto.bulwa.GameParams}
 */
proto.bulwa.GameEvent.prototype.getGameparams = function() {
  return /** @type{?proto.bulwa.GameParams} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.GameParams, 1));
};


/**
 * @param {?proto.bulwa.GameParams|undefined} value
 * @return {!proto.bulwa.GameEvent} returns this
*/
proto.bulwa.GameEvent.prototype.setGameparams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.clearGameparams = function() {
  return this.setGameparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.GameEvent.prototype.hasGameparams = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.bulwa.GameEvent.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Player players = 3;
 * @return {!Array<!proto.bulwa.Player>}
 */
proto.bulwa.GameEvent.prototype.getPlayersList = function() {
  return /** @type{!Array<!proto.bulwa.Player>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.bulwa.Player, 3));
};


/**
 * @param {!Array<!proto.bulwa.Player>} value
 * @return {!proto.bulwa.GameEvent} returns this
*/
proto.bulwa.GameEvent.prototype.setPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.bulwa.Player=} opt_value
 * @param {number=} opt_index
 * @return {!proto.bulwa.Player}
 */
proto.bulwa.GameEvent.prototype.addPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.bulwa.Player, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};


/**
 * repeated Plantation plantations = 4;
 * @return {!Array<!proto.bulwa.Plantation>}
 */
proto.bulwa.GameEvent.prototype.getPlantationsList = function() {
  return /** @type{!Array<!proto.bulwa.Plantation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.bulwa.Plantation, 4));
};


/**
 * @param {!Array<!proto.bulwa.Plantation>} value
 * @return {!proto.bulwa.GameEvent} returns this
*/
proto.bulwa.GameEvent.prototype.setPlantationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.bulwa.Plantation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.bulwa.Plantation}
 */
proto.bulwa.GameEvent.prototype.addPlantations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.bulwa.Plantation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.clearPlantationsList = function() {
  return this.setPlantationsList([]);
};


/**
 * repeated Weather weather = 5;
 * @return {!Array<!proto.bulwa.Weather>}
 */
proto.bulwa.GameEvent.prototype.getWeatherList = function() {
  return /** @type{!Array<!proto.bulwa.Weather>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.bulwa.Weather, 5));
};


/**
 * @param {!Array<!proto.bulwa.Weather>} value
 * @return {!proto.bulwa.GameEvent} returns this
*/
proto.bulwa.GameEvent.prototype.setWeatherList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.bulwa.Weather=} opt_value
 * @param {number=} opt_index
 * @return {!proto.bulwa.Weather}
 */
proto.bulwa.GameEvent.prototype.addWeather = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.bulwa.Weather, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.clearWeatherList = function() {
  return this.setWeatherList([]);
};


/**
 * repeated Event events = 6;
 * @return {!Array<!proto.bulwa.Event>}
 */
proto.bulwa.GameEvent.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.bulwa.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.bulwa.Event, 6));
};


/**
 * @param {!Array<!proto.bulwa.Event>} value
 * @return {!proto.bulwa.GameEvent} returns this
*/
proto.bulwa.GameEvent.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.bulwa.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.bulwa.Event}
 */
proto.bulwa.GameEvent.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.bulwa.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional uint32 turnNumber = 7;
 * @return {number}
 */
proto.bulwa.GameEvent.prototype.getTurnnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.setTurnnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated Production productions = 8;
 * @return {!Array<!proto.bulwa.Production>}
 */
proto.bulwa.GameEvent.prototype.getProductionsList = function() {
  return /** @type{!Array<!proto.bulwa.Production>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.bulwa.Production, 8));
};


/**
 * @param {!Array<!proto.bulwa.Production>} value
 * @return {!proto.bulwa.GameEvent} returns this
*/
proto.bulwa.GameEvent.prototype.setProductionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.bulwa.Production=} opt_value
 * @param {number=} opt_index
 * @return {!proto.bulwa.Production}
 */
proto.bulwa.GameEvent.prototype.addProductions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.bulwa.Production, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.clearProductionsList = function() {
  return this.setProductionsList([]);
};


/**
 * optional Warehouse warehouse = 9;
 * @return {?proto.bulwa.Warehouse}
 */
proto.bulwa.GameEvent.prototype.getWarehouse = function() {
  return /** @type{?proto.bulwa.Warehouse} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.Warehouse, 9));
};


/**
 * @param {?proto.bulwa.Warehouse|undefined} value
 * @return {!proto.bulwa.GameEvent} returns this
*/
proto.bulwa.GameEvent.prototype.setWarehouse = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.clearWarehouse = function() {
  return this.setWarehouse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.GameEvent.prototype.hasWarehouse = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 balance = 10;
 * @return {number}
 */
proto.bulwa.GameEvent.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.setBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional Trade trade = 11;
 * @return {?proto.bulwa.Trade}
 */
proto.bulwa.GameEvent.prototype.getTrade = function() {
  return /** @type{?proto.bulwa.Trade} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.Trade, 11));
};


/**
 * @param {?proto.bulwa.Trade|undefined} value
 * @return {!proto.bulwa.GameEvent} returns this
*/
proto.bulwa.GameEvent.prototype.setTrade = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.clearTrade = function() {
  return this.setTrade(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.GameEvent.prototype.hasTrade = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional uint32 availableToHire = 12;
 * @return {number}
 */
proto.bulwa.GameEvent.prototype.getAvailabletohire = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.setAvailabletohire = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional HR hr = 13;
 * @return {?proto.bulwa.HR}
 */
proto.bulwa.GameEvent.prototype.getHr = function() {
  return /** @type{?proto.bulwa.HR} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.HR, 13));
};


/**
 * @param {?proto.bulwa.HR|undefined} value
 * @return {!proto.bulwa.GameEvent} returns this
*/
proto.bulwa.GameEvent.prototype.setHr = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.clearHr = function() {
  return this.setHr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.GameEvent.prototype.hasHr = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Weather weatherForecast = 14;
 * @return {?proto.bulwa.Weather}
 */
proto.bulwa.GameEvent.prototype.getWeatherforecast = function() {
  return /** @type{?proto.bulwa.Weather} */ (
    jspb.Message.getWrapperField(this, proto.bulwa.Weather, 14));
};


/**
 * @param {?proto.bulwa.Weather|undefined} value
 * @return {!proto.bulwa.GameEvent} returns this
*/
proto.bulwa.GameEvent.prototype.setWeatherforecast = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.clearWeatherforecast = function() {
  return this.setWeatherforecast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bulwa.GameEvent.prototype.hasWeatherforecast = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional uint32 localMarketPoints = 15;
 * @return {number}
 */
proto.bulwa.GameEvent.prototype.getLocalmarketpoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.setLocalmarketpoints = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 protectEcosystemPoints = 16;
 * @return {number}
 */
proto.bulwa.GameEvent.prototype.getProtectecosystempoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.setProtectecosystempoints = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint32 crisisPoints = 17;
 * @return {number}
 */
proto.bulwa.GameEvent.prototype.getCrisispoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.setCrisispoints = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional uint32 workersPoints = 18;
 * @return {number}
 */
proto.bulwa.GameEvent.prototype.getWorkerspoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.setWorkerspoints = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional uint32 balancePoints = 19;
 * @return {number}
 */
proto.bulwa.GameEvent.prototype.getBalancepoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.GameEvent} returns this
 */
proto.bulwa.GameEvent.prototype.setBalancepoints = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.Weather.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.Weather.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.Weather} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Weather.toObject = function(includeInstance, msg) {
  var f, obj = {
    wind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rainfall: jspb.Message.getFieldWithDefault(msg, 2, 0),
    temperature: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.Weather}
 */
proto.bulwa.Weather.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.Weather;
  return proto.bulwa.Weather.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.Weather} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.Weather}
 */
proto.bulwa.Weather.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWind(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRainfall(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTemperature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.Weather.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.Weather.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.Weather} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Weather.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWind();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRainfall();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTemperature();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 wind = 1;
 * @return {number}
 */
proto.bulwa.Weather.prototype.getWind = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Weather} returns this
 */
proto.bulwa.Weather.prototype.setWind = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 rainfall = 2;
 * @return {number}
 */
proto.bulwa.Weather.prototype.getRainfall = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Weather} returns this
 */
proto.bulwa.Weather.prototype.setRainfall = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 temperature = 3;
 * @return {number}
 */
proto.bulwa.Weather.prototype.getTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Weather} returns this
 */
proto.bulwa.Weather.prototype.setTemperature = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.Person.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.Person.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.Person} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Person.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    teamtype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    idx: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.Person}
 */
proto.bulwa.Person.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.Person;
  return proto.bulwa.Person.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.Person} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.Person}
 */
proto.bulwa.Person.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.bulwa.AssignmentAction.TeamType} */ (reader.readEnum());
      msg.setTeamtype(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.Person.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.Person.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.Person} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Person.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTeamtype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getIdx();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.bulwa.Person.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Person} returns this
 */
proto.bulwa.Person.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.bulwa.Person.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.bulwa.Person} returns this
 */
proto.bulwa.Person.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AssignmentAction.TeamType teamType = 3;
 * @return {!proto.bulwa.AssignmentAction.TeamType}
 */
proto.bulwa.Person.prototype.getTeamtype = function() {
  return /** @type {!proto.bulwa.AssignmentAction.TeamType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.bulwa.AssignmentAction.TeamType} value
 * @return {!proto.bulwa.Person} returns this
 */
proto.bulwa.Person.prototype.setTeamtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 idx = 4;
 * @return {number}
 */
proto.bulwa.Person.prototype.getIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Person} returns this
 */
proto.bulwa.Person.prototype.setIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.HR.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.HR.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.HR} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.HR.toObject = function(includeInstance, msg) {
  var f, obj = {
    salariesMap: (f = msg.getSalariesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.HR}
 */
proto.bulwa.HR.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.HR;
  return proto.bulwa.HR.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.HR} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.HR}
 */
proto.bulwa.HR.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getSalariesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readUint32, null, 0, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.HR.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.HR.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.HR} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.HR.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSalariesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeUint32);
  }
};


/**
 * map<int32, uint32> salaries = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.bulwa.HR.prototype.getSalariesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.bulwa.HR} returns this
 */
proto.bulwa.HR.prototype.clearSalariesMap = function() {
  this.getSalariesMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.bulwa.Trade.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.Trade.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.Trade.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.Trade} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Trade.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workersList: jspb.Message.toObjectList(msg.getWorkersList(),
    proto.bulwa.Person.toObject, includeInstance),
    availableposts: jspb.Message.getFieldWithDefault(msg, 3, 0),
    readyposts: jspb.Message.getFieldWithDefault(msg, 4, 0),
    spaceport: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.Trade}
 */
proto.bulwa.Trade.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.Trade;
  return proto.bulwa.Trade.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.Trade} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.Trade}
 */
proto.bulwa.Trade.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.bulwa.Person;
      reader.readMessage(value,proto.bulwa.Person.deserializeBinaryFromReader);
      msg.addWorkers(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAvailableposts(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReadyposts(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpaceport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.Trade.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.Trade.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.Trade} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Trade.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getWorkersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.bulwa.Person.serializeBinaryToWriter
    );
  }
  f = message.getAvailableposts();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getReadyposts();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getSpaceport();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional Player.Role id = 1;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.Trade.prototype.getId = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.Trade} returns this
 */
proto.bulwa.Trade.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated Person workers = 2;
 * @return {!Array<!proto.bulwa.Person>}
 */
proto.bulwa.Trade.prototype.getWorkersList = function() {
  return /** @type{!Array<!proto.bulwa.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.bulwa.Person, 2));
};


/**
 * @param {!Array<!proto.bulwa.Person>} value
 * @return {!proto.bulwa.Trade} returns this
*/
proto.bulwa.Trade.prototype.setWorkersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.bulwa.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.bulwa.Person}
 */
proto.bulwa.Trade.prototype.addWorkers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.bulwa.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.bulwa.Trade} returns this
 */
proto.bulwa.Trade.prototype.clearWorkersList = function() {
  return this.setWorkersList([]);
};


/**
 * optional uint32 availablePosts = 3;
 * @return {number}
 */
proto.bulwa.Trade.prototype.getAvailableposts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Trade} returns this
 */
proto.bulwa.Trade.prototype.setAvailableposts = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 readyPosts = 4;
 * @return {number}
 */
proto.bulwa.Trade.prototype.getReadyposts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Trade} returns this
 */
proto.bulwa.Trade.prototype.setReadyposts = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool spaceport = 5;
 * @return {boolean}
 */
proto.bulwa.Trade.prototype.getSpaceport = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.bulwa.Trade} returns this
 */
proto.bulwa.Trade.prototype.setSpaceport = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.bulwa.Warehouse.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.Warehouse.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.Warehouse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.Warehouse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Warehouse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    capacity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maxcapacity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    yellow: jspb.Message.getFieldWithDefault(msg, 4, 0),
    blue: jspb.Message.getFieldWithDefault(msg, 5, 0),
    baddrink: jspb.Message.getFieldWithDefault(msg, 6, 0),
    workersList: jspb.Message.toObjectList(msg.getWorkersList(),
    proto.bulwa.Person.toObject, includeInstance),
    gooddrink: jspb.Message.getFieldWithDefault(msg, 8, 0),
    purple: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.Warehouse}
 */
proto.bulwa.Warehouse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.Warehouse;
  return proto.bulwa.Warehouse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.Warehouse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.Warehouse}
 */
proto.bulwa.Warehouse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCapacity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxcapacity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYellow(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBlue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBaddrink(value);
      break;
    case 7:
      var value = new proto.bulwa.Person;
      reader.readMessage(value,proto.bulwa.Person.deserializeBinaryFromReader);
      msg.addWorkers(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGooddrink(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPurple(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.Warehouse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.Warehouse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.Warehouse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Warehouse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getMaxcapacity();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getYellow();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getBlue();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getBaddrink();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getWorkersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.bulwa.Person.serializeBinaryToWriter
    );
  }
  f = message.getGooddrink();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getPurple();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * optional Player.Role id = 1;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.Warehouse.prototype.getId = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.Warehouse} returns this
 */
proto.bulwa.Warehouse.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 capacity = 2;
 * @return {number}
 */
proto.bulwa.Warehouse.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Warehouse} returns this
 */
proto.bulwa.Warehouse.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 maxCapacity = 3;
 * @return {number}
 */
proto.bulwa.Warehouse.prototype.getMaxcapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Warehouse} returns this
 */
proto.bulwa.Warehouse.prototype.setMaxcapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 yellow = 4;
 * @return {number}
 */
proto.bulwa.Warehouse.prototype.getYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Warehouse} returns this
 */
proto.bulwa.Warehouse.prototype.setYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 blue = 5;
 * @return {number}
 */
proto.bulwa.Warehouse.prototype.getBlue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Warehouse} returns this
 */
proto.bulwa.Warehouse.prototype.setBlue = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 badDrink = 6;
 * @return {number}
 */
proto.bulwa.Warehouse.prototype.getBaddrink = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Warehouse} returns this
 */
proto.bulwa.Warehouse.prototype.setBaddrink = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated Person workers = 7;
 * @return {!Array<!proto.bulwa.Person>}
 */
proto.bulwa.Warehouse.prototype.getWorkersList = function() {
  return /** @type{!Array<!proto.bulwa.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.bulwa.Person, 7));
};


/**
 * @param {!Array<!proto.bulwa.Person>} value
 * @return {!proto.bulwa.Warehouse} returns this
*/
proto.bulwa.Warehouse.prototype.setWorkersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.bulwa.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.bulwa.Person}
 */
proto.bulwa.Warehouse.prototype.addWorkers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.bulwa.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.bulwa.Warehouse} returns this
 */
proto.bulwa.Warehouse.prototype.clearWorkersList = function() {
  return this.setWorkersList([]);
};


/**
 * optional uint32 goodDrink = 8;
 * @return {number}
 */
proto.bulwa.Warehouse.prototype.getGooddrink = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Warehouse} returns this
 */
proto.bulwa.Warehouse.prototype.setGooddrink = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 purple = 9;
 * @return {number}
 */
proto.bulwa.Warehouse.prototype.getPurple = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Warehouse} returns this
 */
proto.bulwa.Warehouse.prototype.setPurple = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.bulwa.Plantation.repeatedFields_ = [8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.Plantation.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.Plantation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.Plantation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Plantation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    availablecircles: jspb.Message.getFieldWithDefault(msg, 2, 0),
    readycircles: jspb.Message.getFieldWithDefault(msg, 3, 0),
    workersList: jspb.Message.toObjectList(msg.getWorkersList(),
    proto.bulwa.Person.toObject, includeInstance),
    circlesList: jspb.Message.toObjectList(msg.getCirclesList(),
    proto.bulwa.Circle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.Plantation}
 */
proto.bulwa.Plantation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.Plantation;
  return proto.bulwa.Plantation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.Plantation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.Plantation}
 */
proto.bulwa.Plantation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAvailablecircles(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReadycircles(value);
      break;
    case 8:
      var value = new proto.bulwa.Person;
      reader.readMessage(value,proto.bulwa.Person.deserializeBinaryFromReader);
      msg.addWorkers(value);
      break;
    case 9:
      var value = new proto.bulwa.Circle;
      reader.readMessage(value,proto.bulwa.Circle.deserializeBinaryFromReader);
      msg.addCircles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.Plantation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.Plantation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.Plantation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Plantation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAvailablecircles();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getReadycircles();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getWorkersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.bulwa.Person.serializeBinaryToWriter
    );
  }
  f = message.getCirclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.bulwa.Circle.serializeBinaryToWriter
    );
  }
};


/**
 * optional Player.Role id = 1;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.Plantation.prototype.getId = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.Plantation} returns this
 */
proto.bulwa.Plantation.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 availableCircles = 2;
 * @return {number}
 */
proto.bulwa.Plantation.prototype.getAvailablecircles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Plantation} returns this
 */
proto.bulwa.Plantation.prototype.setAvailablecircles = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 readyCircles = 3;
 * @return {number}
 */
proto.bulwa.Plantation.prototype.getReadycircles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Plantation} returns this
 */
proto.bulwa.Plantation.prototype.setReadycircles = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Person workers = 8;
 * @return {!Array<!proto.bulwa.Person>}
 */
proto.bulwa.Plantation.prototype.getWorkersList = function() {
  return /** @type{!Array<!proto.bulwa.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.bulwa.Person, 8));
};


/**
 * @param {!Array<!proto.bulwa.Person>} value
 * @return {!proto.bulwa.Plantation} returns this
*/
proto.bulwa.Plantation.prototype.setWorkersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.bulwa.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.bulwa.Person}
 */
proto.bulwa.Plantation.prototype.addWorkers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.bulwa.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.bulwa.Plantation} returns this
 */
proto.bulwa.Plantation.prototype.clearWorkersList = function() {
  return this.setWorkersList([]);
};


/**
 * repeated Circle circles = 9;
 * @return {!Array<!proto.bulwa.Circle>}
 */
proto.bulwa.Plantation.prototype.getCirclesList = function() {
  return /** @type{!Array<!proto.bulwa.Circle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.bulwa.Circle, 9));
};


/**
 * @param {!Array<!proto.bulwa.Circle>} value
 * @return {!proto.bulwa.Plantation} returns this
*/
proto.bulwa.Plantation.prototype.setCirclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.bulwa.Circle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.bulwa.Circle}
 */
proto.bulwa.Plantation.prototype.addCircles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.bulwa.Circle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.bulwa.Plantation} returns this
 */
proto.bulwa.Plantation.prototype.clearCirclesList = function() {
  return this.setCirclesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.bulwa.Production.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.Production.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.Production.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.Production} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Production.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    availableunits: jspb.Message.getFieldWithDefault(msg, 2, 0),
    readyunits: jspb.Message.getFieldWithDefault(msg, 3, 0),
    workersList: jspb.Message.toObjectList(msg.getWorkersList(),
    proto.bulwa.Person.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.Production}
 */
proto.bulwa.Production.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.Production;
  return proto.bulwa.Production.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.Production} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.Production}
 */
proto.bulwa.Production.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAvailableunits(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReadyunits(value);
      break;
    case 4:
      var value = new proto.bulwa.Person;
      reader.readMessage(value,proto.bulwa.Person.deserializeBinaryFromReader);
      msg.addWorkers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.Production.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.Production.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.Production} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Production.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAvailableunits();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getReadyunits();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getWorkersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.bulwa.Person.serializeBinaryToWriter
    );
  }
};


/**
 * optional Player.Role id = 1;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.Production.prototype.getId = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.Production} returns this
 */
proto.bulwa.Production.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 availableUnits = 2;
 * @return {number}
 */
proto.bulwa.Production.prototype.getAvailableunits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Production} returns this
 */
proto.bulwa.Production.prototype.setAvailableunits = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 readyUnits = 3;
 * @return {number}
 */
proto.bulwa.Production.prototype.getReadyunits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Production} returns this
 */
proto.bulwa.Production.prototype.setReadyunits = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Person workers = 4;
 * @return {!Array<!proto.bulwa.Person>}
 */
proto.bulwa.Production.prototype.getWorkersList = function() {
  return /** @type{!Array<!proto.bulwa.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.bulwa.Person, 4));
};


/**
 * @param {!Array<!proto.bulwa.Person>} value
 * @return {!proto.bulwa.Production} returns this
*/
proto.bulwa.Production.prototype.setWorkersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.bulwa.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.bulwa.Person}
 */
proto.bulwa.Production.prototype.addWorkers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.bulwa.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.bulwa.Production} returns this
 */
proto.bulwa.Production.prototype.clearWorkersList = function() {
  return this.setWorkersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.Circle.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.Circle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.Circle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Circle.toObject = function(includeInstance, msg) {
  var f, obj = {
    shoots: jspb.Message.getFieldWithDefault(msg, 4, 0),
    yellow: jspb.Message.getFieldWithDefault(msg, 5, 0),
    blue: jspb.Message.getFieldWithDefault(msg, 6, 0),
    purple: jspb.Message.getFieldWithDefault(msg, 7, 0),
    tekko: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.Circle}
 */
proto.bulwa.Circle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.Circle;
  return proto.bulwa.Circle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.Circle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.Circle}
 */
proto.bulwa.Circle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShoots(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYellow(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBlue(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPurple(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTekko(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.Circle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.Circle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.Circle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Circle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShoots();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getYellow();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getBlue();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getPurple();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getTekko();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional uint32 shoots = 4;
 * @return {number}
 */
proto.bulwa.Circle.prototype.getShoots = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Circle} returns this
 */
proto.bulwa.Circle.prototype.setShoots = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 yellow = 5;
 * @return {number}
 */
proto.bulwa.Circle.prototype.getYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Circle} returns this
 */
proto.bulwa.Circle.prototype.setYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 blue = 6;
 * @return {number}
 */
proto.bulwa.Circle.prototype.getBlue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Circle} returns this
 */
proto.bulwa.Circle.prototype.setBlue = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 purple = 7;
 * @return {number}
 */
proto.bulwa.Circle.prototype.getPurple = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.Circle} returns this
 */
proto.bulwa.Circle.prototype.setPurple = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool tekko = 8;
 * @return {boolean}
 */
proto.bulwa.Circle.prototype.getTekko = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.bulwa.Circle} returns this
 */
proto.bulwa.Circle.prototype.setTekko = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.GameParams.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.GameParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.GameParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.GameParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    turntime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sessionscount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    autoturnend: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    meetingtime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    gametitle: jspb.Message.getFieldWithDefault(msg, 6, ""),
    gamedescription: jspb.Message.getFieldWithDefault(msg, 7, ""),
    plantation1: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    plantation2: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    plantation3: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    production1: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    production2: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.GameParams}
 */
proto.bulwa.GameParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.GameParams;
  return proto.bulwa.GameParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.GameParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.GameParams}
 */
proto.bulwa.GameParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTurntime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSessionscount(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoturnend(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMeetingtime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGametitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setGamedescription(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlantation1(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlantation2(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlantation3(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProduction1(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProduction2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.GameParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.GameParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.GameParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.GameParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTurntime();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSessionscount();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getAutoturnend();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMeetingtime();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getGametitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getGamedescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPlantation1();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPlantation2();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getPlantation3();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getProduction1();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getProduction2();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional uint32 turnTime = 1;
 * @return {number}
 */
proto.bulwa.GameParams.prototype.getTurntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.GameParams} returns this
 */
proto.bulwa.GameParams.prototype.setTurntime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 sessionsCount = 3;
 * @return {number}
 */
proto.bulwa.GameParams.prototype.getSessionscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.GameParams} returns this
 */
proto.bulwa.GameParams.prototype.setSessionscount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool autoTurnEnd = 4;
 * @return {boolean}
 */
proto.bulwa.GameParams.prototype.getAutoturnend = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.bulwa.GameParams} returns this
 */
proto.bulwa.GameParams.prototype.setAutoturnend = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional uint32 meetingTime = 5;
 * @return {number}
 */
proto.bulwa.GameParams.prototype.getMeetingtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.bulwa.GameParams} returns this
 */
proto.bulwa.GameParams.prototype.setMeetingtime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string gameTitle = 6;
 * @return {string}
 */
proto.bulwa.GameParams.prototype.getGametitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.bulwa.GameParams} returns this
 */
proto.bulwa.GameParams.prototype.setGametitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string gameDescription = 7;
 * @return {string}
 */
proto.bulwa.GameParams.prototype.getGamedescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.bulwa.GameParams} returns this
 */
proto.bulwa.GameParams.prototype.setGamedescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool plantation1 = 8;
 * @return {boolean}
 */
proto.bulwa.GameParams.prototype.getPlantation1 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.bulwa.GameParams} returns this
 */
proto.bulwa.GameParams.prototype.setPlantation1 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool plantation2 = 9;
 * @return {boolean}
 */
proto.bulwa.GameParams.prototype.getPlantation2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.bulwa.GameParams} returns this
 */
proto.bulwa.GameParams.prototype.setPlantation2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool plantation3 = 10;
 * @return {boolean}
 */
proto.bulwa.GameParams.prototype.getPlantation3 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.bulwa.GameParams} returns this
 */
proto.bulwa.GameParams.prototype.setPlantation3 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool production1 = 11;
 * @return {boolean}
 */
proto.bulwa.GameParams.prototype.getProduction1 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.bulwa.GameParams} returns this
 */
proto.bulwa.GameParams.prototype.setProduction1 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool production2 = 12;
 * @return {boolean}
 */
proto.bulwa.GameParams.prototype.getProduction2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.bulwa.GameParams} returns this
 */
proto.bulwa.GameParams.prototype.setProduction2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.Player.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.Player.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.Player} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Player.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    role: jspb.Message.getFieldWithDefault(msg, 3, 0),
    decision: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    authtoken: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.Player}
 */
proto.bulwa.Player.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.Player;
  return proto.bulwa.Player.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.Player} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.Player}
 */
proto.bulwa.Player.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {!proto.bulwa.Player.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDecision(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.Player.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.Player.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.Player} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.Player.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDecision();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAuthtoken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.bulwa.Player.Role = {
  NONE: 0,
  ADMIN: 1,
  PLANTATION_1: 2,
  PLANTATION_2: 3,
  PLANTATION_3: 4,
  PRODUCTION_1: 5,
  PRODUCTION_2: 6,
  WAREHOUSE: 7,
  TRADE: 8,
  HR: 9,
  ANTRO: 10,
  METEO: 11,
  MANAGER_PLANTATION: 101,
  MANAGER_PRODUCTION: 102,
  MANAGER_WAREHOUSE: 103,
  MANAGER_TRADE: 104,
  MANAGER_HR: 105,
  MANAGER_ASTRO: 106
};

/**
 * optional string username = 1;
 * @return {string}
 */
proto.bulwa.Player.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.bulwa.Player} returns this
 */
proto.bulwa.Player.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Role role = 3;
 * @return {!proto.bulwa.Player.Role}
 */
proto.bulwa.Player.prototype.getRole = function() {
  return /** @type {!proto.bulwa.Player.Role} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.bulwa.Player.Role} value
 * @return {!proto.bulwa.Player} returns this
 */
proto.bulwa.Player.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool decision = 4;
 * @return {boolean}
 */
proto.bulwa.Player.prototype.getDecision = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.bulwa.Player} returns this
 */
proto.bulwa.Player.prototype.setDecision = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string authToken = 5;
 * @return {string}
 */
proto.bulwa.Player.prototype.getAuthtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.bulwa.Player} returns this
 */
proto.bulwa.Player.prototype.setAuthtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.CreateGameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.CreateGameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.CreateGameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.CreateGameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gametoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.CreateGameResponse}
 */
proto.bulwa.CreateGameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.CreateGameResponse;
  return proto.bulwa.CreateGameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.CreateGameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.CreateGameResponse}
 */
proto.bulwa.CreateGameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGametoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.CreateGameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.CreateGameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.CreateGameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.CreateGameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGametoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string gameToken = 1;
 * @return {string}
 */
proto.bulwa.CreateGameResponse.prototype.getGametoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.bulwa.CreateGameResponse} returns this
 */
proto.bulwa.CreateGameResponse.prototype.setGametoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bulwa.GameSubscribeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.bulwa.GameSubscribeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bulwa.GameSubscribeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.GameSubscribeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gametoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bulwa.GameSubscribeRequest}
 */
proto.bulwa.GameSubscribeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bulwa.GameSubscribeRequest;
  return proto.bulwa.GameSubscribeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bulwa.GameSubscribeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bulwa.GameSubscribeRequest}
 */
proto.bulwa.GameSubscribeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGametoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bulwa.GameSubscribeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bulwa.GameSubscribeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bulwa.GameSubscribeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bulwa.GameSubscribeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGametoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string gameToken = 1;
 * @return {string}
 */
proto.bulwa.GameSubscribeRequest.prototype.getGametoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.bulwa.GameSubscribeRequest} returns this
 */
proto.bulwa.GameSubscribeRequest.prototype.setGametoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.bulwa);
