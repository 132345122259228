import { EventBus } from "@/eventbus.js";

const getDefaultState = () => {
  return {
    turnNumber: 0,
    balance: 0,
    turnEnded: false,
    timerCount: 0,
    gameState: null,
    role: null,
    players: [],
    playerActions: [],
    events: [],
    specialActions: [],
    weather: [],
    weatherForecast: {
      rainfall: 0,
      wind: 0,
      temperature: 0,
    },
    balancePoints: 0,
    crisisPoints: 0,
    localMarketPoints: 0,
    protectEcosystemPoints: 0,
    workersPoints: 0,
  };
};

const state = getDefaultState();

const actions = {
  sync(ctx, resp) {
    if (ctx.getters.turnNumber !== resp.turnnumber && resp.turnnumber !== 0) {
      if (resp.turnnumber !== 1) {
        EventBus.$emit("turnEnded");
      }
      ctx.commit("setTurnEnded", true);
    } else {
      ctx.commit("setTurnEnded", false);
    }
    ctx.commit("setTurnNumber", resp.turnnumber);
    ctx.commit("setBalance", resp.balance);
    ctx.commit("setGameState", resp.state);
    ctx.commit("setPlayers", resp.playersList);
    ctx.commit("setWeather", resp.weatherList);
    ctx.commit("setWeatherForecast", resp.weatherforecast);
    ctx.commit("setBalancePoints", resp.balancepoints);
    ctx.commit("setCrisisPoints", resp.crisispoints);
    ctx.commit("setLocalMarketPoints", resp.localmarketpoints);
    ctx.commit("setProtectEcosystemPoints", resp.protectecosystempoints);
    ctx.commit("setWorkersPoints", resp.workerspoints);
    ctx.commit("setEvents", resp.eventsList);
  },
  reset(ctx) {
    ctx.commit("reset");
  },
};

const getters = {
  turnNumber: (state) => {
    return state.turnNumber;
  },
  balance: (state) => {
    return state.balance;
  },
  turnEnded: (state) => {
    return state.turnEnded;
  },
  role: (state) => {
    return state.role;
  },
  roleInt: (state) => {
    return parseInt(state.role);
  },
  isAdmin: (state) => {
    return parseInt(state.role) === 1;
  },
  gameState: (state) => {
    return state.gameState;
  },
  players: (state) => {
    return state.players;
  },
  weatherHistory: (state) => {
    return state.weather;
  },
  weather: (state) => {
    if (state.weather.length > 0) {
      return state.weather.at(-1);
    }
    return { wind: 0, rainfall: 0, temperature: 0 };
  },
  weatherForecast: (state) => {
    return state.weatherForecast;
  },
  wind: (state, getters) => {
    return windToText(getters.weather.wind);
  },
  windForecast: (state, getters) => {
    return windToText(getters.weatherForecast.wind);
  },
  rainfall: (state, getters) => {
    return rainfallToText(getters.weather.rainfall);
  },
  rainfallForecast: (state, getters) => {
    return rainfallToText(getters.weatherForecast.rainfall);
  },
  temperature: (state, getters) => {
    return temperatureToText(getters.weather.temperature);
  },
  temperatureForecast: (state, getters) => {
    return temperatureToText(getters.weatherForecast.temperature);
  },
  balancePoints: (state) => {
    return state.balancePoints;
  },
  crisisPoints: (state) => {
    return state.crisisPoints;
  },
  localMarketPoints: (state) => {
    return state.localMarketPoints;
  },
  protectEcosystemPoints: (state) => {
    return state.protectEcosystemPoints;
  },
  workersPoints: (state) => {
    return state.workersPoints;
  },
  events: (state) => {
    return state.events;
  },
  eventsBySource: (state) => (source) => {
    return state.events.filter((e) => e.source == source);
  },
  specialActions: (state) => {
    return state.specialActions;
  },
  playerActions: (state) => {
    return state.playerActions;
  },
};

const mutations = {
  setTurnNumber(state, turnNumber) {
    state.turnNumber = turnNumber;
  },
  setBalance(state, balance) {
    state.balance = balance;
  },
  setTurnEnded(state, turnEnded) {
    state.turnEnded = turnEnded;
  },
  setGameState(state, gameState) {
    state.gameState = gameState;
  },
  setPlayers(state, players) {
    state.players = players;
  },
  setWeather(state, weather) {
    state.weather = weather;
  },
  setWeatherForecast(state, weather) {
    state.weatherForecast = weather;
  },
  setBalancePoints(state, p) {
    state.balancePoints = p;
  },
  setCrisisPoints(state, p) {
    state.crisisPoints = p;
  },
  setLocalMarketPoints(state, p) {
    state.localMarketPoints = p;
  },
  setProtectEcosystemPoints(state, p) {
    state.protectEcosystemPoints = p;
  },
  setWorkersPoints(state, p) {
    state.workersPoints = p;
  },
  setEvents(state, events) {
    state.events = events;
  },
  setSpecialActions(state, actions) {
    state.specialActions = actions;
  },
  setRole(state, role) {
    state.role = role;
  },
  setPlayerActions(state, actions) {
    state.playerActions = actions;
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

function windToText(wind) {
  switch (wind) {
    case 0:
      return "Cisza (0)";
    case 1:
      return "Umiarkowany (1)";
    case 2:
      return "Duży (2)";
    case 3:
      return "Wichura (3)";
  }
}

function rainfallToText(rainfall) {
  switch (rainfall) {
    case 0:
      return "Brak (0)";
    case 1:
      return "Umiarkowane (1)";
    case 2:
      return "Silne (2)";
    case 3:
      return "Ulewa (3)";
  }
}

function temperatureToText(temperature) {
  switch (temperature) {
    case 0:
      return "Niska (0)";
    case 1:
      return "Umiarkowana (1)";
    case 2:
      return "Wysoka (2)";
    case 3:
      return "Tropikalna (3)";
  }
}
