<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-avatar v-bind="attrs" v-on="on"  color="primary ma-1" size="36">
        <span class="white--text">{{ initials(person.name) }}</span>
      </v-avatar>
    </template>
    <span>{{ person.name }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'Person',

  props: {
    person: Object,
  },

  methods: {
    initials: function(name) {
      return name.split(" ").map(x => x[0]).join("");
    },
  },
}
</script>
