import { EventBus } from "@/eventbus.js";

const getDefaultState = () => {
  return {
    turnTime: 30,
    sessionsCount: 1,
    autoTurnEnd: false,
    meetingTime: 300,
    gameTitle: "Plantacja",
    gameDescription:
      "W grze może wziąć udział od 6 do 9 graczy. Aby zwiększyć ich liczbę przesuń w prawo suwaki Plantacja 2, Plantacja 3 i Przetwórnia 2. Grę należy rozpocząć w momencie dołączenia wszystkich graczy (widok w zakładce Gracze).",
    plantation1: true,
    plantation2: false,
    plantation3: false,
    production1: true,
    production2: false,
  }
}

const state = getDefaultState();

const actions = {
  sync(ctx, resp) {
    let params = resp.gameparams;
    ctx.commit('setTurnTime', params.turntime);
    ctx.commit('setSessionsCount', params.sessionscount);
    ctx.commit('setAutoTurnEnd', params.autoturnend);
    ctx.commit('setMeetingTime', params.meetingtime);
    ctx.commit('setGameTitle', params.gametitle);
    ctx.commit('setGameDescription', params.gamedescription);
    ctx.commit('setPlantation1', params.plantation1);
    ctx.commit('setPlantation2', params.plantation2);
    ctx.commit('setPlantation3', params.plantation3);
    ctx.commit('setProduction1', params.production1);
    ctx.commit('setProduction2', params.production2);
  },
  reset(ctx) {
    ctx.commit('reset');
  },
};

const getters = {
  turnTime: (state) => {
    return state.turnTime;
  },
  sessionsCount: (state) => {
    return state.sessionsCount;
  },
  autoTurnEnd: (state) => {
    return state.autoTurnEnd;
  },
  meetingTime: (state) => {
    return state.meetingTime;
  },
  gameTitle: (state) => {
    return state.gameTitle;
  },
  gameDescription: (state) => {
    return state.gameDescription;
  },
  plantation1: (state) => {
    return state.plantation1;
  },
  plantation2: (state) => {
    return state.plantation2;
  },
  plantation3: (state) => {
    return state.plantation3;
  },
  production1: (state) => {
    return state.production1;
  },
  production2: (state) => {
    return state.production2;
  },
};

const mutations = {
  setTurnTime(state, v) {
    state.turnTime = v;
  },
  setSessionsCount(state, v) {
    state.sessionsCount = v;
  },
  setAutoTurnEnd(state, v) {
    state.autoTurnEnd = v;
  },
  setMeetingTime(state, v) {
    state.meetingTime = v;
  },
  setGameTitle(state, v) {
    state.gameTitle = v;
  },
  setGameDescription(state, v) {
    state.gameDescription = v;
  },
  setPlantation1(state, v) {
    state.plantation1 = v;
  },
  setPlantation2(state, v) {
    state.plantation2 = v;
  },
  setPlantation3(state, v) {
    state.plantation3 = v;
  },
  setProduction1(state, v) {
    state.production1 = v;
  },
  setProduction2(state, v) {
    state.production2 = v;
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};


