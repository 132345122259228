<template lang="pug">
v-card
  v-card-title Receptura
  v-card-text
    v-row
      v-col(cols="3")
        v-text-field(v-model="yellow" type="number" outlined dense label="Żółte Ngoro")
      v-col(cols="1" )
        p(align="center").fill-height.text-center.font-weight-bold +
      v-col(cols="3")
        v-text-field(v-model="blue" type="number" outlined dense label="Niebieskie Ngoro")
      v-col(cols="1")
        p.text-center.font-weight-bold =
      v-col(cols="4")
        v-text-field(v-model="bottles" type="number" outlined dense label="Butelki Kwanai")
  v-card-title Operatorzy
  v-card-text
    Team(teamType="operators" :idx="idx")
  v-card-title Specjaliści ds. optymalizacji
  v-card-text
    Team(teamType="experts" :idx="idx")

</template>
<script>
import { mapGetters } from "vuex";

import Team from "@/components/Team";

export default {
  name: 'ProductionUnit',

  components: {
    Team,
  },

  props: {
    idx: Number,
  },

  computed: {
    yellow: {
      get() {
        return this.request(this.idx).yellow;
      },
      set(v) {
        this.$store.commit('production/setYellow', { idx: this.idx, v });
      },
    },
    blue: {
      get() {
        return this.request(this.idx).blue;
      },
      set(v) {
        this.$store.commit('production/setBlue', { idx: this.idx, v });
      },
    },
    bottles: {
      get() {
        return this.request(this.idx).bottles;
      },
      set(v) {
        this.$store.commit('production/setBottles', { idx: this.idx, v });
      },
    },
    ...mapGetters('production', ['request']),
  }
}

</script>
