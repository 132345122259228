<template lang="pug">
v-row
  v-spacer
  v-col(cols="8")
    v-card.ma-6
      v-card-title Podsumowanie rozgrywki
      v-card-text
        v-simple-table
          template(v-slot:default)
            thead
              tr
                th Cel
                th Punktacja
            tbody
              tr
                v-tooltip(bottom)
                  template(v-slot:activator="{ on, attrs }")
                    td(v-bind="attrs" v-on="on") 
                      div
                        v-icon(size="small") mdi-information-outline 
                        |  Zaspokojenie potrzeb lokalnego rynku
                  div
                    p +1 punkt za każdą bulwę sprzedaną na rynku lokalnym
                    p +2 punkty za każdy napój sprzedany na rynku lokalnym
                td {{ localMarketPoints }}
              tr
                v-tooltip(bottom)
                  template(v-slot:activator="{ on, attrs }")
                    td(v-bind="attrs" v-on="on") 
                      div
                        v-icon(size="small") mdi-information-outline 
                        |  Ochrona ekosystemu
                  div
                    p -200 punktów za każdy krąg zarośnięty pnączem tekko
                td {{ protectEcosystemPoints }}
              tr
                v-tooltip(bottom)
                  template(v-slot:activator="{ on, attrs }")
                    td(v-bind="attrs" v-on="on") 
                      div
                        v-icon(size="small") mdi-information-outline 
                        |  Zapewnienie zapasów na wypadek kryzysu
                  div
                    p +2 punkty za każde miejsce w magazynie zajęte przez bulwę lub napój
                td {{ crisisPoints }}
              tr
                v-tooltip(bottom)
                  template(v-slot:activator="{ on, attrs }")
                    td(v-bind="attrs" v-on="on") 
                      div
                        v-icon(size="small") mdi-information-outline 
                        |  Dbałość o pracowników
                  div
                    p +100 punktów za każdy punkt średniej satysfakcji pracowników powyżej 4
                    p +100 punktów za każdy punkt średniego zaangażowania pracowników powyżej 4
                td {{ workersPoints }}
              tr
                v-tooltip(bottom)
                  template(v-slot:activator="{ on, attrs }")
                    td(v-bind="attrs" v-on="on") 
                      div
                        v-icon(size="small") mdi-information-outline 
                        |  Stabilność finansowa
                  div
                    p +200 punktów za każdą turę z dodatnim bilansem finansowym
                td {{ balancePoints }}
              tr
                td Suma
                td {{ balancePoints + crisisPoints + localMarketPoints + protectEcosystemPoints + workersPoints }}
  v-spacer
</template>
<script>
import { EventBus } from "@/eventbus.js";

import { mapGetters } from "vuex";

export default {
  name: "GameSummary",

  metaInfo: {
    title: "Podsumowanie rozgrywki",
  },

  data: () => ({}),

  methods: {},

  computed: {
    ...mapGetters("common", [
      "balancePoints",
      "crisisPoints",
      "localMarketPoints",
      "protectEcosystemPoints",
      "workersPoints",
    ]),
  },
};
</script>
