<template lang="pug">
  v-card
    v-card-title Parametry rozgrywki
      v-card-text
        v-row
          v-col(cols="3")
            v-text-field(v-model="turnTime" label="Czas tury (s)" :disabled="gameState != null" outlined dense)
            v-text-field(v-model="sessionsCount" label="Liczba sesji" :disabled="gameState != null" outlined dense)
            v-text-field(v-model="meetingTime" label="Czas na spotkania (s)" :disabled="gameState != null" outlined dense)
          v-col(cols="6")
            v-text-field(v-model="gameTitle" label="Tytuł gry" :disabled="gameState != null" outlined dense)
            v-textarea(v-model="gameDescription" label="Opis gry" :disabled="gameState != null" outlined dense)
          v-col(cols="3")
            v-switch(v-model="autoTurnEnd" label="Automatyczny koniec tury" :disabled="gameState != null")
            v-switch(v-model="plantation2" label="Plantacja 2" :disabled="gameState != null")
            v-switch(v-model="plantation3" label="Plantacja 3" :disabled="gameState != null")
            v-switch(v-model="production2" label="Przetwórnia 2" :disabled="gameState != null")
    v-card-actions
      v-btn(@click="createGame" :disabled="gameState != null") Stwórz grę
      v-btn(@click="startGame" :disabled="gameState != 'Pending'" color="success") Rozpocznij grę
      v-btn(@click="endTurn" :disabled="gameState != 'Ongoing'" color="error") Wymuś koniec tury
      v-spacer
      v-btn(@click="endGame = true" :disabled="gameState != 'Ongoing'" color="error") Wymuś koniec gry
      v-dialog(v-model="endGame" width="500")
        v-card
          v-card-title.text-h5.grey.lighten-2 Wymusić zakończenie rozgrywki?
          v-card-text Akcji nie da sie cofnąć.
          v-divider
          v-card-actions
            v-btn(color="error" text @click="forceGameEnd") Tak 
            v-spacer
            v-btn(color="primary" text @click="endGame = false") Nie

</template>

<script>
import { GameParams, GameCommand } from "@/bulwa_pb.js";
import { GameClient } from "@/bulwa_grpc_web_pb.js";
import { mapGetters } from "vuex";

import { EventBus } from "@/eventbus.js";

export default {
  name: "Params",

  data() {
    return {
      endGame: false,
    }
  },

  created: function() {
    this.client = new GameClient(process.env.VUE_APP_BACKEND_URL, null, null);
  },

  methods: {
    createGame: function() {
      var gameParams = this.createGameParams();
      var metadata = { auth_token: this.authToken };
      const self = this;
      this.client.createGame(gameParams, metadata, (err, response) => {
        if (err) {
          console.log("failed to create a game");
          console.log(err);
        }
        let gameToken = response.toObject().gametoken;
        self.$store.commit("auth/setGameToken", gameToken);
        // set admin role = 1
        self.$store.commit("common/setRole", 1);
        EventBus.$emit("subscribe");
      });
    },
    createGameParams: function() {
      let gameParams = new GameParams();
      gameParams.setTurntime(this['params/turnTime']);
      gameParams.setSessionscount(this['params/sessionsCount']);
      gameParams.setAutoturnend(this['params/autoTurnEnd']);
      gameParams.setMeetingtime(this['params/meetingTime']);
      gameParams.setGametitle(this['params/gameTitle']);
      gameParams.setGamedescription(this['params/gameDescription']);
      gameParams.setPlantation1(true);
      gameParams.setPlantation2(this["params/plantation2"]);
      gameParams.setPlantation3(this["params/plantation3"]);
      gameParams.setProduction1(true);
      console.log(this["params/production2"]);
      gameParams.setProduction2(this["params/production2"]);
      return gameParams;
    },
    startGame: function() {
      var gameCommand = new GameCommand();
      gameCommand.setGametoken(this.gameToken);
      var metadata = { auth_token: this.authToken };
      this.client.start(gameCommand, metadata, (err, response) => {
        if (err) {
          console.log(err);
        }
      });
    },
    endTurn: function() {
      var gameCommand = new GameCommand();
      gameCommand.setGametoken(this.gameToken);
      var metadata = { auth_token: this.authToken };
      this.client.forceTurnEnd(gameCommand, metadata, (err, response) => {
        if (err) {
          console.log(err);
        }
      });
    },
    forceGameEnd: function() {
      var gameCommand = new GameCommand();
      gameCommand.setGametoken(this.gameToken);
      var metadata = { auth_token: this.authToken };
      const self = this;
      this.client.forceGameEnd(gameCommand, metadata, (err, response) => {
        self.endGame = false;
        EventBus.$emit("setGameSummaryTab");
        if (err) {
          console.log(err);
        }
      });
    }
  },

  computed: {
    turnTime: {
      get() {
        return this['params/turnTime'];
      },
      set(v) {
        this.$store.commit("params/setTurnTime", v);
      },
    },
    sessionsCount: {
      get() {
        return this['params/sessionsCount'];
      },
      set(v) {
        this.$store.commit("params/setSessionsCount", v);
      },
    },
    autoTurnEnd: {
      get() {
        return this['params/autoTurnEnd'];
      },
      set(v) {
        this.$store.commit("params/setAutoTurnEnd", v);
      },
    },
    meetingTime: {
      get() {
        return this['params/meetingTime'];
      },
      set(v) {
        this.$store.commit("params/setMeetingTime", v);
      },
    },
    gameTitle: {
      get() {
        return this['params/gameTitle'];
      },
      set(v) {
        this.$store.commit("params/setGameTitle", v);
      },
    },
    gameDescription: {
      get() {
        return this['params/gameDescription'];
      },
      set(v) {
        this.$store.commit("params/setGameDescription", v);
      },
    },
    plantation2: {
      get() {
        return this["params/plantation2"];
      },
      set(v) {
        this.$store.commit("params/setPlantation2", v);
      },
    },
    plantation3: {
      get() {
        return this["params/plantation3"];
      },
      set(v) {
        this.$store.commit("params/setPlantation3", v);
      },
    },
    production2: {
      get() {
        return this["params/production2"];
      },
      set(v) {
        this.$store.commit("params/setProduction2", v);
      },
    },
    ...mapGetters("auth", ["authToken", "gameToken"]),
    ...mapGetters([
      "params/turnTime",
      "params/sessionsCount",
      "params/autoTurnEnd",
      "params/meetingTime",
      "params/gameTitle",
      "params/gameDescription",
      "params/plantation2",
      "params/plantation3",
      "params/production2",
    ]),
    ...mapGetters("common", ["gameState"]),
  },
};
</script>
