/**
 * @fileoverview gRPC-Web generated client stub for bulwa
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: bulwa.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var proto_auth_pb = require('./proto/auth_pb.js')

var proto_healthcheck_pb = require('./proto/healthcheck_pb.js')
const proto = {};
proto.bulwa = require('./bulwa_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.bulwa.GameClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.bulwa.GamePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.bulwa.GameParams,
 *   !proto.bulwa.CreateGameResponse>}
 */
const methodDescriptor_Game_createGame = new grpc.web.MethodDescriptor(
  '/bulwa.Game/createGame',
  grpc.web.MethodType.UNARY,
  proto.bulwa.GameParams,
  proto.bulwa.CreateGameResponse,
  /**
   * @param {!proto.bulwa.GameParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.bulwa.CreateGameResponse.deserializeBinary
);


/**
 * @param {!proto.bulwa.GameParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.bulwa.CreateGameResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.bulwa.CreateGameResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.bulwa.GameClient.prototype.createGame =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/bulwa.Game/createGame',
      request,
      metadata || {},
      methodDescriptor_Game_createGame,
      callback);
};


/**
 * @param {!proto.bulwa.GameParams} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.bulwa.CreateGameResponse>}
 *     Promise that resolves to the response
 */
proto.bulwa.GamePromiseClient.prototype.createGame =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/bulwa.Game/createGame',
      request,
      metadata || {},
      methodDescriptor_Game_createGame);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.bulwa.GameSubscribeRequest,
 *   !proto.bulwa.GameEvent>}
 */
const methodDescriptor_Game_subscribe = new grpc.web.MethodDescriptor(
  '/bulwa.Game/subscribe',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.bulwa.GameSubscribeRequest,
  proto.bulwa.GameEvent,
  /**
   * @param {!proto.bulwa.GameSubscribeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.bulwa.GameEvent.deserializeBinary
);


/**
 * @param {!proto.bulwa.GameSubscribeRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.bulwa.GameEvent>}
 *     The XHR Node Readable Stream
 */
proto.bulwa.GameClient.prototype.subscribe =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/bulwa.Game/subscribe',
      request,
      metadata || {},
      methodDescriptor_Game_subscribe);
};


/**
 * @param {!proto.bulwa.GameSubscribeRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.bulwa.GameEvent>}
 *     The XHR Node Readable Stream
 */
proto.bulwa.GamePromiseClient.prototype.subscribe =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/bulwa.Game/subscribe',
      request,
      metadata || {},
      methodDescriptor_Game_subscribe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.bulwa.GameCommand,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Game_start = new grpc.web.MethodDescriptor(
  '/bulwa.Game/start',
  grpc.web.MethodType.UNARY,
  proto.bulwa.GameCommand,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.bulwa.GameCommand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.bulwa.GameCommand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.bulwa.GameClient.prototype.start =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/bulwa.Game/start',
      request,
      metadata || {},
      methodDescriptor_Game_start,
      callback);
};


/**
 * @param {!proto.bulwa.GameCommand} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.bulwa.GamePromiseClient.prototype.start =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/bulwa.Game/start',
      request,
      metadata || {},
      methodDescriptor_Game_start);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.bulwa.GameCommand,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Game_forceTurnEnd = new grpc.web.MethodDescriptor(
  '/bulwa.Game/forceTurnEnd',
  grpc.web.MethodType.UNARY,
  proto.bulwa.GameCommand,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.bulwa.GameCommand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.bulwa.GameCommand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.bulwa.GameClient.prototype.forceTurnEnd =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/bulwa.Game/forceTurnEnd',
      request,
      metadata || {},
      methodDescriptor_Game_forceTurnEnd,
      callback);
};


/**
 * @param {!proto.bulwa.GameCommand} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.bulwa.GamePromiseClient.prototype.forceTurnEnd =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/bulwa.Game/forceTurnEnd',
      request,
      metadata || {},
      methodDescriptor_Game_forceTurnEnd);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.bulwa.GameCommand,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Game_forceGameEnd = new grpc.web.MethodDescriptor(
  '/bulwa.Game/forceGameEnd',
  grpc.web.MethodType.UNARY,
  proto.bulwa.GameCommand,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.bulwa.GameCommand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.bulwa.GameCommand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.bulwa.GameClient.prototype.forceGameEnd =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/bulwa.Game/forceGameEnd',
      request,
      metadata || {},
      methodDescriptor_Game_forceGameEnd,
      callback);
};


/**
 * @param {!proto.bulwa.GameCommand} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.bulwa.GamePromiseClient.prototype.forceGameEnd =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/bulwa.Game/forceGameEnd',
      request,
      metadata || {},
      methodDescriptor_Game_forceGameEnd);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.bulwa.SelectRoleRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Game_selectRole = new grpc.web.MethodDescriptor(
  '/bulwa.Game/selectRole',
  grpc.web.MethodType.UNARY,
  proto.bulwa.SelectRoleRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.bulwa.SelectRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.bulwa.SelectRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.bulwa.GameClient.prototype.selectRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/bulwa.Game/selectRole',
      request,
      metadata || {},
      methodDescriptor_Game_selectRole,
      callback);
};


/**
 * @param {!proto.bulwa.SelectRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.bulwa.GamePromiseClient.prototype.selectRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/bulwa.Game/selectRole',
      request,
      metadata || {},
      methodDescriptor_Game_selectRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.bulwa.PlayerActions,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Game_submitActions = new grpc.web.MethodDescriptor(
  '/bulwa.Game/submitActions',
  grpc.web.MethodType.UNARY,
  proto.bulwa.PlayerActions,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.bulwa.PlayerActions} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.bulwa.PlayerActions} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.bulwa.GameClient.prototype.submitActions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/bulwa.Game/submitActions',
      request,
      metadata || {},
      methodDescriptor_Game_submitActions,
      callback);
};


/**
 * @param {!proto.bulwa.PlayerActions} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.bulwa.GamePromiseClient.prototype.submitActions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/bulwa.Game/submitActions',
      request,
      metadata || {},
      methodDescriptor_Game_submitActions);
};


module.exports = proto.bulwa;

