<template lang="pug">
  div
    v-col(cols="8" offset="2")
      v-sheet(rounded="lg")
        v-row
          v-col(cols="12")
            h1.display-2.font-weight-bold.text-center Witaj w Plantacji!
        v-row
          v-col(cols="2" offset="5").text-center Stwórz swoją grę...
        v-row
          v-col(cols="2" offset="5")
            v-btn(v-on:click="createGame" color="warning" block) Stwórz
        v-row
          v-col(cols="4" offset="4").text-center ...albo dołącz do istniejącej za pomocą kodu rozgrywki.
        v-row
          v-col(cols="12")
            v-form
              v-row
                v-col(cols="2" offset="5")
                  v-text-field(v-model="gameToken" label="Kod rozgrywki" required)
              v-row
                v-col(cols="2" offset="5")
                  v-btn(v-on:click="joinGame" block color="success" class="mr-4") Dalej
</template>

<script>
export default {
  name: 'GameSelection',

  metaInfo: {
    title: 'Wybór gry',
  },

  data: () => ({
    gameToken: ""
  }),

  methods: {
    createGame: function () {
      this.$router.push('admin');
    },
    joinGame: function() {
      this.$store.commit('auth/setGameToken', this.$data.gameToken.toUpperCase());
      this.$router.push('game');
    }
  },
}
</script>

