<script>
import { Doughnut, Pie, mixins } from "vue-chartjs";
import PieceLabel from "chart.piecelabel.js";
const { reactiveProp } = mixins;

export default {
  extends: Pie,

  mixins: [reactiveProp],

  props: {
    title: String,
    showPercentage: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    let display = true;
    if (this.title == "hide") {
      display = false;
    }
    const self = this;
    let options = {
      maintainAspectRatio: false,
      title: {
        display: display,
        text: this.title,
      },
      legend: {
        display: false,
      },
      pieceLabel: {
        render: "value",
        arc: true,
        fontColor: "#000",
        position: "outside",
        segment: true,
      },
    };
    console.log(PieceLabel);
    this.addPlugin(PieceLabel);
    this.renderChart(this.chartData, options);
  },
};
</script>
