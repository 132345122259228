<template lang="pug">
  #game
    v-row.pl-8
      v-col
        h1 Panel planetologa
    v-row.pr-8.pl-8
      v-col(cols="12")
        v-card
          v-card-title {{ weatherHistoryTitle }}
          v-card-text
            LineChart(:chartData="weatherHistoryData")
      v-col(cols="4")
        v-card(rounded="lg")
          v-list(color="transparent")
            v-list-item
              v-list-item-icon
                v-icon mdi-weather-partly-snowy-rainy
              v-list-item-content
                v-list-item-title Prognoza pogody na przyszły tydzień
            v-divider(class="my-2")
            v-list-item
              v-list-item-icon
                v-icon mdi-weather-windy
              v-list-item-content
                v-list-item-title(v-text="windForecast")
            v-list-item
              v-list-item-icon
                v-icon mdi-weather-pouring
              v-list-item-content
                v-list-item-title(v-text="rainfallForecast")
            v-list-item
              v-list-item-icon
                v-icon mdi-thermometer
              v-list-item-content
                v-list-item-title(v-text="temperatureForecast")
</template>
<script>
import { mapGetters } from "vuex";

import LineChart from "@/components/LineChart";

export default {
  name: "Meteo",

  components: {
    LineChart,
  },

  data: () => ({}),

  created: function () {
    this.initSpecialActions();
  },

  methods: {
    initSpecialActions: function () {
      this.$store.commit("common/setSpecialActions", [
        { name: "Analiza opadów", cost: 50 },
        { name: "Analiza temperatury", cost: 50 },
        { name: "Analiza wiatrów", cost: 50 },
        { name: "Moduł SI do prognoz", cost: 500 },
      ]);
      console.log(this.specialActions);
    },
  },

  computed: {
    weatherHistoryData: function () {
      let datasets = [
        {
          data: this.weatherHistory.map((x) => x.rainfall),
          label: "Opad",
          fill: false,
          borderColor: "green",
        },
        {
          data: this.weatherHistory.map((x) => x.temperature),
          label: "Temperatura",
          fill: false,
          borderColor: "orange",
        },
        {
          data: this.weatherHistory.map((x) => x.wind),
          label: "Wiatr",
          fill: false,
          borderColor: "blue",
        },
      ];
      let labels = [];
      for (let i = 0; i < datasets[0].data.length; i++) {
        labels.push(i + 1);
      }
      return { labels, datasets };
    },
    weatherHistoryTitle: function () {
      return "Historia pogody";
    },
    ...mapGetters("common", [
      "windForecast",
      "rainfallForecast",
      "temperatureForecast",
      "weatherHistory",
    ]),
  },
};
</script>
